import * as React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Button as MUIButton,
} from "@mui/material";
import {
  Button,
  useGetList,
  Loading,
  useDataProvider,
  useGetOne,
  useRefresh,
} from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import RestoreIcon from "@mui/icons-material/Restore";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MoreHorizIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PomView from "./PomView";
import NextPomView from "./NextPomView";
import { Countries as countries } from "./Countries";
import { MensWc, WomensWc } from "./MensWc";
import Parse from "parse";

const Decision = ({ referee, index }) => (
  <>
    <Box
      backgroundColor={
        referee &&
        (referee[0] === "bp" ||
          referee[0] === "ds" ||
          referee[0] === "ss" ||
          referee[0] === "ps")
          ? "red"
          : referee && referee[0] === "good"
            ? "#ffffff"
            : "none"
      }
      component="div"
      sx={{
        borderRadius: 2,
        width: index === 5 ? "93%" : "80%",
        height: 40,
        margin: "auto",
      }}
    />
  </>
);

export const Run = () => {
  const navigate = useNavigate();
  const user = Parse.User.current();
  const { platform } = useParams();
  const [timeTrigger, set_timeTrigger] = React.useState(0);
  const [round, setRound] = React.useState("1");
  const [current, setCurrent] = React.useState(null);
  const [startAction, setstartAction] = React.useState(false);
  const [ressetAction, setressetAction] = React.useState(false);
  const [result, setResult] = React.useState(null);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { data: MensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "m",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const { data: WomensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "f",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const {
    data: competition,
    isLoadingComp,
    errorComp,
  } = useGetOne("competitions", {
    id: localStorage.getItem("currentCompetition"),
  });

  const {
    data: lifters,
    total,
    isLoading,
    error,
  } = useGetList("lifters", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      platform,
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "fullname",
      order: "ASC",
    },
  });
  const [refreshedLifters, setRefreshedLifters] = React.useState(
    lifters || null,
  );
  const {
    data: record,
    isLoading2,
    error2,
  } = useGetOne("platforms", {
    id: platform,
  });
  const [refreshedPlatform, setRefreshedPlatform] = React.useState(record);
  // const platformRound = React.useMemo(() => {
  //     if(record && record.round)
  //       return(record.round)

  //       return 1
  // }, [record])

  const livequery = async () => {
    // const self = this;
    if (!refreshedPlatform && platform) {
      const { data: recordT } = await dataProvider.getOne("platforms", {
        id: platform,
      });
      setRefreshedPlatform(recordT);
    }

    const query = new Parse.Query("platforms");
    query.equalTo("objectId", platform);
    const subscription = await query.subscribe();
    subscription.on("update", async (object) => {
      console.log("Entro a update de la primera subscripción");
      console.log(object.toJSON());
      setRound(object.get("round"));
      setRefreshedPlatform(object.toJSON());
    });

    const queryLifters = new Parse.Query("lifters");
    queryLifters.equalTo("platform", platform);
    const subscriptionLifers = await queryLifters.subscribe();
    subscriptionLifers.on("update", async (object) => {
      //Obtengo al current
      const { data: liftersT } = await dataProvider.getList("lifters", {
        filter: {
          competition: localStorage.getItem("currentCompetition")
            ? localStorage.getItem("currentCompetition")
            : null,
          platform,
        },
        pagination: {
          page: 1,
          perPage: 10000,
        },
        sort: {
          field: "fullname",
          order: "ASC",
        },
      });
      setRefreshedLifters(liftersT);
    });
  };

  const getCurrent = async () => {
    const { data: recordT } = await dataProvider.getOne("platforms", {
      id: platform,
    });
    const { data: liftersT } = await dataProvider.getList("lifters", {
      filter: {
        competition: localStorage.getItem("currentCompetition")
          ? localStorage.getItem("currentCompetition")
          : null,
        platform,
      },
      pagination: {
        page: 1,
        perPage: 10000,
      },
      sort: {
        field: "fullname",
        order: "ASC",
      },
    });
    // console.log(liftersT)
    const ordered = liftersT
      .filter((item) => item.bodyweight && item.bodyweight.length)
      .sort((a, b) => {
        if (a.first_attempt === b.first_attempt) {
          return parseFloat(a.bodyweight) > parseFloat(b.bodyweight) ? -1 : 1;
        } else {
          return a.first_attempt < b.first_attempt ? -1 : 1;
        }
      });

    setCurrent(ordered[0]);
    dataProvider.update("platforms", {
      id: platform,
      data: {
        current: ordered[0],
      },
    });

    // if(!recordT.current) {
    //   //Se escoge al primero si estoy iniciando
    //   setCurrent(ordered[0])
    //   dataProvider.update('platforms', { id: platform, data: { current: ordered[0] } });
    // } else {
    //   setCurrent(recordT.current)
    // }
  };

  React.useEffect(() => {
    if (platform) {
      // getCurrent()
      livequery();
    }
  }, [platform]);

  React.useEffect(() => {
    if (!isLoading && lifters.length && !refreshedLifters)
      setRefreshedLifters(lifters);
  }, [isLoading]);

  // React.useEffect(() => {
  //   // alert(record)
  //   if(!isLoading2 && record && !refreshedPlatform)
  //       setRefreshedPlatform(record)
  // }, [isLoading2, error2, record])

  React.useEffect(() => {
    if (refreshedPlatform) setRound(refreshedPlatform.round);
  }, [refreshedPlatform]);

  const goodLift = async (
    valido,
    lifter,
    attempt,
    nextAttempt,
    attemptResultField,
    nextRound,
  ) => {
    set_timeTrigger((timeTrigger) => timeTrigger + 1);
    console.log(
      valido,
      lifter,
      attempt,
      nextAttempt,
      attemptResultField,
      nextRound,
    );
    const { data: record } = await dataProvider.getOne("platforms", {
      id: platform,
    });
    const { data: lifters } = await dataProvider.getList("lifters", {
      filter: {
        competition: localStorage.getItem("currentCompetition")
          ? localStorage.getItem("currentCompetition")
          : null,
        platform,
      },
      pagination: {
        page: 1,
        perPage: 10000,
      },
      sort: {
        field: "fullname",
        order: "ASC",
      },
    });
    console.log(lifters);
    let resutls = record[attemptResultField] || {};
    resutls[lifter] = valido;
    dataProvider.update("platforms", {
      id: platform,
      data: {
        [attemptResultField]: resutls,
        hr: [],
        rr: [],
        lr: [],
      },
    });

    const ordered = lifters.sort((a, b) => {
      if (a[attempt] === b[attempt]) {
        return parseInt(b.lot_number) < parseInt(a.lot_number) ? -1 : 1;
      } else {
        return a[attempt] < b[attempt] ? -1 : 1;
      }
    });

    const myIndex = ordered.findIndex((item) => item.id === lifter);
    const next =
      parseInt(myIndex + 1) === ordered.length ? 0 : parseInt(myIndex + 1);
    console.log(next);
    console.log(ordered[next]);
    if (next > 0) {
      setCurrent(ordered[next]);
      dataProvider.update("platforms", {
        id: platform,
        data: {
          current: ordered[next],
        },
      });
    } else {
      console.log(lifters.filter((item) => item[nextAttempt]));
      if (nextAttempt) {
        const orderedNextRound = lifters
          .filter((item) => item[nextAttempt])
          .sort((a, b) => {
            if (a[nextAttempt] === b[nextAttempt]) {
              return parseInt(b.lot_number) < parseInt(a.lot_number) ? -1 : 1;
            } else {
              return a[nextAttempt] < b[nextAttempt] ? -1 : 1;
            }
          });
        setCurrent(orderedNextRound[0]);
        dataProvider.update("platforms", {
          id: platform,
          data: {
            current: orderedNextRound[0],
            round: nextRound.toString(),
          },
        });
      }
    }
  };

  const changeAttempt = (e, field) => {
    console.log(e.target.id);
    console.log(e.target.value);
    const myId = e.target.id.split("-")[1];
    dataProvider
      .update("lifters", {
        id: myId,
        data: {
          [field]: parseFloat(e.target.value),
        },
      })
      .then((results) => console.log(results));
  };

  const changeRack = (e) => {
    const myId = e.target.id.split("-")[1];
    dataProvider
      .update("lifters", {
        id: myId,
        data: {
          rack_height: parseInt(e.target.value),
        },
      })
      .then((results) => console.log(results));
  };

  const clearResult = async (round, lifter, field) => {
    // alert(lifter)
    const { data: record } = await dataProvider.getOne("platforms", {
      id: platform,
    });
    let resultados = record[field] || {};
    console.log(record);
    console.log(field);
    console.log(record[field]);
    console.log(resultados);
    delete resultados[lifter];
    await dataProvider.update("platforms", {
      id: platform,
      data: {
        [field]: resultados,
      },
    });
    refresh();
  };

  const setRecord = async (recordName, round, lifter, field) => {
    alert(`Saving as ${recordName} record`);
    const { data: record } = await dataProvider.getOne("platforms", {
      id: platform,
    });
    let resutls = record[field] || {};
    resutls[lifter] = resutls[lifter]
      ? {
          ...resutls[lifter],
          [recordName]: true,
        }
      : {
          [recordName]: true,
        };
    await dataProvider.update("platforms", {
      id: platform,
      data: {
        [field]: resutls,
      },
    });
    refresh();
  };

  const changeResult = async (round, lifter, desision, field) => {
    // alert(lifter)
    const { data: record } = await dataProvider.getOne("platforms", {
      id: platform,
    });

    let resutls = record[field] || {};
    resutls[lifter] = desision;
    await dataProvider.update("platforms", {
      id: platform,
      data: {
        [field]: resutls,
      },
    });

    refresh();
  };

  const setCurrentAttemp = async (round, lifter) => {
    const { data: record } = await dataProvider.getOne("platforms", {
      id: platform,
    });
    await dataProvider.update("platforms", {
      id: platform,
      data: {
        current: lifter,
        round: round.toString(),
      },
    });
    setCurrent(lifter);
    refresh();
  };

  const { data: teams } = useGetList("teams", {
    filter: {
      competition: localStorage.getItem("currentCompetition"),
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "name",
      order: "ASC",
    },
  });

  if (isLoading || isLoading2 || !refreshedPlatform || !record) {
    return <Loading />;
  }
  if (error || error2) {
    return <p>ERROR</p>;
  }

  if (record)
    return (
      <Card>
        {/*<CardHeader title={`Platform ${record ? record.name : ''}`} />*/}
        <CardContent>
          <Grid className="sticky" container spacing={2}>
            <Grid item xs={4}>
              <h2
                style={{ margin: 0 }}
              >{`Platform ${record ? record.name : ""}`}</h2>
              {/*<MUIButton onClick={()=>getCurrent()}>Set 1st Athlete </MUIButton>*/}
              <p>
                Round: {round}
                <br />
                Current Lifter:{" "}
                {current ? current.surename + " " + current.firstname : ""}
                <br />
                {/*refreshedPlatform.finished ? <MUIButton>Display podium</MUIButton> : null*/}
              </p>
            </Grid>
            <Grid item xs={4}>
              <PomView
                trigger={timeTrigger}
                startAction={startAction}
                ressetAction={ressetAction}
                seconds={
                  refreshedPlatform && refreshedPlatform.seconds
                    ? refreshedPlatform.seconds
                    : null
                }
                onResset={async (seconds) =>
                  dataProvider.update("platforms", {
                    id: platform,
                    data: { timer: seconds },
                  })
                }
                onStart={async (startStop) =>
                  dataProvider.update("platforms", {
                    id: platform,
                    data: { start: startStop },
                  })
                }
              />
              <NextPomView />
            </Grid>
            <Grid item xs={4}>
              {refreshedPlatform && (
                <div
                  style={{
                    backgroundColor: "gray",
                    display: "flex",
                    border: "1px solid grey",
                    padding: 5,
                    borderRadius: 8,
                    width: "90%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12} style={{ marginBottom: "5px" }}>
                      <Decision referee={refreshedPlatform.lr} index={5} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={12} style={{ marginBottom: "5px" }}>
                      <Decision referee={refreshedPlatform.hr} index={5} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={12} style={{ marginBottom: "5px" }}>
                      <Decision referee={refreshedPlatform.rr} index={5} />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
          <TableContainer style={{ marginTop: 150 }} component={Paper}>
            <Table
              sx={{ minWidth: 650, width: "100%" }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      position: "sticky",
                      left: 0,
                      backgroundColor: "#DDDDDD",
                    }}
                  >
                    Lot No.
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      position: "sticky",
                      left: 55,
                      backgroundColor: "#DDDDDD",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Flag
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Country/Team
                  </TableCell>
                  <TableCell style={{ border: "solid", borderWidth: 1 }}>
                    BWC
                  </TableCell>
                  <TableCell style={{ border: "solid", borderWidth: 1 }}>
                    BW
                  </TableCell>
                  {/* <TableCell style={{ border: "solid", borderWidth: 1 }}> */}
                  {/*   Rack height */}
                  {/* </TableCell> */}
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Squat 1
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Squat 2
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Squat 3
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Best Squat
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Bench 1
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Bench 2
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Bench 3
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Best Bench
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Dead 1
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Dead 2
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Dead 3
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Best Deadlift
                  </TableCell>
                  {/*<TableCell style={{border: 'solid',borderWidth: 1}} align="right">Rack Height</TableCell>*/}
                  {/* <TableCell style={{border: 'solid',borderWidth: 1}} align="right">Best</TableCell> */}
                  {/*<TableCell style={{border: 'solid',borderWidth: 1}} align="right">Total</TableCell>*/}
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Wilks
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1 }}
                    align="right"
                  >
                    Rank
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {refreshedLifters &&
                  refreshedLifters
                    .sort((a, b) => {
                      const attempt =
                        round === "1"
                          ? "squat_first_attempt"
                          : round === "2"
                            ? "squat_second_attempt"
                            : round === "3"
                              ? "squat_third_attempt"
                              : round === "4"
                                ? "bench_first_attempt"
                                : round === "5"
                                  ? "bench_second_attempt"
                                  : round === "6"
                                    ? "bench_third_attempt"
                                    : round === "7"
                                      ? "dead_first_attempt"
                                      : round === "8"
                                        ? "dead_second_attempt"
                                        : round === "9"
                                          ? "dead_third_attempt"
                                          : "";

                      if (a[attempt] === b[attempt]) {
                        return parseInt(b.lot_number) < parseInt(a.lot_number)
                          ? -1
                          : 1;
                      } else {
                        return a[attempt] < b[attempt] ? -1 : 1;
                      }
                    })
                    .map((row, index) => {
                      let rank = "-";
                      return {
                        ...row,
                        bestSquat:
                          refreshedPlatform &&
                          refreshedPlatform.squat_third_attempt_results &&
                          refreshedPlatform.squat_third_attempt_results[row.id]
                            ? row.squat_third_attempt
                            : refreshedPlatform.squat_second_attempt_results &&
                                refreshedPlatform.squat_second_attempt_results[
                                  row.id
                                ]
                              ? row.squat_second_attempt
                              : refreshedPlatform.squat_first_attempt_results &&
                                  refreshedPlatform.squat_first_attempt_results[
                                    row.id
                                  ]
                                ? row.squat_first_attempt
                                : null,
                        bestBench:
                          refreshedPlatform &&
                          refreshedPlatform.bench_third_attempt_results &&
                          refreshedPlatform.bench_third_attempt_results[row.id]
                            ? row.bench_third_attempt
                            : refreshedPlatform.bench_second_attempt_results &&
                                refreshedPlatform.bench_second_attempt_results[
                                  row.id
                                ]
                              ? row.bench_second_attempt
                              : refreshedPlatform.bench_first_attempt_results &&
                                  refreshedPlatform.bench_first_attempt_results[
                                    row.id
                                  ]
                                ? row.bench_first_attempt
                                : null,
                        bestDeadlift:
                          refreshedPlatform &&
                          refreshedPlatform.deadlift_third_attempt_results &&
                          refreshedPlatform.deadlift_third_attempt_results[
                            row.id
                          ]
                            ? row.deadlift_third_attempt
                            : refreshedPlatform.deadlift_second_attempt_results &&
                                refreshedPlatform
                                  .deadlift_second_attempt_results[row.id]
                              ? row.deadlift_second_attempt
                              : refreshedPlatform.deadlift_first_attempt_results &&
                                  refreshedPlatform
                                    .deadlift_first_attempt_results[row.id]
                                ? row.deadlift_first_attempt
                                : null,
                        BWC: [...WomensWc, ...MensWc].find(
                          (item) => item.id === row.weight_class,
                        )
                          ? [...WomensWc, ...MensWc].find(
                              (item) => item.id === row.weight_class,
                            ).name
                          : row.weight_class,
                      };
                    })
                    .map((row, index, liftersInTable) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{
                            border: "solid",
                            borderWidth: 1,
                            position: "sticky",
                            left: 0,
                            backgroundColor: "#DDDDDD",
                            zIndex: 1,
                          }}
                          component="th"
                          scope="row"
                        >
                          <small>{row.lot_number}</small>
                        </TableCell>
                        <TableCell
                          style={{
                            border: "solid",
                            borderWidth: 1,
                            position: "sticky",
                            left: 55,
                            backgroundColor: "#DDDDDD",
                            zIndex: 1,
                          }}
                          component="th"
                          scope="row"
                        >
                          <small>
                            {row.surename ? row.surename.toUpperCase() : ""}{" "}
                            {row.firstname}
                          </small>
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          align="center"
                        >
                          {row.country &&
                          row.country.toLowerCase().includes("hungary") ? (
                            <img
                              src={`/hung.jpg`}
                              loading="lazy"
                              width="60"
                              alt=""
                            />
                          ) : row.country &&
                            row.country.toLowerCase().includes("ukraine") ? (
                            <img
                              src={`/ucr.jpg`}
                              loading="lazy"
                              width="60"
                              alt=""
                            />
                          ) : (
                            <img
                              src={`/hellas.png`}
                              loading="lazy"
                              width="60"
                              alt=""
                            />
                          )}
                          {/*row.country &&
                            countries.find(
                              (ctry) => ctry.code === row.country,
                            ) &&
                            countries.find((ctry) => ctry.code === row.country)
                              .flag && (
                              <img
                                src={`/flags/${countries.find((ctry) => ctry.code === row.country).flag}`}
                                loading="lazy"
                                width="30"
                                // src={`https://flagcdn.com/w20/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png`}
                                // srcSet={`https://flagcdn.com/w40/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png 2x`}
                                alt=""
                              />
                            )*/}
                        </TableCell>
                        <TableCell
                          style={{
                            border: "solid",
                            borderWidth: 1,
                            maxWidth: 50,
                            wordWrap: "break-world",
                          }}
                        >
                          {row.country} /{" "}
                          {teams &&
                          teams.length &&
                          teams.find((item) => item.id === row.team)
                            ? teams.find((item) => item.id === row.team).name
                            : null}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          component="th"
                          scope="row"
                        >
                          {row.BWC}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          component="th"
                          scope="row"
                        >
                          {row.bodyweight}
                        </TableCell>
                        {/* <TableCell */}
                        {/*   style={{ border: "solid", borderWidth: 1 }} */}
                        {/*   component="th" */}
                        {/*   scope="row" */}
                        {/* > */}
                        {/*   {row.rack_height} */}
                        {/* </TableCell> */}
                        {/*
                         * AQUI COMIENZAN LOS CAMPOS DE SQUAT
                         */}
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          sx={{
                            backgroundColor:
                              !refreshedPlatform.squat_first_attempt_results
                                ? "none"
                                : refreshedPlatform.squat_first_attempt_results[
                                      row.id
                                    ] &&
                                    refreshedPlatform
                                      .squat_first_attempt_results[row.id] ===
                                      true
                                  ? "#008000"
                                  : refreshedPlatform.squat_first_attempt_results &&
                                      refreshedPlatform
                                        .squat_first_attempt_results[row.id] ===
                                        false
                                    ? "#CC0000"
                                    : "none",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <TextField
                              onChange={(e) =>
                                changeAttempt(e, "squat_first_attempt")
                              }
                              size="small"
                              sx={{ minWidth: "30px" }}
                              id={`Squat1-${row.id}`}
                              defaultValue={row.squat_first_attempt}
                              label=""
                              variant="standard"
                            />
                            <BasicMenu
                              competition={competition}
                              setRecord={(record) =>
                                setRecord(record, 1, row.id)
                              }
                              clearResult={() =>
                                clearResult(
                                  1,
                                  row.id,
                                  "squat_first_attempt_results",
                                )
                              }
                              goodResult={() =>
                                changeResult(
                                  1,
                                  row.id,
                                  true,
                                  "squat_first_attempt_results",
                                )
                              }
                              badResult={() =>
                                changeResult(
                                  1,
                                  row.id,
                                  false,
                                  "squat_first_attempt_results",
                                )
                              }
                              setCurrentAttemp={() => setCurrentAttemp(1, row)}
                            />
                          </div>
                          {current && current.id === row.id && round == 1 && (
                            <>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    true,
                                    row.id,
                                    "squat_first_attempt",
                                    "squat_second_attempt",
                                    "squat_first_attempt_results",
                                    2,
                                  )
                                }
                                aria-label="delete"
                              >
                                <DoneIcon sx={{ color: "green" }} />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    false,
                                    row.id,
                                    "squat_first_attempt",
                                    "squat_second_attempt",
                                    "squat_first_attempt_results",
                                    2,
                                  )
                                }
                                aria-label="delete"
                              >
                                <CloseIcon sx={{ color: "red" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setstartAction(!startAction)}
                                size="small"
                                aria-label="delete"
                              >
                                <AlarmOnIcon sx={{ color: "gray" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setressetAction(true)}
                                size="small"
                                aria-label="delete"
                              >
                                <RestoreIcon sx={{ color: "gray" }} />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          sx={{
                            backgroundColor:
                              !refreshedPlatform.squat_second_attempt_results
                                ? "none"
                                : refreshedPlatform
                                      .squat_second_attempt_results[row.id] &&
                                    refreshedPlatform
                                      .squat_second_attempt_results[row.id] ===
                                      true
                                  ? "#008000"
                                  : refreshedPlatform.squat_second_attempt_results &&
                                      refreshedPlatform
                                        .squat_second_attempt_results[
                                        row.id
                                      ] === false
                                    ? "#CC0000"
                                    : "none",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <TextField
                              onChange={(e) =>
                                changeAttempt(e, "squat_second_attempt")
                              }
                              size="small"
                              sx={{ minWidth: "30px" }}
                              id={`Squat2-${row.id}`}
                              defaultValue={row.squat_second_attempt}
                              label=""
                              variant="standard"
                            />
                            <BasicMenu
                              competition={competition}
                              setRecord={(record) =>
                                setRecord(record, 2, row.id)
                              }
                              clearResult={() =>
                                clearResult(
                                  2,
                                  row.id,
                                  "squat_second_attempt_results",
                                )
                              }
                              goodResult={() =>
                                changeResult(
                                  2,
                                  row.id,
                                  true,
                                  "squat_second_attempt_results",
                                )
                              }
                              badResult={() =>
                                changeResult(
                                  2,
                                  row.id,
                                  false,
                                  "squat_second_attempt_results",
                                )
                              }
                              setCurrentAttemp={() => setCurrentAttemp(2, row)}
                            />
                          </div>
                          {current && current.id === row.id && round == 2 && (
                            <>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    true,
                                    row.id,
                                    "squat_second_attempt",
                                    "squat_third_attempt",
                                    "squat_second_attempt_results",
                                    3,
                                  )
                                }
                                aria-label="delete"
                              >
                                <DoneIcon sx={{ color: "green" }} />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    false,
                                    row.id,
                                    "squat_second_attempt",
                                    "squat_third_attempt",
                                    "squat_second_attempt_results",
                                    3,
                                  )
                                }
                                aria-label="delete"
                              >
                                <CloseIcon sx={{ color: "red" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setstartAction(!startAction)}
                                size="small"
                                aria-label="delete"
                              >
                                <AlarmOnIcon sx={{ color: "gray" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setressetAction(true)}
                                size="small"
                                aria-label="delete"
                              >
                                <RestoreIcon sx={{ color: "gray" }} />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          sx={{
                            backgroundColor:
                              !refreshedPlatform.squat_third_attempt_results
                                ? "none"
                                : refreshedPlatform.squat_third_attempt_results[
                                      row.id
                                    ] &&
                                    refreshedPlatform
                                      .squat_third_attempt_results[row.id] ===
                                      true
                                  ? "#008000"
                                  : refreshedPlatform.squat_third_attempt_results &&
                                      refreshedPlatform
                                        .squat_third_attempt_results[row.id] ===
                                        false
                                    ? "#CC0000"
                                    : "none",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <TextField
                              onChange={(e) =>
                                changeAttempt(e, "squat_third_attempt")
                              }
                              size="small"
                              sx={{ minWidth: "30px" }}
                              id={`Squat3-${row.id}`}
                              defaultValue={row.squat_third_attempt}
                              label=""
                              variant="standard"
                            />
                            <BasicMenu
                              competition={competition}
                              setRecord={(record) =>
                                setRecord(record, 3, row.id)
                              }
                              clearResult={() =>
                                clearResult(
                                  3,
                                  row.id,
                                  "squat_third_attempt_results",
                                )
                              }
                              goodResult={() =>
                                changeResult(
                                  3,
                                  row.id,
                                  true,
                                  "squat_third_attempt_results",
                                )
                              }
                              badResult={() =>
                                changeResult(
                                  3,
                                  row.id,
                                  false,
                                  "squat_third_attempt_results",
                                )
                              }
                              setCurrentAttemp={() => setCurrentAttemp(3, row)}
                            />
                          </div>
                          {current && current.id === row.id && round == 3 && (
                            <>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    true,
                                    row.id,
                                    "squat_third_attempt",
                                    "bench_first_attempt",
                                    "squat_third_attempt_results",
                                    4,
                                  )
                                }
                                aria-label="delete"
                              >
                                <DoneIcon sx={{ color: "green" }} />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    false,
                                    row.id,
                                    "squat_third_attempt",
                                    "bench_first_attempt",
                                    "squat_third_attempt_results",
                                    4,
                                  )
                                }
                                aria-label="delete"
                              >
                                <CloseIcon sx={{ color: "red" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setstartAction(!startAction)}
                                size="small"
                                aria-label="delete"
                              >
                                <AlarmOnIcon sx={{ color: "gray" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setressetAction(true)}
                                size="small"
                                aria-label="delete"
                              >
                                <RestoreIcon sx={{ color: "gray" }} />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        {/*<TableCell style={{border: 'solid',borderWidth: 1, maxWidth: 50}} align="right">
                                      <TextField onChange={(e)=>changeRack(e)} size="small" id={`RachHeight-${row.id}`} defaultValue={row.rack_height} label="" variant="standard" />
                                    </TableCell>*/}
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          align="right"
                        >
                          {row.bestSquat}
                        </TableCell>
                        {/*******************************************TERMINASQUAT********************************************/}

                        {/*
                         * AQUI COMIENZAN LOS CAMPOS DE BENCH
                         */}
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          sx={{
                            backgroundColor:
                              !refreshedPlatform.bench_first_attempt_results
                                ? "none"
                                : refreshedPlatform.bench_first_attempt_results[
                                      row.id
                                    ] &&
                                    refreshedPlatform
                                      .bench_first_attempt_results[row.id] ===
                                      true
                                  ? "#008000"
                                  : refreshedPlatform.bench_first_attempt_results &&
                                      refreshedPlatform
                                        .bench_first_attempt_results[row.id] ===
                                        false
                                    ? "#CC0000"
                                    : "none",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <TextField
                              onChange={(e) =>
                                changeAttempt(e, "bench_first_attempt")
                              }
                              size="small"
                              sx={{ minWidth: "30px" }}
                              id={`Squat1-${row.id}`}
                              defaultValue={row.bench_first_attempt}
                              label=""
                              variant="standard"
                            />
                            <BasicMenu
                              competition={competition}
                              setRecord={(record) =>
                                setRecord(record, 4, row.id)
                              }
                              clearResult={() =>
                                clearResult(
                                  4,
                                  row.id,
                                  "bench_first_attempt_results",
                                )
                              }
                              goodResult={() =>
                                changeResult(
                                  4,
                                  row.id,
                                  true,
                                  "bench_first_attempt_results",
                                )
                              }
                              badResult={() =>
                                changeResult(
                                  4,
                                  row.id,
                                  false,
                                  "bench_first_attempt_results",
                                )
                              }
                              setCurrentAttemp={() => setCurrentAttemp(4, row)}
                            />
                          </div>
                          {current && current.id === row.id && round == 4 && (
                            <>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    true,
                                    row.id,
                                    "bench_first_attempt",
                                    "bench_second_attempt",
                                    "bench_first_attempt_results",
                                    5,
                                  )
                                }
                                aria-label="delete"
                              >
                                <DoneIcon sx={{ color: "green" }} />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    false,
                                    row.id,
                                    "bench_first_attempt",
                                    "bench_second_attempt",
                                    "bench_first_attempt_results",
                                    5,
                                  )
                                }
                                aria-label="delete"
                              >
                                <CloseIcon sx={{ color: "red" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setstartAction(!startAction)}
                                size="small"
                                aria-label="delete"
                              >
                                <AlarmOnIcon sx={{ color: "gray" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setressetAction(true)}
                                size="small"
                                aria-label="delete"
                              >
                                <RestoreIcon sx={{ color: "gray" }} />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          sx={{
                            backgroundColor:
                              !refreshedPlatform.bench_second_attempt_results
                                ? "none"
                                : refreshedPlatform
                                      .bench_second_attempt_results[row.id] &&
                                    refreshedPlatform
                                      .bench_second_attempt_results[row.id] ===
                                      true
                                  ? "#008000"
                                  : refreshedPlatform.bench_second_attempt_results &&
                                      refreshedPlatform
                                        .bench_second_attempt_results[
                                        row.id
                                      ] === false
                                    ? "#CC0000"
                                    : "none",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <TextField
                              onChange={(e) =>
                                changeAttempt(e, "bench_second_attempt")
                              }
                              size="small"
                              sx={{ minWidth: "30px" }}
                              id={`Squat2-${row.id}`}
                              defaultValue={row.bench_second_attempt}
                              label=""
                              variant="standard"
                            />
                            <BasicMenu
                              competition={competition}
                              setRecord={(record) =>
                                setRecord(record, 5, row.id)
                              }
                              clearResult={() =>
                                clearResult(
                                  5,
                                  row.id,
                                  "bench_second_attempt_results",
                                )
                              }
                              goodResult={() =>
                                changeResult(
                                  5,
                                  row.id,
                                  true,
                                  "bench_second_attempt_results",
                                )
                              }
                              badResult={() =>
                                changeResult(
                                  5,
                                  row.id,
                                  false,
                                  "bench_second_attempt_results",
                                )
                              }
                              setCurrentAttemp={() => setCurrentAttemp(5, row)}
                            />
                          </div>
                          {current && current.id === row.id && round == 5 && (
                            <>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    true,
                                    row.id,
                                    "bench_second_attempt",
                                    "bench_third_attempt",
                                    "bench_second_attempt_results",
                                    6,
                                  )
                                }
                                aria-label="delete"
                              >
                                <DoneIcon sx={{ color: "green" }} />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    false,
                                    row.id,
                                    "bench_second_attempt",
                                    "bench_third_attempt",
                                    "bench_second_attempt_results",
                                    6,
                                  )
                                }
                                aria-label="delete"
                              >
                                <CloseIcon sx={{ color: "red" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setstartAction(!startAction)}
                                size="small"
                                aria-label="delete"
                              >
                                <AlarmOnIcon sx={{ color: "gray" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setressetAction(true)}
                                size="small"
                                aria-label="delete"
                              >
                                <RestoreIcon sx={{ color: "gray" }} />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          sx={{
                            backgroundColor:
                              !refreshedPlatform.bench_third_attempt_results
                                ? "none"
                                : refreshedPlatform.bench_third_attempt_results[
                                      row.id
                                    ] &&
                                    refreshedPlatform
                                      .bench_third_attempt_results[row.id] ===
                                      true
                                  ? "#008000"
                                  : refreshedPlatform.squat_third_attempt_results &&
                                      refreshedPlatform
                                        .bench_third_attempt_results[row.id] ===
                                        false
                                    ? "#CC0000"
                                    : "none",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <TextField
                              onChange={(e) =>
                                changeAttempt(e, "bench_third_attempt")
                              }
                              size="small"
                              sx={{ minWidth: "30px" }}
                              id={`Squat3-${row.id}`}
                              defaultValue={row.bench_third_attempt}
                              label=""
                              variant="standard"
                            />
                            <BasicMenu
                              competition={competition}
                              setRecord={(record) =>
                                setRecord(record, 6, row.id)
                              }
                              clearResult={() =>
                                clearResult(
                                  6,
                                  row.id,
                                  "bench_third_attempt_results",
                                )
                              }
                              goodResult={() =>
                                changeResult(
                                  6,
                                  row.id,
                                  true,
                                  "bench_third_attempt_results",
                                )
                              }
                              badResult={() =>
                                changeResult(
                                  6,
                                  row.id,
                                  false,
                                  "bench_third_attempt_results",
                                )
                              }
                              setCurrentAttemp={() => setCurrentAttemp(5, row)}
                            />
                          </div>
                          {current && current.id === row.id && round == 6 && (
                            <>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    true,
                                    row.id,
                                    "bench_third_attempt",
                                    "deadlift_first_attempt",
                                    "bench_third_attempt_results",
                                    7,
                                  )
                                }
                                aria-label="delete"
                              >
                                <DoneIcon sx={{ color: "green" }} />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    false,
                                    row.id,
                                    "bench_third_attempt",
                                    "deadlift_first_attempt",
                                    "bench_third_attempt_results",
                                    7,
                                  )
                                }
                                aria-label="delete"
                              >
                                <CloseIcon sx={{ color: "red" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setstartAction(!startAction)}
                                size="small"
                                aria-label="delete"
                              >
                                <AlarmOnIcon sx={{ color: "gray" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setressetAction(true)}
                                size="small"
                                aria-label="delete"
                              >
                                <RestoreIcon sx={{ color: "gray" }} />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        {/*<TableCell style={{border: 'solid',borderWidth: 1, maxWidth: 50}} align="right">
                                      <TextField onChange={(e)=>changeRack(e)} size="small" id={`RachHeight-${row.id}`} defaultValue={row.rack_height} label="" variant="standard" />
                                    </TableCell>*/}
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          align="right"
                        >
                          {row.bestBench}
                        </TableCell>
                        {/*******************************************TERMINABENCH********************************************/}
                        {/*
                         * AQUI COMIENZAN LOS CAMPOS DE DEADLIFT
                         */}
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          sx={{
                            backgroundColor:
                              !refreshedPlatform.deadlift_first_attempt_results
                                ? "none"
                                : refreshedPlatform
                                      .deadlift_first_attempt_results[row.id] &&
                                    refreshedPlatform
                                      .deadlift_first_attempt_results[
                                      row.id
                                    ] === true
                                  ? "#008000"
                                  : refreshedPlatform.deadlift_first_attempt_results &&
                                      refreshedPlatform
                                        .deadlift_first_attempt_results[
                                        row.id
                                      ] === false
                                    ? "#CC0000"
                                    : "none",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <TextField
                              onChange={(e) =>
                                changeAttempt(e, "deadlift_first_attempt")
                              }
                              size="small"
                              sx={{ minWidth: "30px" }}
                              id={`Squat1-${row.id}`}
                              defaultValue={row.deadlift_first_attempt}
                              label=""
                              variant="standard"
                            />
                            <BasicMenu
                              competition={competition}
                              setRecord={(record) =>
                                setRecord(record, 7, row.id)
                              }
                              clearResult={() =>
                                clearResult(
                                  7,
                                  row.id,
                                  "deadlift_first_attempt_results",
                                )
                              }
                              goodResult={() =>
                                changeResult(
                                  7,
                                  row.id,
                                  true,
                                  "deadlift_first_attempt_results",
                                )
                              }
                              badResult={() =>
                                changeResult(
                                  7,
                                  row.id,
                                  false,
                                  "deadlift_first_attempt_results",
                                )
                              }
                              setCurrentAttemp={() => setCurrentAttemp(7, row)}
                            />
                          </div>
                          {current && current.id === row.id && round == 7 && (
                            <>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    true,
                                    row.id,
                                    "deadlift_first_attempt",
                                    "deadlift_second_attempt",
                                    "deadlift_first_attempt_results",
                                    8,
                                  )
                                }
                                aria-label="delete"
                              >
                                <DoneIcon sx={{ color: "green" }} />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    false,
                                    row.id,
                                    "deadlift_first_attempt",
                                    "deadlift_second_attempt",
                                    "deadlift_first_attempt_results",
                                    8,
                                  )
                                }
                                aria-label="delete"
                              >
                                <CloseIcon sx={{ color: "red" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setstartAction(!startAction)}
                                size="small"
                                aria-label="delete"
                              >
                                <AlarmOnIcon sx={{ color: "gray" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setressetAction(true)}
                                size="small"
                                aria-label="delete"
                              >
                                <RestoreIcon sx={{ color: "gray" }} />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          sx={{
                            backgroundColor:
                              !refreshedPlatform.deadlift_second_attempt_results
                                ? "none"
                                : refreshedPlatform
                                      .deadlift_second_attempt_results[
                                      row.id
                                    ] &&
                                    refreshedPlatform
                                      .deadlift_second_attempt_results[
                                      row.id
                                    ] === true
                                  ? "#008000"
                                  : refreshedPlatform.deadlift_second_attempt_results &&
                                      refreshedPlatform
                                        .deadlift_second_attempt_results[
                                        row.id
                                      ] === false
                                    ? "#CC0000"
                                    : "none",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <TextField
                              onChange={(e) =>
                                changeAttempt(e, "deadlift_second_attempt")
                              }
                              size="small"
                              sx={{ minWidth: "30px" }}
                              id={`Squat2-${row.id}`}
                              defaultValue={row.deadlift_second_attempt}
                              label=""
                              variant="standard"
                            />
                            <BasicMenu
                              competition={competition}
                              setRecord={(record) =>
                                setRecord(record, 8, row.id)
                              }
                              clearResult={() =>
                                clearResult(
                                  8,
                                  row.id,
                                  "deadlift_second_attempt_results",
                                )
                              }
                              goodResult={() =>
                                changeResult(
                                  8,
                                  row.id,
                                  true,
                                  "deadlift_second_attempt_results",
                                )
                              }
                              badResult={() =>
                                changeResult(
                                  8,
                                  row.id,
                                  false,
                                  "deadlift_second_attempt_results",
                                )
                              }
                              setCurrentAttemp={() => setCurrentAttemp(8, row)}
                            />
                          </div>
                          {current && current.id === row.id && round == 8 && (
                            <>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    true,
                                    row.id,
                                    "deadlift_second_attempt",
                                    "deadlift_third_attempt",
                                    "deadlift_second_attempt_results",
                                    9,
                                  )
                                }
                                aria-label="delete"
                              >
                                <DoneIcon sx={{ color: "green" }} />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    false,
                                    row.id,
                                    "deadlift_second_attempt",
                                    "deadlift_third_attempt",
                                    "deadlift_second_attempt_results",
                                    9,
                                  )
                                }
                                aria-label="delete"
                              >
                                <CloseIcon sx={{ color: "red" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setstartAction(!startAction)}
                                size="small"
                                aria-label="delete"
                              >
                                <AlarmOnIcon sx={{ color: "gray" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setressetAction(true)}
                                size="small"
                                aria-label="delete"
                              >
                                <RestoreIcon sx={{ color: "gray" }} />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          sx={{
                            backgroundColor:
                              !refreshedPlatform.deadlift_third_attempt_results
                                ? "none"
                                : refreshedPlatform
                                      .deadlift_third_attempt_results[row.id] &&
                                    refreshedPlatform
                                      .deadlift_third_attempt_results[
                                      row.id
                                    ] === true
                                  ? "#008000"
                                  : refreshedPlatform.deadlift_third_attempt_results &&
                                      refreshedPlatform
                                        .deadlift_third_attempt_results[
                                        row.id
                                      ] === false
                                    ? "#CC0000"
                                    : "none",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <TextField
                              onChange={(e) =>
                                changeAttempt(e, "deadlift_third_attempt")
                              }
                              size="small"
                              sx={{ minWidth: "30px" }}
                              id={`Squat3-${row.id}`}
                              defaultValue={row.deadlift_third_attempt}
                              label=""
                              variant="standard"
                            />
                            <BasicMenu
                              competition={competition}
                              setRecord={(record) =>
                                setRecord(record, 9, row.id)
                              }
                              clearResult={() =>
                                clearResult(9, row.id, "deadlift_third_attempt")
                              }
                              goodResult={() =>
                                changeResult(
                                  9,
                                  row.id,
                                  true,
                                  "deadlift_third_attempt_results",
                                )
                              }
                              badResult={() =>
                                changeResult(
                                  9,
                                  row.id,
                                  false,
                                  "deadlift_third_attempt_results",
                                )
                              }
                              setCurrentAttemp={() => setCurrentAttemp(1, row)}
                            />
                          </div>
                          {current && current.id === row.id && round == 9 && (
                            <>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    true,
                                    row.id,
                                    "deadlift_third_attempt",
                                    null,
                                    null,
                                    null,
                                  )
                                }
                                aria-label="delete"
                              >
                                <DoneIcon sx={{ color: "green" }} />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  goodLift(
                                    false,
                                    row.id,
                                    "deadlift_third_attempt",
                                    null,
                                    null,
                                    null,
                                  )
                                }
                                aria-label="delete"
                              >
                                <CloseIcon sx={{ color: "red" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setstartAction(!startAction)}
                                size="small"
                                aria-label="delete"
                              >
                                <AlarmOnIcon sx={{ color: "gray" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => setressetAction(true)}
                                size="small"
                                aria-label="delete"
                              >
                                <RestoreIcon sx={{ color: "gray" }} />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        {/*<TableCell style={{border: 'solid',borderWidth: 1, maxWidth: 50}} align="right">
                                      <TextField onChange={(e)=>changeRack(e)} size="small" id={`RachHeight-${row.id}`} defaultValue={row.rack_height} label="" variant="standard" />
                                    </TableCell>*/}
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          align="right"
                        >
                          {row.bestDeadlift}
                        </TableCell>
                        {/*******************************************************************************/}
                        {/*<TableCell style={{border: 'solid',borderWidth: 1}} align="right">{refreshedPlatform && refreshedPlatform.first_attempt_results && refreshedPlatform.second_attempt_results && refreshedPlatform.third_attempt_results ? (
                                                          <>
                                                            {refreshedPlatform.first_attempt_results[row.id] && refreshedPlatform.second_attempt_results[row.id] && refreshedPlatform.third_attempt_results[row.id] ? row.first_attempt + row.second_attempt + row.third_attempt : null}
                                                        {refreshedPlatform.first_attempt_results[row.id] && refreshedPlatform.second_attempt_results[row.id] && !refreshedPlatform.third_attempt_results[row.id] ? row.first_attempt + row.second_attempt : null}
                                                        {refreshedPlatform.first_attempt_results[row.id] && !refreshedPlatform.second_attempt_results[row.id] && refreshedPlatform.third_attempt_results[row.id] ? row.first_attempt + row.third_attempt : null}
                                                        {!refreshedPlatform.first_attempt_results[row.id] && refreshedPlatform.second_attempt_results[row.id] && refreshedPlatform.third_attempt_results[row.id] ? row.second_attempt + row.third_attempt : null}
                                                        {refreshedPlatform.first_attempt_results[row.id] && !refreshedPlatform.second_attempt_results[row.id] && !refreshedPlatform.third_attempt_results[row.id] ? row.first_attempt : null}
                                                        {!refreshedPlatform.first_attempt_results[row.id] && refreshedPlatform.second_attempt_results[row.id] && !refreshedPlatform.third_attempt_results[row.id] ? row.second_attempt : null}
                                                        {!refreshedPlatform.first_attempt_results[row.id] && !refreshedPlatform.second_attempt_results[row.id] && refreshedPlatform.third_attempt_results[row.id] ? row.third_attempt : null}
                                                        {!refreshedPlatform.first_attempt_results[row.id] && !refreshedPlatform.second_attempt_results[row.id] && !refreshedPlatform.third_attempt_results[row.id] ? '-' : null}
                                                          </>) : '-'}
                                    </TableCell>*/}
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          align="right"
                        >
                          {
                            /*pDots(row.best, row.sex, row.bodyweight)*/ calculateWilksScore(
                              row.sex,
                              row.bodyweight,
                              row.best,
                            )
                          }
                        </TableCell>
                        <TableCell
                          style={{ border: "solid", borderWidth: 1 }}
                          align="right"
                        >
                          {getRank(
                            row,
                            liftersInTable.filter(
                              (lifter) => lifter.BWC === row.BWC,
                            ),
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    );

  return <Loading />;
};

const maleValues = [
  -216.0475144, 16.2606339, -0.002388645, -0.00113732, 7.01863e-6, -1.291e-8,
];

const femaleValues = [
  594.31747775582, -27.23842536447, 0.82112226871, -0.00930733913, 4.731582e-5,
  -9.054e-8,
];

const imperial = 2.20462262185;

function calculateWilksScore(
  gender,
  bodyWeight,
  liftedWeight,
  unitType = "metric",
) {
  // if (!gender || !bodyWeight || !liftedWeight) {
  //   console.log('Missing parameters, please fill in gender, body weight and weight.');
  // }

  if (unitType === "imperial") {
    liftedWeight /= imperial;
    bodyWeight /= imperial;
  }

  validateInput({
    gender: gender,
    bodyWeight: parseFloat(bodyWeight),
    liftedWeight: liftedWeight,
    unitType: unitType,
  });

  let coeff = 500 / calculateCoefficient(gender, bodyWeight);

  return (liftedWeight * coeff).toFixed(3);
}

function calculateCoefficient(gender, bodyWeight) {
  let coeff = 0;
  let values = gender === "m" ? maleValues : femaleValues;

  for (let i = 0; i <= 5; i++) {
    coeff += i === 0 ? values[i] : values[i] * bodyWeight ** i;
  }

  return coeff;
}

function validateInput({
  gender,
  bodyWeight = 0,
  liftedWeight = 0,
  wilksScore = 0,
  unitType,
}) {
  if (typeof gender !== "string" || (gender !== "m" && gender !== "f")) {
    console.log(
      "Gender is not valid. Please select m for Male or f for Female.",
    );
  }

  if (typeof bodyWeight !== "number" || bodyWeight < 0) {
    console.log("Body weight is not valid.");
  }

  if (typeof liftedWeight !== "number" || liftedWeight < 0) {
    console.log("Weight is not valid.");
  }

  if (typeof wilksScore !== "number" || wilksScore < 0) {
    console.log("Wilks score is not valid.");
  }

  if (
    typeof unitType !== "string" ||
    (unitType !== "metric" && unitType !== "imperial")
  ) {
    console.log("Unit type is not valid. Please select metric or imperial.");
  }
}

function getRank(meIam, lifters) {
  lifters.sort((a, b) => {
    return a.best > b.best ? -1 : 1;
  });

  return lifters.map((lifter) => lifter.id).indexOf(meIam.id) + 1;
}

function pDots(best, sex, bw) {
  var inputLabel = "Best Lift",
    inputUnit = "kg",
    outputLabel = "Score",
    outputUnit = "pts",
    steps = 1;
  var a,
    b,
    c,
    d,
    e,
    bwsum,
    result,
    total = 0.0;
  var params = {
    1: {
      A: -7.93954283e-7,
      B: 0.000493457474,
      C: -0.1231642956,
      D: 16.0233664,
      E: 45.59224,
    },
    2: {
      A: -2.55986906e-6,
      B: 0.00116119212,
      C: -0.205352889,
      D: 17.3690866,
      E: 55.4261,
    },
  };

  var gender = sex === "m" || sex === "M" || sex === "male" ? 1 : 2;
  a = params[gender].A;
  b = params[gender].B;
  c = params[gender].C;
  d = params[gender].D;
  e = params[gender].E;

  const value = best;
  bwsum =
    a * Math.pow(bw, 4) +
    b * Math.pow(bw, 3) +
    c * Math.pow(bw, 2) +
    d * bw +
    e;
  var exactResult = (value * 500) / bwsum;
  result = Math.round(exactResult * 1000) / 1000;

  if (!isNaN(result) && result !== Infinity) {
    total += result.toFixed(3);
  }

  var totalResult = Math.round(total * 1000) / 1000;

  return `${totalResult.toFixed(3)}`;
}

function BasicMenu(props) {
  const { competition } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        size="small"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ minWidth: 40 }}
      >
        <MoreHorizIcon size="10" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            props.clearResult();
            setAnchorEl(null);
          }}
        >
          Clear Result
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.goodResult();
            setAnchorEl(null);
          }}
        >
          Mark as Good
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.badResult();
            setAnchorEl(null);
          }}
        >
          Mark as Bad
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.setCurrentAttemp();
            setAnchorEl(null);
          }}
        >
          Set as Current
        </MenuItem>
        {competition && competition.records
          ? competition.records.split(",").map((record) => (
              <MenuItem
                onClick={() => {
                  props.setRecord(record);
                  // alert(record)
                  setAnchorEl(null);
                }}
              >
                Set as {record}
              </MenuItem>
            ))
          : null}
      </Menu>
    </div>
  );
}
