import * as React from 'react';
import { useState } from 'react';
import { useLogin, useNotify, Notification, TextInput, useDataProvider } from 'react-admin';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Parse from 'parse';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.paralympic.org/powerlifting/rules">
        Carlos A Robinson L
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function MyLoginPage({ theme }) {
  // const MyLoginPage = ({ theme }) => {
    const [email, setEmail] = useState('');
    const [currentPage, setCurrentPage] = useState('login');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    
    const handleSubmit = e => {
        e.preventDefault();
        if(currentPage==='login') {
            login({ username: email, password }).catch(() =>
                notify('Invalid email or password')
            );    
        }else{
            const user = new Parse.User();
            user.set("username", email);
            user.set("password", password);
            user.set("email", email);

            // dataProvider.create('users', { data: { email, password } })
            user.signUp()
                .then(() => {
                    login({ username: email, password }).catch(() =>
                        notify('Invalid email or password')
                    );          
                })
        }
        
    };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
                label="Email Address"
                fullWidth
                name="email"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
            />
            <TextField
                label="Password"
                fullWidth
                name="password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
                {currentPage==='login' ? "Sign In" : "Sign Up"}
            </Button>
            <Grid container>
              <Grid item xs>
                {/*<Link href="#" variant="body2">
                  Forgot password?
                </Link>*/}
              </Grid>
              <Grid item>
                <Link 
                    style={{
                        cursor: 'pointer'
                    }}
                    onClick={() => currentPage==='login' ? setCurrentPage('registro') : setCurrentPage('login')}
                    variant="body2">
                  {currentPage==='login' ? "Don't have an account? Sign Up" : "I already have an account. Sign In"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}