import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  Edit,
  SimpleForm,
  ReferenceInput,
  TextInput,
  Create,
  NumberInput,
  AutocompleteArrayInput,
  SelectInput,
  required,
  DeleteButton,
  Button,
  AutocompleteInput,
  FormDataConsumer,
  useRecordContext,
  CreateButton,
  useDataProvider,
  useRefresh,
  FunctionField,
  TopToolbar,
  FilterButton,
  useGetList,
  Loading,
  ShowButton,
  SelectArrayInput,
  Show,
  SimpleShowLayout,
  useGetOne,
  WithListContext,
  downloadCSV,
  ExportButton,
  ReferenceArrayInput,
  SelectArrayField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import { useController } from "react-hook-form";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { useParams } from "react-router-dom";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import AwardIcon from "@mui/icons-material/EmojiEvents";
import PrintIcon from "@mui/icons-material/Print";
import ListIcon from "@mui/icons-material/List";
// import { MensWc, WomensWc } from "./MensWc";
import { Typography, TextField as MUITextField, Stack } from "@mui/material";
import { wilks2020 } from "powerlifting-formulas";
// import {Countries as countries} from './Countries'
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Parse from "parse";

// const user = Parse.User.current();
// console.log(user)
const maleValues = [
  -216.0475144, 16.2606339, -0.002388645, -0.00113732, 7.01863e-6, -1.291e-8,
];

const femaleValues = [
  594.31747775582, -27.23842536447, 0.82112226871, -0.00930733913, 4.731582e-5,
  -9.054e-8,
];

const imperial = 2.20462262185;

function calculateWilksScore(
  gender,
  bodyWeight,
  liftedWeight,
  unitType = "metric",
) {
  // if (!gender || !bodyWeight || !liftedWeight) {
  //   console.log('Missing parameters, please fill in gender, body weight and weight.');
  // }

  if (unitType === "imperial") {
    liftedWeight /= imperial;
    bodyWeight /= imperial;
  }

  validateInput({
    gender: gender,
    bodyWeight: bodyWeight,
    liftedWeight: liftedWeight,
    unitType: unitType,
  });

  let coeff = 500 / calculateCoefficient(gender, bodyWeight);

  return (liftedWeight * coeff).toFixed(3);
}

function calculateCoefficient(gender, bodyWeight) {
  let coeff = 0;
  let values = gender === "m" ? maleValues : femaleValues;

  for (let i = 0; i <= 5; i++) {
    coeff += i === 0 ? values[i] : values[i] * bodyWeight ** i;
  }

  return coeff;
}

function validateInput({
  gender,
  bodyWeight = 0,
  liftedWeight = 0,
  wilksScore = 0,
  unitType,
}) {
  if (typeof gender !== "string" || (gender !== "m" && gender !== "f")) {
    console.log(
      "Gender is not valid. Please select m for Male or f for Female.",
    );
  }

  if (typeof bodyWeight !== "number" || bodyWeight < 0) {
    console.log("Body weight is not valid.");
  }

  if (typeof liftedWeight !== "number" || liftedWeight < 0) {
    console.log("Weight is not valid.");
  }

  if (typeof wilksScore !== "number" || wilksScore < 0) {
    console.log("Wilks score is not valid.");
  }

  if (
    typeof unitType !== "string" ||
    (unitType !== "metric" && unitType !== "imperial")
  ) {
    console.log("Unit type is not valid. Please select metric or imperial.");
  }
}

function pDots(best, sex, bw) {
  var inputLabel = "Best Lift",
    inputUnit = "kg",
    outputLabel = "Score",
    outputUnit = "pts",
    steps = 1;
  var a,
    b,
    c,
    d,
    e,
    bwsum,
    result,
    total = 0.0;
  var params = {
    1: {
      A: -7.93954283e-7,
      B: 0.000493457474,
      C: -0.1231642956,
      D: 16.0233664,
      E: 45.59224,
    },
    2: {
      A: -2.55986906e-6,
      B: 0.00116119212,
      C: -0.205352889,
      D: 17.3690866,
      E: 55.4261,
    },
  };

  var gender = sex === "m" || sex === "M" || sex === "male" ? 1 : 2;
  a = params[gender].A;
  b = params[gender].B;
  c = params[gender].C;
  d = params[gender].D;
  e = params[gender].E;

  const value = best;
  bwsum =
    a * Math.pow(bw, 4) +
    b * Math.pow(bw, 3) +
    c * Math.pow(bw, 2) +
    d * bw +
    e;
  var exactResult = (value * 500) / bwsum;
  result = Math.round(exactResult * 1000) / 1000;

  if (!isNaN(result) && result !== Infinity) {
    total += result.toFixed(3);
  }

  var totalResult = Math.round(total * 1000) / 1000;

  return `${totalResult.toFixed(3)}`;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Empty = () => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const { data: MensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "m",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const { data: WomensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "f",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  async function crearDivision(cat, i, sex) {
    const { data: competition } = await dataProvider.getOne("competitions", {
      id: localStorage.getItem("currentCompetition"),
    });

    // console.log(competition);
    // return;

    /**
     * Incluir participaciones
     * */
    const { data: participations } = await dataProvider.getList(
      "participation",
      {
        filter: {
          competition: localStorage.getItem("currentCompetition"),
        },
        pagination: {
          page: 1,
          perPage: 10000,
        },
        sort: {
          field: "createdAt",
          order: "DESC",
        },
      },
    );
    // console.log( participations );
    // return;
    if (competition.divisions.length) {
      if (participations.length) {
        for (const participation of participations) {
          for (const age_division of competition.divisions) {
            // Creo la division para cada movimiento de la competencia

            if (competition.squat) {
              const { data } = await dataProvider.create("award_divisions", {
                data: {
                  participation: participation.name,
                  name: cat.name,
                  movement: "squat",
                  categories: [cat.id],
                  sex,
                  divisions: [age_division],
                  competition: localStorage.getItem("currentCompetition"),
                  scored_by: "best_lift",
                },
              });
            }

            if (competition.bench) {
              const { data } = await dataProvider.create("award_divisions", {
                data: {
                  participation: participation.name,
                  name: cat.name,
                  movement: "bench",
                  categories: [cat.id],
                  sex,
                  divisions: [age_division],
                  competition: localStorage.getItem("currentCompetition"),
                  scored_by: "best_lift",
                },
              });
            }

            if (competition.deadlift) {
              const { data } = await dataProvider.create("award_divisions", {
                data: {
                  participation: participation.name,
                  name: cat.name,
                  movement: "deadlift",
                  categories: [cat.id],
                  sex,
                  divisions: [age_division],
                  competition: localStorage.getItem("currentCompetition"),
                  scored_by: "best_lift",
                },
              });
            }
          }
        }
      } else {
        for (const age_division of competition.divisions) {
          if (competition.squat) {
            const { data } = await dataProvider.create("award_divisions", {
              data: {
                name: cat.name,
                movement: "squat",
                categories: [cat.id],
                sex,
                divisions: [age_division],
                competition: localStorage.getItem("currentCompetition"),
                scored_by: "best_lift",
              },
            });
          }

          if (competition.bench) {
            const { data } = await dataProvider.create("award_divisions", {
              data: {
                name: cat.name,
                movement: "bench",
                categories: [cat.id],
                sex,
                divisions: [age_division],
                competition: localStorage.getItem("currentCompetition"),
                scored_by: "best_lift",
              },
            });
          }

          if (competition.deadlift) {
            const { data } = await dataProvider.create("award_divisions", {
              data: {
                name: cat.name,
                movement: "deadlift",
                categories: [cat.id],
                sex,
                divisions: [age_division],
                competition: localStorage.getItem("currentCompetition"),
                scored_by: "best_lift",
              },
            });
          }
        }
      }
    } else {
      if (participations.length) {
        for (const participation of participations) {
          if (competition.squat) {
            const { data } = await dataProvider.create("award_divisions", {
              data: {
                participation: participation.name,
                name: cat.name,
                movement: "squat",
                categories: [cat.id],
                sex,
                divisions: [],
                competition: localStorage.getItem("currentCompetition"),
                scored_by: "best_lift",
              },
            });
          }

          if (competition.bench) {
            const { data } = await dataProvider.create("award_divisions", {
              data: {
                participation: participation.name,
                name: cat.name,
                movement: "bench",
                categories: [cat.id],
                sex,
                divisions: [],
                competition: localStorage.getItem("currentCompetition"),
                scored_by: "best_lift",
              },
            });
          }

          if (competition.deadlift) {
            const { data } = await dataProvider.create("award_divisions", {
              data: {
                participation: participation.name,
                name: cat.name,
                movement: "deadlift",
                categories: [cat.id],
                sex,
                divisions: [],
                competition: localStorage.getItem("currentCompetition"),
                scored_by: "best_lift",
              },
            });
          }
        }
      } else {
        if (competition.squat) {
          const { data } = await dataProvider.create("award_divisions", {
            data: {
              name: cat.name,
              movement: "squat",
              categories: [cat.id],
              sex,
              divisions: [],
              competition: localStorage.getItem("currentCompetition"),
              scored_by: "best_lift",
            },
          });
        }

        if (competition.bench) {
          const { data } = await dataProvider.create("award_divisions", {
            data: {
              name: cat.name,
              movement: "bench",
              categories: [cat.id],
              sex,
              divisions: [],
              competition: localStorage.getItem("currentCompetition"),
              scored_by: "best_lift",
            },
          });
        }

        if (competition.deadlift) {
          const { data } = await dataProvider.create("award_divisions", {
            data: {
              name: cat.name,
              movement: "deadlift",
              categories: [cat.id],
              sex,
              divisions: [],
              competition: localStorage.getItem("currentCompetition"),
              scored_by: "best_lift",
            },
          });
        }
      }
    }
  }

  if (!MensWc && !WomensWc) {
    return <Loading />;
  }

  return (
    <Box sx={{ flex: 1 }} textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        No Award Divisions Created
      </Typography>
      <Typography variant="body1">
        Create one or create defaults per category
      </Typography>
      <CreateButton label="Create one" />
      <br />
      <Button
        variant="contained"
        onClick={async () => {
          MensWc.forEach((cat, i) => {
            crearDivision(cat, i, "m");
          });
          WomensWc.forEach((cat, i) => {
            crearDivision(cat, i, "f");
          });
        }}
      >
        <ListIcon /> Create Defaults
      </Button>
    </Box>
  );
};

const EmptyResults = () => {
  return (
    <Box sx={{ flex: 1 }} textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        No Award Divisions Created
      </Typography>
      <Typography variant="body1">
        You need to create Award Divisions to organize results
      </Typography>
    </Box>
  );
};

const AwardShow = () => {
  const { id } = useParams();
  const { data: record } = useGetOne("award_divisions", {
    id,
  });
  console.log(record);

  const { data: MensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "m",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const { data: WomensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "f",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const {
    data: teams,
    total5,
    isLoading5,
    error5,
  } = useGetList("teams", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "name",
      order: "ASC",
    },
  });

  const {
    data: platforms,
    total: total2,
    isLoading: isLoading2,
    error: error2,
  } = useGetList("platforms", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "name",
      order: "ASC",
    },
  });

  const {
    data: lifters,
    total,
    isLoading,
    error,
  } = useGetList("lifters", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      // weight_class: record ? record.categories : null,
      // divisions: record?record.divisions:null
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "surename",
      order: "ASC",
    },
  });

  const refreshedLifters =
    lifters && platforms
      ? lifters.map((row) => {
          const refreshedPlatform = platforms.find(
            (item) => item.id === row.platform,
          );
          const best =
            refreshedPlatform &&
            refreshedPlatform.third_attempt_results &&
            refreshedPlatform.third_attempt_results[row.id]
              ? row.third_attempt
              : refreshedPlatform &&
                  refreshedPlatform.second_attempt_results &&
                  refreshedPlatform.second_attempt_results[row.id]
                ? row.second_attempt
                : refreshedPlatform &&
                    refreshedPlatform.first_attempt_results &&
                    refreshedPlatform.first_attempt_results[row.id]
                  ? row.first_attempt
                  : null;
          return {
            ...row,
            best_result: best,
            wilk: wilks2020(
              row.bodyweight,
              best,
              row.sex === "m" ? "male" : "female",
            ), //calculateWilksScore(row.sex, row.bodyweight, best)//pDots(best, row.sex, row.bodyweight)
          };
        })
      : [];

  const {
    data: competition,
    total: total3,
    isLoading: isLoading3,
    error: error3,
  } = useGetOne("competitions", {
    id: localStorage.getItem("currentCompetition")
      ? localStorage.getItem("currentCompetition")
      : null,
  });

  if (isLoading || isLoading2 || isLoading3) {
    return <Loading />;
  }
  if (error || error2 || error3) {
    return <p>ERROR</p>;
  }

  const imprimir = () => {
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(`<style type="text/css" media="print" />
        body {font-size: 8pt; font-family:'Arial','Times New Roman',Times,serif;}
        table { page-break-inside: avoid;page-break-after:auto }
        tr    { page-break-inside:avoid; page-break-after:auto }
        td    { page-break-inside:avoid; page-break-after:auto; font-size: 8pt !important; font-family:'Arial','Times New Roman',Times,serif; }
        thead { display:table-header-group }
        tfoot { display:table-footer-group }</style>`);
    pri.document.write(document.getElementById("toprint").innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="name" />
        <IconButton
          autoFocus
          color="inherit"
          onClick={() => {
            imprimir();
          }}
        >
          <PrintIcon />
          Print
        </IconButton>
        <iframe
          title="pdf"
          id="ifmcontentstoprint"
          className="pdfIframe"
        ></iframe>
        <Box id="toprint">
          <div
            style={{
              padding: 4,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            colspan={2}
          >
            {competition && competition.pictures && competition.pictures[0] ? (
              <img src={competition.pictures[0].src} height="40" />
            ) : null}
            <h1>
              Results - {record && record.participation} -{" "}
              {record.categories &&
                record.categories
                  .map(
                    (item) =>
                      [...WomensWc, ...MensWc].find((wc) => wc.id === item)
                        .name,
                  )
                  .join(", ")}
            </h1>
          </div>
          <TableContainer component={Paper}>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 32,
                marginTop: 32,
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                      width: "15%",
                    }}
                  >
                    Competition
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition ? competition.meet_name : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    City and Country
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition ? competition.city_town : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    9-10/12/2023
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Bodyweight Category
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    <FunctionField
                      source="categories"
                      label="Categories"
                      render={(record) =>
                        record.sex === "f"
                          ? `${record.categories ? record.categories && record.categories.map((item) => WomensWc.find((wc) => wc.id === item).name).join(", ") : ""}`
                          : `${record.categories ? record.categories && record.categories.map((item) => MensWc.find((wc) => wc.id === item).name).join(", ") : ""}`
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 2,
              }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    align="right"
                  >
                    Lot No.
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                  >
                    Team
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                  >
                    DoB
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                  >
                    BW
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                  >
                    Rack No.
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    align="right"
                  >
                    1
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    align="right"
                  >
                    2
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    align="right"
                  >
                    3
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    align="right"
                  >
                    Best
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    align="right"
                  >
                    Wilks
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    align="right"
                  >
                    Rank
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {refreshedLifters &&
                  refreshedLifters
                    .filter((lifter) =>
                      record.categories
                        ? ((record.categories &&
                            record.categories.indexOf(lifter.weight_class) >=
                              0) ||
                            !record.categories ||
                            !record.categories.length) &&
                          lifter.divisions.some(
                            (divission) =>
                              record.divisions.indexOf(divission) >= 0,
                          ) &&
                          lifter.participation === record.participation
                        : record.sex === lifter.sex,
                    )
                    .sort((a, b) => {
                      if (a.wilk !== b.wilk)
                        return parseFloat(b.wilk) - parseFloat(a.wilk);
                      else if (a.bodyweight !== b.bodyweight)
                        return a.bodyweight < b.bodyweight ? -1 : 1;

                      return a.lot_number < b.lot_number ? -1 : 1;
                    })
                    .map((row, index) => {
                      const refreshedPlatform = platforms.find(
                        (item) => item.id === row.platform,
                      );
                      if (
                        refreshedPlatform &&
                        refreshedPlatform.first_attempt_results &&
                        refreshedPlatform.second_attempt_results &&
                        refreshedPlatform.third_attempt_results
                      )
                        return (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              align="right"
                            >
                              {row.lot_number}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              scope="row"
                            >
                              {row.surename} {row.firstname}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              scope="row"
                            >
                              {/*row.country*/}
                              {teams &&
                              teams.length &&
                              teams.find((item) => item.id === row.team)
                                ? teams.find((item) => item.id === row.team)
                                    .name
                                : null}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              scope="row"
                            >
                              {row.date_of_birth}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              scope="row"
                            >
                              {row.bodyweight}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              scope="row"
                            >
                              {row.rack_height}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              align="right"
                            >
                              {refreshedPlatform &&
                              !refreshedPlatform.first_attempt_results ? (
                                row.first_attempt
                              ) : refreshedPlatform.first_attempt_results[
                                  row.id
                                ] &&
                                refreshedPlatform.first_attempt_results[
                                  row.id
                                ] === true ? (
                                row.first_attempt
                              ) : refreshedPlatform.first_attempt_results &&
                                refreshedPlatform.first_attempt_results[
                                  row.id
                                ] === false ? (
                                <s>{row.first_attempt}</s>
                              ) : "" + " " + refreshedPlatform &&
                                !refreshedPlatform.first_attempt_records ? (
                                ""
                              ) : refreshedPlatform.first_attempt_records[
                                  row.id
                                ] ? (
                                ` ${Object.keys(refreshedPlatform.first_attempt_records[row.id])}`
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              align="right"
                            >
                              {refreshedPlatform &&
                              !refreshedPlatform.second_attempt_results ? (
                                row.second_attempt
                              ) : refreshedPlatform.second_attempt_results[
                                  row.id
                                ] &&
                                refreshedPlatform.second_attempt_results[
                                  row.id
                                ] === true ? (
                                row.second_attempt
                              ) : refreshedPlatform.second_attempt_results &&
                                refreshedPlatform.second_attempt_results[
                                  row.id
                                ] === false ? (
                                <s>{row.second_attempt}</s>
                              ) : (
                                ""
                              )}
                              {refreshedPlatform &&
                              !refreshedPlatform.second_attempt_records
                                ? ""
                                : refreshedPlatform.second_attempt_records[
                                      row.id
                                    ]
                                  ? ` ${Object.keys(refreshedPlatform.second_attempt_records[row.id])}`
                                  : ""}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              align="right"
                            >
                              {refreshedPlatform &&
                              !refreshedPlatform.third_attempt_results ? (
                                row.third_attempt
                              ) : refreshedPlatform.third_attempt_results[
                                  row.id
                                ] &&
                                refreshedPlatform.third_attempt_results[
                                  row.id
                                ] === true ? (
                                row.third_attempt
                              ) : refreshedPlatform.third_attempt_results &&
                                refreshedPlatform.third_attempt_results[
                                  row.id
                                ] === false ? (
                                <s>{row.third_attempt}</s>
                              ) : (
                                "DNF"
                              )}
                              {refreshedPlatform &&
                              !refreshedPlatform.third_attempt_records
                                ? ""
                                : refreshedPlatform.third_attempt_records[
                                      row.id
                                    ]
                                  ? ` ${Object.keys(refreshedPlatform.third_attempt_records[row.id])}`
                                  : ""}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              align="right"
                            >
                              {row.best_result ? row.best_result : "-"}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              align="right"
                            >
                              {wilks2020(
                                row.bodyweight,
                                record.movement === "squat"
                                  ? row.bestSquat
                                  : record.movement === "bench"
                                    ? row.bestBench
                                    : row.bestDeadlift,
                                row.sex === "m" ? "male" : "female",
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "solid",
                                borderWidth: 1,
                                padding: 4,
                              }}
                              align="right"
                            >
                              {!refreshedPlatform.first_attempt_results[
                                row.id
                              ] &&
                              !refreshedPlatform.second_attempt_results[
                                row.id
                              ] &&
                              !refreshedPlatform.third_attempt_results[row.id]
                                ? "-"
                                : index + 1}
                            </TableCell>
                          </TableRow>
                        );
                    })}
              </TableBody>
            </Table>
            <p>
              Chief Referee: <TextField source="chief_referee" />
              <br />
              Signature:
            </p>
            <p>
              Technical Delegate: <TextField source="tdelegate" />
              <br />
              Signature:
            </p>
          </TableContainer>
        </Box>
      </SimpleShowLayout>
    </Show>
  );
};

const ListActions = (props) => (
  <TopToolbar>
    {/*<FilterButton/>*/}
    <CreateButton />
    <ExportButton label="Export competition results" maxResults={50000} />
    {/* Add your custom actions */}
    {/*{<Button variant="contained"><FileDownloadIcon /> Export competition results</Button>}*/}
  </TopToolbar>
);

const postFilters = [
  <TextInput source="name" alwaysOn />,
  <TextInput source="movement" alwaysOn />,
  <ReferenceInput
    filter={{ competition: localStorage.getItem("currentCompetition") }}
    source="categories"
    reference="weight_class"
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const AwardList = () => (
  <List
    emptyWhileLoading
    empty={<Empty />}
    filters={postFilters}
    filter={{
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    }}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" />
      {/* <TextField source="participation" /> */}
      <TextField source="movement" />
      <ReferenceArrayField
        label="Age divisions"
        reference="divisions"
        source="divisions"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        label="categories"
        reference="weight_class"
        source="categories"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <EditButton />
      {/*<ShowButton />*/}
      <DeleteButton />
    </Datagrid>
  </List>
);

export const AwardList2 = () => {
  const user = Parse.User.current();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!localStorage.getItem("currentCompetition")) {
      alert("You must click on the competition you want to enter");
      navigate("/");
    }
  }, []);

  const { data: MensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "m",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const { data: WomensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "f",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const { data, isLoading, error } = useGetList("award_divisions", {
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "published_at",
      order: "DESC",
    },
    filter: {
      competition: localStorage.getItem("currentCompetition"),
    },
  });

  const {
    data: teams,
    total5,
    isLoading5,
    error5,
  } = useGetList("teams", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "name",
      order: "ASC",
    },
  });

  const {
    data: platforms,
    total: total2,
    isLoading: isLoading2,
    error: error2,
  } = useGetList("platforms", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "name",
      order: "ASC",
    },
  });

  const {
    data: lifters,
    total,
    isLoading: isLoadingL,
    error: errorL,
  } = useGetList("lifters", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      // weight_class: record ? record.categories : null,
      // divisions: record?record.divisions:null
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "surename",
      order: "ASC",
    },
  });

  const { data: participation } = useGetList("participation", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
  });

  const { data: divisions } = useGetList("divisions", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
  });

  const refreshedLifters =
    lifters && platforms
      ? lifters.map((row) => {
          const refreshedPlatform = platforms.find(
            (item) => item.id === row.platform,
          );
          const best =
            refreshedPlatform &&
            refreshedPlatform.third_attempt_results &&
            refreshedPlatform.third_attempt_results[row.id]
              ? row.third_attempt
              : refreshedPlatform &&
                  refreshedPlatform.second_attempt_results &&
                  refreshedPlatform.second_attempt_results[row.id]
                ? row.second_attempt
                : refreshedPlatform &&
                    refreshedPlatform.first_attempt_results &&
                    refreshedPlatform.first_attempt_results[row.id]
                  ? row.first_attempt
                  : null;
          return {
            ...row,
            bestSquat:
              refreshedPlatform &&
              refreshedPlatform.squat_third_attempt_results &&
              refreshedPlatform.squat_third_attempt_results[row.id]
                ? row.squat_third_attempt
                : refreshedPlatform.squat_second_attempt_results &&
                    refreshedPlatform.squat_second_attempt_results[row.id]
                  ? row.squat_second_attempt
                  : refreshedPlatform.squat_first_attempt_results &&
                      refreshedPlatform.squat_first_attempt_results[row.id]
                    ? row.squat_first_attempt
                    : null,
            bestBench:
              refreshedPlatform &&
              refreshedPlatform.bench_third_attempt_results &&
              refreshedPlatform.bench_third_attempt_results[row.id]
                ? row.bench_third_attempt
                : refreshedPlatform.bench_second_attempt_results &&
                    refreshedPlatform.bench_second_attempt_results[row.id]
                  ? row.bench_second_attempt
                  : refreshedPlatform.bench_first_attempt_results &&
                      refreshedPlatform.bench_first_attempt_results[row.id]
                    ? row.bench_first_attempt
                    : null,
            bestDeadlift:
              refreshedPlatform &&
              refreshedPlatform.deadlift_third_attempt_results &&
              refreshedPlatform.deadlift_third_attempt_results[row.id]
                ? row.deadlift_third_attempt
                : refreshedPlatform.deadlift_second_attempt_results &&
                    refreshedPlatform.deadlift_second_attempt_results[row.id]
                  ? row.deadlift_second_attempt
                  : refreshedPlatform.deadlift_first_attempt_results &&
                      refreshedPlatform.deadlift_first_attempt_results[row.id]
                    ? row.deadlift_first_attempt
                    : null,
            best_result: best,
            wilk: " ",
            //wilks2020(
            //row.bodyweight,
            //best,
            //row.sex === "m" ? "male" : "female",
            //), //calculateWilksScore(row.sex, row.bodyweight, best)//pDots(best, row.sex, row.bodyweight)
          };
        })
      : [];

  const {
    data: competition,
    total: total3,
    isLoading: isLoading3,
    error: error3,
  } = useGetOne("competitions", {
    id: localStorage.getItem("currentCompetition")
      ? localStorage.getItem("currentCompetition")
      : null,
  });

  const exporter = (award_division) => {
    const rows = [];
    for (var i = 0; i < award_division.length; i++) {
      const record = award_division[i];
      const divisionrows =
        refreshedLifters &&
        refreshedLifters
          .sort((a, b) => {
            return b.best_result < a.best_result ? -1 : 1;
          })
          .filter(
            (lifter) =>
              record.categories.indexOf(lifter.weight_class) >= 0 &&
              lifter.divisions.some(
                (divission) => record.divisions.indexOf(divission) >= 0,
              ),
          );
      // console.log(divisionrows)
      for (var j = 0; j < divisionrows.length; j++) {
        const row = divisionrows[j];
        const refreshedPlatform = platforms.find(
          (item) => item.id === row.platform,
        );
        const record1 =
          refreshedPlatform &&
          refreshedPlatform.first_attempt_records &&
          refreshedPlatform.first_attempt_records[row.id]
            ? " " + Object.keys(refreshedPlatform.first_attempt_records[row.id])
            : "";
        const record2 =
          refreshedPlatform &&
          refreshedPlatform.second_attempt_records &&
          refreshedPlatform.second_attempt_records[row.id]
            ? " " +
              Object.keys(refreshedPlatform.second_attempt_records[row.id])
            : "";
        const record3 =
          refreshedPlatform &&
          refreshedPlatform.third_attempt_records &&
          refreshedPlatform.third_attempt_records[row.id]
            ? " " + Object.keys(refreshedPlatform.third_attempt_records[row.id])
            : "";
        if (refreshedPlatform)
          rows.push({
            award_division: record.name,
            lot_no: record.lot_number,
            surename: row.surename,
            firstname: row.firstname,
            country: row.country,
            dob: row.date_of_birth,
            bw: row.bodyweight,
            weight_class: row.weight_class,
            country: row.country,
            first_attempt:
              refreshedPlatform.first_attempt_results &&
              refreshedPlatform.first_attempt_results[row.id] &&
              refreshedPlatform.first_attempt_results[row.id] === true
                ? row.first_attempt.toString()
                : refreshedPlatform.first_attempt_results &&
                    refreshedPlatform.first_attempt_results[row.id] &&
                    refreshedPlatform.first_attempt_results[row.id] === false
                  ? "-" + row.first_attempt.toString()
                  : "" + record1,
            second_attempt:
              refreshedPlatform.second_attempt_results &&
              refreshedPlatform.second_attempt_results[row.id] &&
              refreshedPlatform.second_attempt_results[row.id] === true
                ? row.second_attempt.toString()
                : refreshedPlatform.second_attempt_results &&
                    refreshedPlatform.second_attempt_results[row.id] &&
                    refreshedPlatform.second_attempt_results[row.id] === false
                  ? "-" + row.second_attempt.toString()
                  : "" + record2,
            third_attempt:
              refreshedPlatform.third_attempt_results &&
              refreshedPlatform.third_attempt_results[row.id] &&
              refreshedPlatform.third_attempt_results[row.id] === true
                ? row.third_attempt.toString()
                : refreshedPlatform.third_attempt_results &&
                    refreshedPlatform.third_attempt_results[row.id] &&
                    refreshedPlatform.third_attempt_results[row.id] === false
                  ? "-" + row.third_attempt.toString()
                  : "DNF" + record3,
            best: row.best_result ? row.best_result.toString() : "-",
            place:
              !refreshedPlatform.first_attempt_results[row.id] &&
              !refreshedPlatform.second_attempt_results[row.id] &&
              !refreshedPlatform.third_attempt_results[row.id]
                ? "-"
                : j + 1,
          });
      }
    }
    console.log(rows);
    // award_division.forEach(record => {
    //     // const { backlinks, author, ...postForExport } = post; // omit backlinks and author
    //     // postForExport.author_name = post.author.name; // add a field

    //     row.forEach((row, index) => {
    //       const refreshedPlatform = platforms.find(item => item.id === row.platform);
    //       results.push({
    //         award_division: record.name,
    //         lot_no: record.lot_number,
    //         name: row.fullname,
    //         country: row.country,
    //         team: teams && teams.length && teams.find(item => item.id === row.team) ? teams.find(item => item.id === row.team).name : "",
    //         dob: row.date_of_birth,
    //         bw: row.bodyweight,
    //         weight_class: row.weight_class,
    //     });
    //   })
    // results.push(row)
    // console.log(row);
    // return row;
    // });

    jsonExport(
      rows,
      {
        rows: [
          "award_division",
          "lot_no",
          "name",
          "country",
          "team",
          "dob",
          "bw",
          "weight_class",
          "first_attempt",
          "second_attempt",
          "third_attempt",
          "best",
          "total",
          "nbr",
          "place",
        ], // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, competition.name + "-Results"); // download as 'results.csv` file
      },
    );
  };

  if (
    isLoading ||
    isLoading2 ||
    isLoading3 ||
    !refreshedLifters ||
    isLoadingL ||
    !MensWc ||
    !WomensWc ||
    !divisions
  ) {
    return <Loading />;
  }
  if (error || error2 || error3 || errorL) {
    return <p>ERROR</p>;
  }

  // console.log(data)
  if (!data || !data.length || !participation) return <Empty />;

  const imprimir = () => {
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(`<style type="text/css" media="print" />
        body {font-size: 8pt; font-family:'Arial','Times New Roman',Times,serif;}
        table { page-break-inside: avoid;page-break-after:auto }
        tr    { page-break-inside:avoid; page-break-after:auto }
        td    { page-break-inside:avoid; page-break-after:auto; font-size: 8pt !important; font-family:'Arial','Times New Roman',Times,serif; }
        thead { display:table-header-group }
        tfoot { display:table-footer-group }</style>`);
    pri.document.write(document.getElementById("toprint").innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  return (
    <List
      exporter={exporter}
      emptyWhileLoading
      actions={<ListActions />}
      empty={<EmptyResults />}
      filters={postFilters}
      resource="award_divisions"
      filter={{
        competition: localStorage.getItem("currentCompetition")
          ? localStorage.getItem("currentCompetition")
          : null,
      }}
    >
      <WithListContext
        render={({ data }) => (
          <Stack spacing={2} sx={{ padding: 2 }}>
            {data.map((record) => (
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <FunctionField
                    record={record}
                    source="categories"
                    label="Categories"
                    render={(record) =>
                      record.sex === "f" ? (
                        <h3>
                          {record.categories
                            ? `${record.categories && record.categories.map((item) => WomensWc.find((wc) => wc.id === item).name).join(", ")} ${divisions && record.divisions ? record.divisions.map((divi) => divisions.find((l) => l.id === divi).name).join(", ") : ""}`
                            : `${record.name}`}{" "}
                          - {record.movement}
                        </h3>
                      ) : (
                        <h3>
                          {record.categories
                            ? `${record.categories && record.categories.map((item) => MensWc.find((wc) => wc.id === item).name).join(", ")} ${divisions && record.divisions ? record.divisions.map((divi) => divisions.find((l) => l.id === divi).name).join(", ") : ""}`
                            : `${record.name}`}{" "}
                          - {record.movement}
                        </h3>
                      )
                    }
                  />

                  {/*<IconButton autoFocus color="inherit" onClick={()=>{
                                              imprimir()
                                            }}>
                                              <PrintIcon />
                                              Print
                                            </IconButton>*/}
                  <Box display="flex">
                    <ShowButton record={record} />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <TableContainer component={Paper}>
                    <Table
                      className="myFormat"
                      size="small"
                      style={{
                        width: "100%",
                        border: "solid",
                        borderWidth: 1,
                        borderCollapse: "collapse",
                        marginBottom: 32,
                        marginTop: 32,
                      }}
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                              fontWeight: "bold",
                              width: "15%",
                            }}
                          >
                            Competition
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            colspan={2}
                          >
                            {competition ? competition.meet_name : null}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                              fontWeight: "bold",
                            }}
                          >
                            City and Country
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            colspan={2}
                          >
                            {competition ? competition.city_town : null}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                              fontWeight: "bold",
                            }}
                          >
                            Date
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            colspan={2}
                          >
                            {/* 9-10/12/2023 */}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                              fontWeight: "bold",
                            }}
                          >
                            Bodyweight Category
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            colspan={2}
                          >
                            {record.sex === "f"
                              ? `${record.categories ? record.categories.map((item) => WomensWc.find((wc) => wc.id === item).name).join(", ") : ""}`
                              : `${record.categories ? record.categories.map((item) => MensWc.find((wc) => wc.id === item).name).join(", ") : ""}`}
                            <FunctionField
                              source="categories"
                              label="Categories"
                              render={(record) =>
                                record.sex === "f"
                                  ? `${record.categories ? record.categories.map((item) => WomensWc.find((wc) => wc.id === item).name).join(", ") : ""}`
                                  : `${record.categories ? record.categories.map((item) => MensWc.find((wc) => wc.id === item).name).join(", ") : ""}`
                              }
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table
                      style={{
                        width: "100%",
                        border: "solid",
                        borderWidth: 1,
                        borderCollapse: "collapse",
                        marginBottom: 2,
                      }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            align="right"
                          >
                            Lot No.
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                          >
                            Team
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                          >
                            DoB
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                          >
                            BW
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                          >
                            Weight Class
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            align="right"
                          >
                            1
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            align="right"
                          >
                            2
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            align="right"
                          >
                            3
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            align="right"
                          >
                            Best
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            align="right"
                          >
                            Wilks
                          </TableCell>
                          <TableCell
                            style={{
                              border: "solid",
                              borderWidth: 1,
                              padding: 4,
                            }}
                            align="right"
                          >
                            Rank
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {refreshedLifters &&
                          refreshedLifters
                            .filter((lifter) =>
                              record.categories
                                ? (record.categories &&
                                    record.categories.indexOf(
                                      lifter.weight_class,
                                    ) >= 0) ||
                                  !record.categories ||
                                  !record.categories.length /*&&
                                  lifter.divisions.some(
                                    (divission) =>
                                      record.divisions.indexOf(divission) >= 0,
                                  ) /*&&
                                  lifter.participation === record.participation*/
                                : record.sex === lifter.sex,
                            )
                            .sort((a, b) => {
                              a.wilk = wilks2020(
                                a.bodyweight,
                                record.movement === "squat"
                                  ? a.bestSquat
                                  : record.movement === "bench"
                                    ? a.bestBench
                                    : a.bestDeadlift,
                                a.sex === "m" ? "male" : "female",
                              );

                              b.wilk = wilks2020(
                                b.bodyweight,
                                record.movement === "squat"
                                  ? b.bestSquat
                                  : record.movement === "bench"
                                    ? b.bestBench
                                    : b.bestDeadlift,
                                b.sex === "m" ? "male" : "female",
                              );
                              if (a.wilk !== b.wilk)
                                return parseFloat(b.wilk) - parseFloat(a.wilk);
                              else if (a.bodyweight !== b.bodyweight)
                                return a.bodyweight < b.bodyweight ? -1 : 1;

                              return a.lot_number < b.lot_number ? -1 : 1;
                            })
                            .map((row, index) => {
                              // console.log(row.platform)
                              const refreshedPlatform = platforms.find(
                                (item) => item.id === row.platform,
                              );
                              // console.log(refreshedPlatform)
                              if (
                                refreshedPlatform &&
                                // refreshedPlatform.first_attempt_results &&
                                // refreshedPlatform.second_attempt_results &&
                                // refreshedPlatform.third_attempt_results &&
                                row.bodyweight &&
                                row.bodyweight.length
                              )
                                return (
                                  <TableRow
                                    key={row.id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      align="right"
                                    >
                                      {row.lot_number}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      scope="row"
                                    >
                                      {row.surename} {row.firstname}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      scope="row"
                                    >
                                      {row.country}
                                      {/*teams &&
                                      teams.length &&
                                      teams.find((item) => item.id === row.team)
                                        ? teams.find(
                                            (item) => item.id === row.team,
                                          ).name
                                        : null*/}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      scope="row"
                                    >
                                      {row.date_of_birth}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      scope="row"
                                    >
                                      {row.bodyweight}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      scope="row"
                                    >
                                      {[...WomensWc, ...MensWc].find(
                                        (item) => item.id === row.weight_class,
                                      )
                                        ? [...WomensWc, ...MensWc].find(
                                            (item) =>
                                              item.id === row.weight_class,
                                          ).name
                                        : row.weight_class}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      align="right"
                                    >
                                      {record.movement === "squat" ? (
                                        refreshedPlatform &&
                                        !refreshedPlatform.squat_first_attempt_results ? (
                                          row.squat_first_attempt
                                        ) : refreshedPlatform
                                            .squat_first_attempt_results[
                                            row.id
                                          ] &&
                                          refreshedPlatform
                                            .squat_first_attempt_results[
                                            row.id
                                          ] === true ? (
                                          row.squat_first_attempt
                                        ) : refreshedPlatform.squat_first_attempt_results &&
                                          refreshedPlatform
                                            .squat_first_attempt_results[
                                            row.id
                                          ] === false ? (
                                          <s>{row.squat_first_attempt}</s>
                                        ) : null
                                      ) : null}

                                      {record.movement === "bench" ? (
                                        refreshedPlatform &&
                                        !refreshedPlatform.bench_first_attempt_results ? (
                                          row.bench_first_attempt
                                        ) : refreshedPlatform
                                            .bench_first_attempt_results[
                                            row.id
                                          ] &&
                                          refreshedPlatform
                                            .bench_first_attempt_results[
                                            row.id
                                          ] === true ? (
                                          row.bench_first_attempt
                                        ) : refreshedPlatform.bench_first_attempt_results &&
                                          refreshedPlatform
                                            .bench_first_attempt_results[
                                            row.id
                                          ] === false ? (
                                          <s>{row.bench_first_attempt}</s>
                                        ) : null
                                      ) : null}

                                      {record.movement === "deadlift" ? (
                                        refreshedPlatform &&
                                        !refreshedPlatform.deadlift_first_attempt_results ? (
                                          row.deadlift_first_attempt
                                        ) : refreshedPlatform
                                            .deadlift_first_attempt_results[
                                            row.id
                                          ] &&
                                          refreshedPlatform
                                            .deadlift_first_attempt_results[
                                            row.id
                                          ] === true ? (
                                          row.deadlift_first_attempt
                                        ) : refreshedPlatform.deadlift_first_attempt_results &&
                                          refreshedPlatform
                                            .deadlift_first_attempt_results[
                                            row.id
                                          ] === false ? (
                                          <s>{row.deadlift_first_attempt}</s>
                                        ) : null
                                      ) : null}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      align="right"
                                    >
                                      {record.movement === "squat" ? (
                                        refreshedPlatform &&
                                        !refreshedPlatform.squat_second_attempt_results ? (
                                          row.squat_second_attempt
                                        ) : refreshedPlatform
                                            .squat_second_attempt_results[
                                            row.id
                                          ] &&
                                          refreshedPlatform
                                            .squat_second_attempt_results[
                                            row.id
                                          ] === true ? (
                                          row.squat_second_attempt
                                        ) : refreshedPlatform.squat_second_attempt_results &&
                                          refreshedPlatform
                                            .squat_second_attempt_results[
                                            row.id
                                          ] === false ? (
                                          <s>{row.squat_second_attempt}</s>
                                        ) : null
                                      ) : null}
                                      {record.movement === "bench" ? (
                                        refreshedPlatform &&
                                        !refreshedPlatform.bench_second_attempt_results ? (
                                          row.bench_second_attempt
                                        ) : refreshedPlatform
                                            .bench_second_attempt_results[
                                            row.id
                                          ] &&
                                          refreshedPlatform
                                            .bench_second_attempt_results[
                                            row.id
                                          ] === true ? (
                                          row.bench_second_attempt
                                        ) : refreshedPlatform.bench_second_attempt_results &&
                                          refreshedPlatform
                                            .bench_second_attempt_results[
                                            row.id
                                          ] === false ? (
                                          <s>{row.bench_second_attempt}</s>
                                        ) : null
                                      ) : null}

                                      {record.movement === "deadlift" ? (
                                        refreshedPlatform &&
                                        !refreshedPlatform.deadlift_second_attempt_results ? (
                                          row.deadlift_second_attempt
                                        ) : refreshedPlatform
                                            .deadlift_second_attempt_results[
                                            row.id
                                          ] &&
                                          refreshedPlatform
                                            .deadlift_second_attempt_results[
                                            row.id
                                          ] === true ? (
                                          row.deadlift_second_attempt
                                        ) : refreshedPlatform.deadlift_second_attempt_results &&
                                          refreshedPlatform
                                            .deadlift_second_attempt_results[
                                            row.id
                                          ] === false ? (
                                          <s>{row.deadlift_second_attempt}</s>
                                        ) : null
                                      ) : null}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      align="right"
                                    >
                                      {record.movement === "squat" ? (
                                        refreshedPlatform &&
                                        !refreshedPlatform.squat_third_attempt_results ? (
                                          row.squat_third_attempt
                                        ) : refreshedPlatform
                                            .squat_third_attempt_results[
                                            row.id
                                          ] &&
                                          refreshedPlatform
                                            .squat_third_attempt_results[
                                            row.id
                                          ] === true ? (
                                          row.squat_third_attempt
                                        ) : refreshedPlatform.squat_third_attempt_results &&
                                          refreshedPlatform
                                            .squat_third_attempt_results[
                                            row.id
                                          ] === false ? (
                                          <s>{row.squat_third_attempt}</s>
                                        ) : null
                                      ) : record.movement === "squat" ? (
                                        "DNF"
                                      ) : null}

                                      {record.movement === "bench" ? (
                                        refreshedPlatform &&
                                        !refreshedPlatform.bench_third_attempt_results ? (
                                          row.bench_third_attempt
                                        ) : refreshedPlatform
                                            .bench_third_attempt_results[
                                            row.id
                                          ] &&
                                          refreshedPlatform
                                            .bench_third_attempt_results[
                                            row.id
                                          ] === true ? (
                                          row.bench_third_attempt
                                        ) : refreshedPlatform.bench_third_attempt_results &&
                                          refreshedPlatform
                                            .bench_third_attempt_results[
                                            row.id
                                          ] === false ? (
                                          <s>{row.bench_third_attempt}</s>
                                        ) : null
                                      ) : record.movement === "bench" ? (
                                        "DNF"
                                      ) : null}

                                      {record.movement === "deadlift" ? (
                                        refreshedPlatform &&
                                        !refreshedPlatform.deadlift_third_attempt_results ? (
                                          row.squat_third_attempt
                                        ) : refreshedPlatform
                                            .deadlift_third_attempt_results[
                                            row.id
                                          ] &&
                                          refreshedPlatform
                                            .deadlift_third_attempt_results[
                                            row.id
                                          ] === true ? (
                                          row.deadlift_third_attempt
                                        ) : refreshedPlatform.deadlift_third_attempt_results &&
                                          refreshedPlatform
                                            .deadlift_third_attempt_results[
                                            row.id
                                          ] === false ? (
                                          <s>{row.deadlift_third_attempt}</s>
                                        ) : null
                                      ) : record.movement === "deadlift" ? (
                                        "DNF"
                                      ) : null}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      align="right"
                                    >
                                      {record.movement === "squat" &&
                                      row.bestSquat
                                        ? row.bestSquat
                                        : null}
                                      {record.movement === "bench" &&
                                      row.bestBench
                                        ? row.bestBench
                                        : null}
                                      {record.movement === "deadlift" &&
                                      row.bestDeadlift
                                        ? row.bestDeadlift
                                        : null}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      align="right"
                                    >
                                      {wilks2020(
                                        row.bodyweight,
                                        record.movement === "squat"
                                          ? row.bestSquat
                                          : record.movement === "bench"
                                            ? row.bestBench
                                            : row.bestDeadlift,
                                        row.sex === "m" ? "male" : "female",
                                      )}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "solid",
                                        borderWidth: 1,
                                        padding: 4,
                                      }}
                                      align="right"
                                    >
                                      {record.movement === "squat" ? (
                                        <span>
                                          {!refreshedPlatform
                                            .squat_first_attempt_results[
                                            row.id
                                          ] &&
                                          !refreshedPlatform
                                            .squat_second_attempt_results[
                                            row.id
                                          ] &&
                                          !refreshedPlatform
                                            .squat_third_attempt_results[row.id]
                                            ? "-"
                                            : index + 1}
                                        </span>
                                      ) : null}

                                      {record.movement === "bench" ? (
                                        <span>
                                          {!refreshedPlatform
                                            .bench_first_attempt_results[
                                            row.id
                                          ] &&
                                          !refreshedPlatform
                                            .bench_second_attempt_results[
                                            row.id
                                          ] &&
                                          !refreshedPlatform
                                            .bench_third_attempt_results[row.id]
                                            ? "-"
                                            : index + 1}
                                        </span>
                                      ) : null}

                                      {record.movement === "deadlift" ? (
                                        <span>
                                          {!refreshedPlatform
                                            .deadlift_first_attempt_results[
                                            row.id
                                          ] &&
                                          !refreshedPlatform
                                            .deadlift_second_attempt_results[
                                            row.id
                                          ] &&
                                          !refreshedPlatform
                                            .deadlift_third_attempt_results[
                                            row.id
                                          ]
                                            ? "-"
                                            : index + 1}
                                        </span>
                                      ) : null}
                                    </TableCell>
                                  </TableRow>
                                );
                            })}
                      </TableBody>
                    </Table>
                    <p>
                      Chief Referee:{" "}
                      <TextField record={record} source="chief_referee" />
                      <br />
                      Signature:
                    </p>
                    <p>
                      Technical Delegate:{" "}
                      <TextField record={record} source="tdelegate" />
                      <br />
                      Signature:
                    </p>
                  </TableContainer>
                </Box>
              </>
            ))}
          </Stack>
        )}
      />
    </List>
  );
};

export const AwardEdit = () => {
  return (
    <Edit>
      <DetailsForm />
    </Edit>
  );
};

export const AwardCreate = () => {
  const user = Parse.User.current();
  return (
    <Create>
      <DetailsForm />
    </Create>
  );
};

const DetailsForm = () => {
  const user = Parse.User.current();
  const { id } = useParams();
  const { data: participation } = useGetList("participation", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
  });

  const { data: MensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "m",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const { data: WomensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "f",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  if (!participation || !MensWc || !WomensWc) return <Loading />;

  return (
    <SimpleForm>
      <TextInput
        label="Event"
        disabled
        defaultValue={localStorage.getItem("currentCompetition")}
        source="competition"
      />
      <TextInput fullWidth source="name" />
      <ReferenceArrayInput source="participation" reference="participation">
        <SelectArrayInput
          optionText="name"
          optionValue="name"
          label="Participations"
          filter={{
            competition: localStorage.getItem("currentCompetition"),
          }}
        />
      </ReferenceArrayInput>
      <TextInput fullWidth source="chief_referee" />
      <TextInput fullWidth source="tdelegate" />
      <ReferenceArrayInput
        source="divisions"
        reference="divisions"
        filter={{
          competition: localStorage.getItem("currentCompetition"),
        }}
      >
        <SelectArrayInput
          fullwidth
          optionText="name"
          create={<CreateButton collection="divisions" />}
        />
      </ReferenceArrayInput>
      <SelectInput
        source="movement"
        choices={[
          { id: "squat", name: "Squat" },
          { id: "bench", name: "Bench Press" },
          { id: "deadlift", name: "Deadlift" },
        ]}
      />
      <Box style={{ width: "100%" }} display={{ xs: "block", sm: "flex" }}>
        <Box
          flex={0.5}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <SelectInput
                source="sex"
                choices={[
                  { id: "f", name: "F" },
                  { id: "m", name: "M" },
                ]}
              />
            )}
          </FormDataConsumer>
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return formData.sex === "m" ? (
                <>
                  <SelectArrayInput
                    fullWidth
                    label="Men's Weight Classes (kg)"
                    source="categories"
                    choices={MensWc}
                  />
                </>
              ) : formData.sex === "f" ? (
                <>
                  <SelectArrayInput
                    fullWidth
                    label="Women's Weight Classes (kg)"
                    source="categories"
                    choices={WomensWc}
                  />
                </>
              ) : null;
            }}
          </FormDataConsumer>
        </Box>
      </Box>
      <SelectInput
        source="scored_by"
        choices={[
          { id: "best_lift", name: "Best Lift" },
          { id: "formula", name: "Formula" },
        ]}
      />
    </SimpleForm>
  );
};

// const countryRenderer = option => `🇦🇩 ${option.label} (${option.code})`;
const countryRenderer = (option) => ` ${option.label} (${option.code})`;

const ColorInput = (props) => {
  const input1 = useController({
    name: props.source,
    defaultValue: props.defaultValue,
  });

  return <input {...input1.field} type="color" />;
};

export default {
  list: AwardList,
  edit: AwardEdit,
  create: AwardCreate,
  show: AwardShow,
  icon: AwardIcon,
};
