import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  Edit,
  SimpleForm,
  ReferenceInput,
  TextInput,
  Create,
  SelectInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  DeleteButton,
  Toolbar,
  TopToolbar,
  useDataProvider,
  useRefresh,
  CreateButton,
  AutocompleteInput,
  FilterButton,
  NumberInput,
  DateInput,
  useListContext,
  Loading,
  useGetList,
  Pagination,
  SimpleList,
  useGetOne,
  SelectArrayInput,
  ReferenceArrayInput,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridEditSingleSelectCell,
  useGridApiContext,
  GridCellEditStopReasons,
} from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import { useController } from "react-hook-form";
import IconUpload from "@mui/icons-material/CloudUpload";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
// import { MensWc, WomensWc } from "./MensWc";
import { useCreate, useCreateSuggestionContext } from "react-admin";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField as MUITextField,
  Select as MUISelect,
  Autocomplete as MUIAutocomplete,
  Box,
} from "@mui/material";
// import readXlsxFile from 'read-excel-file'
import { Countries as countries } from "./Countries";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
// import Papa from 'papaparse';

const StyledBox = styled(Box)(({ theme }) => ({
  height: 300,
  width: "100%",
  "& .MuiDataGrid-cell--editing": {
    backgroundColor: "rgb(255,215,115, 0.19)",
    color: "#1a3e72",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));
//
function CustomTypeEditComponent(props) {
  const { id, value, field, colDef, row } = props;
  const apiRef = useGridApiContext();
  const { data: MensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "m",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const { data: WomensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "f",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const handleChange = async (event) => {
    console.log(id, field, event.target.value);
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
    });
    apiRef.current.stopCellEditMode({
      id,
      field,
    });
  };

  // if ((colDef.field === "weight_class", MensWc, WomensWc)) {
  //   return (
  //     <Select
  //       name={field}
  //       value={value}
  //       onChange={handleChange}
  //       size="small"
  //       sx={{ height: 1 }}
  //       native
  //       autoFocus
  //     >
  //       <option value={null}>Select</option>
  //       {row.sex === "m"
  //         ? MensWc.map((item) => <option value={item.id}>{item.name}</option>)
  //         : row.sex === "f"
  //           ? WomensWc.map((item) => (
  //               <option value={item.id}>{item.name}</option>
  //             ))
  //           : colDef.valueOptions.map((item) => (
  //               <option value={item.id}>{item.name}</option>
  //             ))}
  //     </Select>
  //   );
  // }

  return (
    <Select
      name={field}
      value={value}
      onChange={handleChange}
      size="small"
      sx={{ height: 1 }}
      native
      autoFocus
    >
      <option value={null}>Select</option>
      {colDef.valueOptions.map((item) => (
        <option value={item.id}>{item.name}</option>
      ))}
    </Select>
  );
}

const countryRenderer = (option) => `${option.label} (${option.code})`;

const fields = [
  {
    // Visible in table header and when matching columns.
    label: "Firstname",
    // This is the key used for this field when we call onSubmit.
    key: "firstname",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["first name", "First Name", "NOME", "nome"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Stephanie Doe",
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: "required",
        errorMessage: "Name is required",
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: "error",
      },
    ],
  },
  {
    // Visible in table header and when matching columns.
    label: "Surename",
    // This is the key used for this field when we call onSubmit.
    key: "surename",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["sure name", "last name", "NOME", "nome"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Stephanie Doe",
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: "required",
        errorMessage: "Name is required",
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: "error",
      },
    ],
  },
  // {
  //   // Visible in table header and when matching columns.
  //   label: "Team",
  //   // This is the key used for this field when we call onSubmit.
  //   key: "team",
  //   // Allows for better automatic column matching. Optional.
  //   alternateMatches: ["time", "clube", "CLUBE"],
  //   // Used when editing and validating information.
  //   fieldType: {
  //     // There are 3 types - "input" / "checkbox" / "select".
  //     type: "input",
  //   },
  //   // Used in the first step to provide an example of what data is expected in this field. Optional.
  //   example: "Eagles",
  //   // Can have multiple validations that are visible in Validation Step table.
  // },
  {
    // Visible in table header and when matching columns.
    label: "Sex",
    // This is the key used for this field when we call onSubmit.
    key: "sex",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["gender", "genero", "GÊNERO", "Gender"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "m",
    // Can have multiple validations that are visible in Validation Step table.
  },
  {
    // Visible in table header and when matching columns.
    label: "Date of birth",
    // This is the key used for this field when we call onSubmit.
    key: "date_of_birth",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["Date of birth", "DATA NASCIMENTO", "DoB"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "12/04/1989",
    // Can have multiple validations that are visible in Validation Step table.
  },
  {
    // Visible in table header and when matching columns.
    label: "Participation",
    // This is the key used for this field when we call onSubmit.
    key: "participation",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["Participation", "participation"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "12/04/1989",
    // Can have multiple validations that are visible in Validation Step table.
  },
  {
    // Visible in table header and when matching columns.
    label: "Weight class",
    // This is the key used for this field when we call onSubmit.
    key: "weight_class",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["category", "Event Name", "PROVA"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Women's Up to 41 kg",
    // Can have multiple validations that are visible in Validation Step table.
  },
  {
    // Visible in table header and when matching columns.
    label: "Age Division",
    // This is the key used for this field when we call onSubmit.
    key: "divisions",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["Open"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Open",
    // Can have multiple validations that are visible in Validation Step table.
  },
  {
    // Visible in table header and when matching columns.
    label: "Country",
    // This is the key used for this field when we call onSubmit.
    key: "country",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["pais", "PAIS"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "BRA",
    // Can have multiple validations that are visible in Validation Step table.
  },
];

const Archivo2 = (props) => {
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [isOpen, setOpen] = React.useState(false);
  const { data: MensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "m",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const { data: WomensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "f",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  if (!MensWc || !WomensWc) return <div>Loading...</div>;

  return (
    <>
      <ReactSpreadsheetImport
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onSubmit={async (data, file) => {
          const rows = data.all ? data.all : [];
          console.log(rows);
          console.log(file);

          const teams = data.all.map((row) => row.team);
          var uniqueTeams = teams.filter(
            (value, index, array) => array.indexOf(value) === index,
          );
          console.log(uniqueTeams);
          const createdTeams = [];
          for (var i = 0; i < uniqueTeams.length; i++) {
            const value = uniqueTeams[i];
            const { data } = await dataProvider.create("teams", {
              data: {
                name: value,
                competition: localStorage.getItem("currentCompetition"),
              },
            });
            console.log(data);
            createdTeams.push(data);
          }

          if (rows)
            for (let i = rows.length - 1; i >= 0; i--) {
              console.log(rows[i]);
              const row = rows[i];
              // var parts = row.date_of_birth.split('/');
              // console.log(parts)
              // const bdate = new Date(parts[0], parseInt(parts[1])-1, parts[2]);
              let weightClass = "";
              if (
                row.sex === "MASCULINO" ||
                row.sex === "M" ||
                row.sex === "m" ||
                row.sex.toLowerCase() === "male"
              )
                weightClass =
                  MensWc.find((item) => item.idString === row.weight_class) &&
                  MensWc.find((item) => item.idString === row.weight_class).id
                    ? MensWc.find((item) => item.idString === row.weight_class)
                        .id
                    : row.weight_class;

              if (
                row.sex === "FEMININO" ||
                row.sex === "F" ||
                row.sex === "f" ||
                row.sex.toLowerCase() === "female"
              )
                weightClass =
                  WomensWc.find((item) => item.idString === row.weight_class) &&
                  WomensWc.find((item) => item.idString === row.weight_class).id
                    ? WomensWc.find(
                        (item) => item.idString === row.weight_class,
                      ).id
                    : row.weight_class;

              // console.log(bdate);
              // console.log(row)
              // console.log(weightClass);
              // return;
              const lifter = {
                competition: localStorage.getItem("currentCompetition"),
                ...row,
                team: createdTeams.find((item) => item.name === row.team)
                  ? createdTeams.find((item) => item.name === row.team).id
                  : "",
                sex:
                  row.sex === "MASCULINO" ||
                  row.sex === "M" ||
                  row.sex === "m" ||
                  row.sex.toLowerCase() === "male"
                    ? "m"
                    : row.sex === "FEMININO" ||
                        row.sex === "F" ||
                        row.sex === "f" ||
                        row.sex.toLowerCase() === "female"
                      ? "f"
                      : row.sex,
                date_of_birth: row.date_of_birth,
                weight_class: weightClass,
                sdmsid: parseInt(row.sdmsid),
                divisions: ["Open"],
              };

              delete lifter.__index;

              if (!lifter.sdmsid) delete lifter.sdmsid;
              //Lo busco primero por sdmsid?
              console.log(lifter);
              const { data } = await dataProvider.create("lifters", {
                data: lifter,
              });
              console.log(data);
              refresh();
            }
        }}
        fields={fields}
      />
      <Button onClick={() => setOpen(true)}>
        <IconUpload /> Import{" "}
      </Button>
    </>
  );

  const handleNvEnter = async (event) => {
    console.log(event);
    return;
    const input = document.getElementById("input");
    // readXlsxFile(input.files[0])
    //     .then(async(rows) => {
    //         let contador = 0;
    //         const teams = rows.map(row => row[3]);
    //         var uniqueTeams = teams.filter((value, index, array) => array.indexOf(value) === index);
    //         console.log(uniqueTeams)
    //         const createdTeams = [];
    //         for (var i = 0; i < uniqueTeams.length; i++) {
    //           const value = uniqueTeams[i];
    //           console.log(value)
    //           const {data} = await dataProvider.create(
    //             'teams',
    //             { data: { name: value, competition: localStorage.getItem('currentCompetition') } }
    //           );
    //           console.log(data);
    //           createdTeams.push(data);
    //         }
    //         console.log(createdTeams)

    //         for (var j = 1; j < rows.length; j++) {
    //             var row = rows[j]
    //             var parts = row[6].split('/');
    //             console.log(parts)
    //             const bdate = new Date(parts[0], parseInt(parts[1])-1, parts[2]);
    //             let weightClass = '';
    //             if(row[7] === "MASCULINO")
    //               weightClass = MensWc.find(item => item.name === row[9]) && MensWc.find(item => item.name === row[9]).id ? MensWc.find(item => item.name === row[9]).id : row[9];

    //             if(row[7] === "FEMININO")
    //               weightClass = WomensWc.find(item => item.name === row[9]) && WomensWc.find(item => item.name === row[9]).id ? WomensWc.find(item => item.name === row[9]).id : row[9];

    //             console.log(bdate);
    //             console.log(row)

    //               //Lo busco primero por sdmsid?

    //               const {data} = await dataProvider.create(
    //               'lifters',
    //               { data: {
    //                   competition: localStorage.getItem('currentCompetition'),
    //                   fullname: row[1], //row[8] + ' ' + row[9],
    //                   first_name: row[1],
    //                   last_name: row[1],
    //                   sex: row[5] === "MASCULINO" ? 'm' : "FEMININO" ? 'f' : row[7],
    //                   country: "BRA",
    //                   // region: row[6],
    //                   sdmsid: row[2] ? row[2] : '',
    //                   date_of_birth: row[6],
    //                   weight_class: weightClass,
    //                   divisions: ["Elite"],
    //                   team: createdTeams.find(item => item.name === row[3]) ? createdTeams.find(item => item.name === row[3]).id : '',
    //                 }}
    //             );
    //             console.log(data);
    //             refresh();

    //         }
    // })
    // Papa.parse(event.target.files[0], {
    //   header: true,
    //   skipEmptyLines: true,
    //   complete: async function (results) {
    //     // console.log(results.data)
    //     for (var j = 0; j < results.data.length; j++) {
    //       var row = results.data[j];
    //       console.log(row)

    //       const {data} = await dataProvider.create(
    //         'lifters',
    //         { data: {
    //             competition: localStorage.getItem('currentCompetition'),
    //             fullname: row.name,
    //             first_name: row.name,
    //             last_name: row.name,
    //             sex: row.gender === "Male" ? 'm' : "Female" ? 'f' : row.gender,
    //             country: row.team,
    //             // date_of_birth: bdate.toISOString().split('T')[0],
    //             weight_class: row.declaredAwardsWeightClass,
    //             divisions: [row.division],
    //           }}
    //       );
    //       console.log(data);
    //       if(j === results.data.length - 1) {refresh()}
    //     }
    //   },
    // });
  };

  return (
    <label className="custom-file-upload">
      <input onChange={handleNvEnter} type="file" />
      {/*<Typography color="primary" style={{cursor:'pointer'}} align="center"><IconUpload /> Import (Liftingcast File)</Typography>*/}
    </label>
  );
};

const ListActions = (props) => (
  <TopToolbar>
    <FilterButton />
    <CreateButton variant="contained" />
    {/*<Archivo />*/}
    <Archivo2 />
    {/* <ExportButton maxResults={50000}/> */}
    {/* Add your custom actions */}
  </TopToolbar>
);

const Empty = () => (
  <Box style={{ flex: 1 }} textAlign="center" m={1}>
    <Typography variant="h4" paragraph>
      No lifters available
    </Typography>
    <Typography variant="body1">Create one or import from a file</Typography>
    <CreateButton />
    {/*<Archivo />*/}
    <Archivo2 />
  </Box>
);

const postFilters = [
  <TextInput source="lot_number" alwaysOn />,
  <SelectInput
    alwaysOn
    label="Gender"
    source="sex"
    choices={[
      { id: "f", name: "F" },
      { id: "m", name: "M" },
    ]}
  />,
  <TextInput source="surename" alwaysOn />,
  <TextInput source="participation" alwaysOn />,
  <ReferenceInput
    filter={{ competition: localStorage.getItem("currentCompetition") }}
    source="weight_class"
    reference="weight_class"
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    filter={{ competition: localStorage.getItem("currentCompetition") }}
    source="age_divisions"
    reference="divisions"
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    filter={{ competition: localStorage.getItem("currentCompetition") }}
    label="Session"
    source="platform"
    reference="platforms"
    alwaysOn
  >
    <SelectInput label="Session" optionText="name" />
  </ReferenceInput>,
  // <ReferenceInput perPage={1000000} filter={{competition: localStorage.getItem("currentCompetition")}} source="team" reference="teams" >
  //   <SelectInput label="Team/Club" optionText="name" />
  // </ReferenceInput>,
  // <SelectInput optionValue="code" source="country" choices={countries} optionText={countryRenderer} />
];

const LiftersTable = () => {
  const { data, isLoading, perPage } = useListContext();
  const navigate = useNavigate();
  const [filter, setFilter] = React.useState({});
  const dataProvider = useDataProvider();

  const { data: MensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "m",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const { data: WomensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "f",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const handleChange = (event) => {
    let filterT = {
      ...filter,
    };
    if (event.target.value) {
      filterT.wc = event.target.value;
    } else {
      delete filterT.wc;
    }
    setFilter(filterT);
  };

  const handleChange2 = (event) => {
    let filterT = {
      ...filter,
    };
    if (event.target.value) {
      filterT.platform = event.target.value;
    } else {
      delete filterT.platform;
    }
    setFilter(filterT);
  };

  React.useEffect(() => {
    if (!localStorage.getItem("currentCompetition")) {
      alert("You must click on the competition you want to enter");
      navigate("/");
    }
  }, []);

  const {
    data: platforms,
    total2,
    isLoading2,
    error2,
  } = useGetList("platforms", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "name",
      order: "ASC",
    },
  });

  function renderRating(params) {
    return (
      <Button onClick={() => navigate("/lifters/" + params.id)}>Edit</Button>
    );
  }

  function renderSession(params) {
    if (!isLoading2 && platforms && platforms.length) {
      const sesion = platforms.find((item) => item.id === params.value);
      if (sesion && sesion.name) return <p>{sesion.name}</p>;
    }

    if (isLoading2) return <>...</>;

    return null;
  }

  const columns = [
    {
      field: "lot_number",
      headerName: "Lot No.",
      width: 70,
      editable: true,
    },
    {
      field: "sdmsid",
      headerName: "SDMS",
      width: 120,
      editable: true,
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 220,
      editable: true,
    },
    {
      field: "surename",
      headerName: "Sure Name",
      width: 220,
      editable: true,
    },
    {
      field: "participation",
      headerName: "Participation",
      width: 120,
      editable: false,
    },
    {
      field: "weight_class",
      headerName: "Weight class",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: MensWc && WomensWc ? [...MensWc, ...WomensWc] : [],
      // valueOptions: ({ row }) => { return row.sex === 'm' ? MensWc : row.sex === 'f' ? WomensWc : []},
      renderEditCell: (params) => <CustomTypeEditComponent {...params} />,
      renderCell: (params) =>
        [...MensWc, ...WomensWc].find((item) => item.id === params.value)
          ? [...MensWc, ...WomensWc].find((item) => item.id === params.value)
              .name
          : null,
    },
    {
      field: "bodyweight",
      headerName: "Bodyweight",
      width: 120,
      editable: true,
      //       renderCell: (params) => {
      //         let invalido = false;
      //         const sex = params.row.sex;
      //         const bodyweight = params.value;
      //         const weight_class = params.row.weight_class;
      //         if (bodyweight && weight_class && sex === "m") {
      //           const prev = MensWc.findIndex((item) => item.id === weight_class);
      //           const prevWC =
      //             parseFloat(prev) > 0 ? MensWc[parseInt(prev) - 1]["id"] : -1;
      //           if (bodyweight && parseFloat(bodyweight) > weight_class) {
      //             invalido = true;
      //           } else if (
      //             prevWC > -1 &&
      //             bodyweight &&
      //             parseFloat(bodyweight) <= prevWC
      //           ) {
      //             invalido = true;
      //           } else {
      //             invalido = false;
      //           }
      //
      //           if (
      //             params.row.weight_class === "+107" &&
      //             parseFloat(bodyweight) > 107
      //           )
      //             invalido = false;
      //         }
      //
      //         if (bodyweight && weight_class && sex === "f") {
      //           const prev = WomensWc.findIndex((item) => item.id === weight_class);
      //           const prevWC =
      //             parseFloat(prev) > 0 ? WomensWc[parseInt(prev) - 1]["id"] : -1;
      //           if (bodyweight && parseFloat(bodyweight) > weight_class) {
      //             invalido = true;
      //           } else if (
      //             prevWC > -1 &&
      //             bodyweight &&
      //             parseFloat(bodyweight) <= prevWC
      //           ) {
      //             invalido = true;
      //           } else {
      //             invalido = false;
      //           }
      //
      //           if (params.row.weight_class === "+86" && parseFloat(bodyweight) > 86)
      //             invalido = false;
      //         }
      //
      //         if (!invalido) return params.value;
      //         else return `${params.value} ❌`;
      //       },
    },
    {
      field: "squat_rack_height",
      headerName: "S. Rack Height",
      type: "number",
      width: 120,
      editable: true,
    },
    {
      field: "squat_first_attempt",
      headerName: "S. 1st Att",
      type: "number",
      width: 100,
      editable: true,
    },
    {
      field: "bench_rack_height",
      headerName: "B. Rack Height",
      type: "number",
      width: 120,
      editable: true,
    },
    {
      field: "bench_first_attempt",
      headerName: "B. 1st Att",
      type: "number",
      width: 100,
      editable: true,
    },
    {
      field: "deadlift_first_attempt",
      headerName: "D. 1st Att",
      type: "number",
      width: 100,
      editable: true,
    },
    // { field: 'rack_height', headerName: 'Rack Height', type: 'number', width: 80, editable: true },
    {
      field: "platform",
      headerName: "Session",
      type: "singleSelect",
      width: 150,
      editable: true,
      valueOptions:
        !isLoading2 && !error2 && platforms && platforms.length
          ? platforms.map((item) => ({
              id: item.id,
              name: item.name,
            }))
          : [],
      renderCell: renderSession,
      renderEditCell: (params) => <CustomTypeEditComponent {...params} />,
    },

    // { field: 'division', headerName: 'Divission', type: 'singleSelect', width: 150, editable: true, valueOptions: divisiones,
    // renderCell: (params) =>divisiones.find(item => item.id===params.value) ? divisiones.find(item => item.id===params.value).name : null,
    // renderEditCell: (params) => <CustomTypeEditComponent {...params} />},
    // { field: 'platform', headerName: 'Session', width: 100, editable: true },
    {
      field: "date_of_birth",
      headerName: "DoB",
      width: 120,
      editable: true,
    },
    {
      field: "sex",
      headerName: "Gender",
      width: 120,
      editable: true,
    },
    {
      field: "country",
      headerName: "Country",
      width: 120,
      editable: true,
    },
    // { field: 'division', headerName: 'Divission', width: 120, editable: false },
    {
      field: "objectId",
      headerName: "Edit",
      width: 100,
      editable: false,
      renderCell: renderRating,
    },
  ];

  const processRowUpdate = React.useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        // const mutation = computeMutation(newRow, oldRow);
        console.log(newRow, oldRow);
        if (newRow) {
          dataProvider
            .update("lifters", {
              id: newRow.id,
              data: newRow,
            })
            .then((item) => {
              console.log(newRow);
              resolve(newRow);
            });
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    [],
  );

  if (!MensWc || !WomensWc) return <Loading />;
  if (isLoading || isLoading2) return <Loading />;

  return (
    <StyledBox
      style={{
        margin: "1em",
        height: "calc(90vh - 100px)",
        width: "calc(95vw - 170px)",
      }}
    >
      <div style={{ height: "100%", width: "100%", margin: "auto" }}>
        <DataGrid
          // initialState={{
          //   pagination: {
          //     paginationModel: {
          //       pageSize: 10,
          //     },
          //   },
          // }}
          // pageSizeOptions={[5, 10, 25, 50, 100]}
          // autoHeight={true}
          rows={data}
          columns={columns}
          processRowUpdate={processRowUpdate}
          // onCellEditStop={(params, event) => {
          //   if (params.reason === GridCellEditStopReasons.cellFocusOut) {
          //     event.defaultMuiPrevented = true;
          //   }
          // }}
        />
      </div>
    </StyledBox>
  );
};

export const LifterList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!localStorage.getItem("currentCompetition")) {
      alert("You must click on the competition you want to enter");
      navigate("/");
    }
  }, []);

  const filterVar = {
    competition: localStorage.getItem("currentCompetition")
      ? localStorage.getItem("currentCompetition")
      : null,
  };

  return (
    <List
      perPage={1000}
      pagination={false}
      filter={filterVar}
      actions={<ListActions />}
      empty={<Empty />}
      filters={postFilters}
    >
      {" "}
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.fullname}
          tertiaryText={(record) => record.bodyweight}
        />
      ) : (
        <LiftersTable />
      )}
    </List>
  );
};

export const LifterEdit = () => (
  <Edit actions={<CreateActions />} redirect="list">
    <DetailsForm />
  </Edit>
);

const CreateActions = () => (
  <TopToolbar>
    {/* Add your custom actions */}
    <Button color="primary" onClick={() => window.history.back()}>
      Cancel
    </Button>
  </TopToolbar>
);

export const LifterCreate = () => (
  <Create actions={<CreateActions />} redirect="list">
    <DetailsForm />
  </Create>
);

const DetailsForm = () => {
  const [invalidWc, setInvalidWc] = React.useState(false);

  const { data: MensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "m",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const { data: WomensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "f",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const {
    data: competition,
    isLoading,
    error,
  } = useGetOne("competitions", {
    id: localStorage.getItem("currentCompetition"),
  });
  console.log(competition);
  const divisiones =
    competition && competition.divisions ? [...competition.divisions] : [];
  if (isLoading || !competition) {
    return <Loading />;
  }
  if (error) {
    return <p>{JSON.stringify(error)}</p>;
  }
  return (
    <SimpleForm>
      <TextInput
        label="Event"
        disabled
        defaultValue={localStorage.getItem("currentCompetition")}
        source="competition"
      />
      {/*<TextInput fullWidth source="fullname" />*/}
      <TextInput fullWidth source="firstname" />
      <TextInput fullWidth source="surename" />
      <Box style={{ width: "100%" }} display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} sx={{ display: "flex", flexDirection: "column" }}>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <TextInput
                onChange={(e) => {
                  /*
                  const sex = formData.sex;
                  const bodyweight = e.target.value;
                  if (
                    MensWc &&
                    bodyweight &&
                    formData.weight_class &&
                    sex === "m"
                  ) {
                    const prev = MensWc.findIndex(
                      (item) => item.id === formData.weight_class,
                    );
                    const prevWC =
                      parseInt(prev) > 0
                        ? MensWc[parseInt(prev) - 1]["id"]
                        : -1;
                    if (
                      bodyweight &&
                      parseInt(bodyweight) > formData.weight_class
                    ) {
                      setInvalidWc(true);
                    } else if (
                      prevWC > -1 &&
                      bodyweight &&
                      parseInt(bodyweight) <= prevWC
                    ) {
                      setInvalidWc(true);
                    } else {
                      setInvalidWc(false);
                    }
                  }

                  if (
                    WomensWc &&
                    bodyweight &&
                    formData.weight_class &&
                    sex === "f"
                  ) {
                    const prev = WomensWc.findIndex(
                      (item) => item.id === formData.weight_class,
                    );
                    const prevWC =
                      parseInt(prev) > 0
                        ? MensWc[parseInt(prev) - 1]["id"]
                        : -1;
                    if (
                      bodyweight &&
                      parseInt(bodyweight) > formData.weight_class
                    ) {
                      setInvalidWc(true);
                    } else if (
                      prevWC > -1 &&
                      bodyweight &&
                      parseInt(bodyweight) <= prevWC
                    ) {
                      setInvalidWc(true);
                    } else {
                      setInvalidWc(false);
                    }
                  }
                */
                }}
                source="bodyweight"
              />
            )}
          </FormDataConsumer>
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <DateInput source="date_of_birth" />
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextInput source="lot_number" />
        </Box>
      </Box>
      <h3>First attempts</h3>
      <Box style={{ width: "100%" }} display={{ xs: "block", sm: "flex" }}>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <NumberInput source="squat_first_attempt" />
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <NumberInput source="squat_rack_height" />
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <NumberInput source="bench_first_attempt" />
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <NumberInput source="bench_rack_height" />
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <NumberInput source="deadlift_first_attempt" />
        </Box>
      </Box>
      <Box style={{ width: "100%" }} display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} sx={{ display: "flex", flexDirection: "column" }}>
          <ReferenceArrayInput
            source="age_divisions"
            reference="divisions"
            filter={{
              competition: localStorage.getItem("currentCompetition"),
            }}
          >
            <SelectArrayInput fullwidth optionText="name" />
          </ReferenceArrayInput>
          <ReferenceInput
            source="participation"
            reference="participation"
            filter={{
              competition: localStorage.getItem("currentCompetition"),
            }}
          >
            <SelectInput
              optionText="name"
              optionValue="name"
              label="Participations"
            />
          </ReferenceInput>
        </Box>
        <Box
          flex={0.5}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <SelectInput
                label="Gender"
                source="sex"
                onChange={(e) => {
                  const sex = e.target.value;
                  if (sex === "m") {
                    const prev = MensWc.findIndex(
                      (item) => item.id === formData.weight_class,
                    );
                    const prevWC =
                      parseInt(prev) > 0
                        ? MensWc[parseInt(prev) - 1]["id"]
                        : -1;
                    if (
                      formData.bodyweight &&
                      parseInt(formData.bodyweight) > formData.weight_class
                    ) {
                      setInvalidWc(true);
                    } else if (
                      prevWC > -1 &&
                      formData.bodyweight &&
                      parseInt(formData.bodyweight) <= prevWC
                    ) {
                      setInvalidWc(true);
                    } else {
                      setInvalidWc(false);
                    }
                  }

                  if (sex === "f") {
                    const prev = WomensWc.findIndex(
                      (item) => item.id === formData.weight_class,
                    );
                    const prevWC =
                      parseInt(prev) > 0
                        ? MensWc[parseInt(prev) - 1]["id"]
                        : -1;
                    if (
                      formData.bodyweight &&
                      parseInt(formData.bodyweight) > formData.weight_class
                    ) {
                      setInvalidWc(true);
                    } else if (
                      prevWC > -1 &&
                      formData.bodyweight &&
                      parseInt(formData.bodyweight) <= prevWC
                    ) {
                      setInvalidWc(true);
                    } else {
                      setInvalidWc(false);
                    }
                  }
                }}
                choices={[
                  { id: "f", name: "F" },
                  { id: "m", name: "M" },
                ]}
              />
            )}
          </FormDataConsumer>
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return formData.sex === "m" ? (
                <>
                  <SelectInput
                    fullWidth
                    onChange={(e) => {
                      const prev = MensWc.findIndex(
                        (item) => item.id === e.target.value,
                      );
                      const prevWC =
                        parseInt(prev) > 0
                          ? MensWc[parseInt(prev) - 1]["id"]
                          : -1;
                      if (
                        formData.bodyweight &&
                        parseInt(formData.bodyweight) > e.target.value
                      ) {
                        setInvalidWc(true);
                      } else if (
                        prevWC > -1 &&
                        formData.bodyweight &&
                        parseInt(formData.bodyweight) <= prevWC
                      ) {
                        setInvalidWc(true);
                      } else {
                        setInvalidWc(false);
                      }
                    }}
                    label="Men's Weight Classes"
                    source="weight_class"
                    choices={MensWc}
                  />
                  {invalidWc ? <CloseIcon sx={{ color: "red" }} /> : null}
                </>
              ) : formData.sex === "f" ? (
                <>
                  <SelectInput
                    fullWidth
                    onChange={(e) => {
                      const prev = WomensWc.findIndex(
                        (item) => item.id === e.target.value,
                      );
                      const prevWC =
                        parseInt(prev) > 0
                          ? WomensWc[parseInt(prev) - 1]["id"]
                          : -1;
                      if (
                        formData.bodyweight &&
                        parseInt(formData.bodyweight) > e.target.value
                      ) {
                        setInvalidWc(true);
                      } else if (
                        prevWC > -1 &&
                        formData.bodyweight &&
                        parseInt(formData.bodyweight) <= prevWC
                      ) {
                        setInvalidWc(true);
                      } else {
                        setInvalidWc(false);
                      }
                    }}
                    label="Women's Weight Classes"
                    source="weight_class"
                    choices={WomensWc}
                  />
                  {invalidWc ? <CloseIcon sx={{ color: "red" }} /> : null}
                </>
              ) : null;
            }}
          </FormDataConsumer>
        </Box>
      </Box>
      <Box style={{ width: "100%" }} display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} sx={{ display: "flex", flexDirection: "column" }}>
          <AutocompleteInput
            optionText={countryRenderer}
            optionValue="code"
            source="country"
            choices={countries}
          />
          <TextField source="country" />
          {/*{JSON.stringify(countries)}*/}
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <ReferenceInput
            perPage={1000000}
            filter={{ competition: localStorage.getItem("currentCompetition") }}
            source="team"
            reference="teams"
          >
            <SelectInput
              label="Team/Club"
              create={<CreateTeam dbtable="teams" />}
              optionText="name"
            />
          </ReferenceInput>
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: "0.5em" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <ReferenceInput
            source="platform"
            reference="platforms"
            filter={{
              competition: localStorage.getItem("currentCompetition"),
            }}
          >
            <SelectInput
              label="Session"
              create={<CreatePlatform />}
              optionText="name"
            />
          </ReferenceInput>
        </Box>
      </Box>
    </SimpleForm>
  );
};

const CreateTeam = (props) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate();
  const [value, setValue] = React.useState(filter || "");

  const handleSubmit = (event) => {
    event.preventDefault();
    create(
      props.dbtable,
      {
        data: {
          name: value,
          competition: localStorage.getItem("currentCompetition") || "",
        },
      },
      {
        onSuccess: (data) => {
          setValue("");
          onCreate(data);
        },
      },
    );
  };

  return (
    <Dialog open onClose={onCancel} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <MUITextField
            fullWidth
            label="Team name"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CountryInput = () => {
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
  } = useController({
    name: "country",
    defaultValue: "",
  });
  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width: 300 }}
      size="small"
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.code}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <MUITextField
          {...params}
          {...field}
          label="Country"
          error={(isTouched || isSubmitted) && invalid}
          helperText={(isTouched || isSubmitted) && invalid ? error : ""}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

const CreatePlatform = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate();
  const [value, setValue] = React.useState(filter || "");

  const handleSubmit = (event) => {
    event.preventDefault();
    create(
      "platforms",
      {
        data: {
          name: value,
          competition: localStorage.getItem("currentCompetition") || "",
        },
      },
      {
        onSuccess: (data) => {
          setValue("");
          onCreate(data);
        },
      },
    );
  };

  return (
    <Dialog open onClose={onCancel} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <MUITextField
            fullWidth
            label="Platform"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
