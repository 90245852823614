import * as React from "react";
import "./App.css";
import {
  Admin,
  Resource,
  ListGuesser,
  defaultTheme,
  Layout,
  Menu,
  CustomRoutes,
  useTranslate,
} from "react-admin";
import { Route } from "react-router-dom";
import ParseAuth from "./parseAuth";
import ParseClient from "./parseClient";
import addUploadFeature from "./addUploadFeature";
import red from "@mui/material/colors/red";
import EventIcon from "@mui/icons-material/Event";
import GroupsIcon from "@mui/icons-material/Groups";
import { Dashboard } from "./Dashboard";
import { Run } from "./Run";
import { Board } from "./Board";
import { Display } from "./Display";
import { Referee } from "./Referee";
import PrintIcon from "@mui/icons-material/Print";
// import Logo from './assets/domi.png';
import { PostList, PostCreate, PostEdit, WCEdit, WCCreate } from "./posts";
import {
  CompetitionCreate,
  CompetitionEdit,
  CompetitionList,
} from "./Competitions";
import { LifterCreate, LifterEdit, LifterList } from "./Lifters";
import MyLoginPage from "./MyLoginPage";
import { PaperWork } from "./PaperWork";
import AwardDivisions, { AwardList2 } from "./AwardDivisions";
import localForageDataProvider from "ra-data-local-forage";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "./i18n/en";
//scp -r build/* root@45.55.41.53:/var/parse-server-example/public/wp/
//touch .gitignore && echo "node_modules/" >> .gitignore && git rm -r --cached node_modules ; git status
const i18nProvider = polyglotI18nProvider(
  (locale) => {
    return englishMessages;
  },
  "en",
  [
    {
      locale: "en",
      name: "English",
    },
    // { locale: 'fr', name: 'Français' },
    // { locale: 'pt', name: 'Portuguese' },
  ],
);

const parseConfig = {
  URL: "https://wp.freiesland.com/parse",
  JAVASCRIPT_KEY: null,
  APP_ID: "ParaPowerCr0b51",
  MASTER_KEY: "ParaPowerm4st3rk3y",
};
const authProvider = ParseAuth(parseConfig);
const dataProviderB = ParseClient(parseConfig);
const uploadCapableDataProvider = addUploadFeature(dataProviderB);

const theme = {
  ...defaultTheme,
  palette: {
    primary: {
      light: "#000000",
      main: "#D6403c",
      dark: "#D6403c",
      contrastText: "#fff",
    },
    secondary: {
      main: "#000000",
    },
    error: red,
    white: {
      main: "#FFFFFF",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  sidebar: {
    width: 150, // The default value is 240
    closedWidth: 50, // The default value is 55
  },
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined",
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          backgroundColor: "White",
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#9f0808",
            color: "#FFF",
          },
        },
      },
    },
  },
};

const MyMenu = () => {
  if (localStorage.getItem("currentCompetition")) {
    return (
      <Menu>
        <p
          style={{
            padding: 20,
            fontWeight: "bold",
          }}
        >
          Currently on:
          {localStorage.getItem("currentCompetition")}
        </p>
        <Menu.DashboardItem />
        <Menu.ResourceItem name="weight_class" />
        <Menu.ResourceItem name="lifters" />
        <Menu.ResourceItem name="teams" />
        <Menu.ResourceItem name="platforms" />
        <Menu.ResourceItem name="paper_work" />
        <Menu.ResourceItem name="award_divisions" />
        <Menu.ResourceItem name="results" />
      </Menu>
    );
  }

  return (
    <Menu>
      <small
        style={{
          padding: 20,
          fontWeight: "bold",
        }}
      >
        Hola, {/*user.nombre_completo*/}
      </small>
      <Menu.DashboardItem />
      {/*<Menu.ResourceItem name="competitions" />*/}
      {/*<Menu.ResourceItem name="lifters" />
                                                                                                            <Menu.ResourceItem name="teams" />
                                                                                                            <Menu.ResourceItem name="weight_in" />
                                                                                                            <Menu.ResourceItem name="platforms" />
                                                                                                            <Menu.ResourceItem name="paper_work" />
                                                                                                            <Menu.ResourceItem name="award_divisions" />*/}
    </Menu>
  );
};

const MyLayout = (props) => <Layout {...props} menu={MyMenu} />;

const App = () => {
  const [dataProvider, setDataProvider] = React.useState(null);
  const translate = useTranslate();
  React.useEffect(() => {
    async function startDataProvider() {
      const localForageProvider = await localForageDataProvider();
      setDataProvider(localForageProvider);
    }

    if (dataProvider === null) {
      startDataProvider();
    }
  }, [dataProvider]);

  // hide the admin until the data provider is ready
  if (!dataProvider) {
    return <p>Loading...</p>;
  }

  return (
    <Admin
      i18nProvider={i18nProvider}
      dataProvider={
        localStorage.getItem("offline")
          ? dataProvider
          : uploadCapableDataProvider
      }
      authProvider={authProvider}
      loginPage={MyLoginPage}
      theme={theme}
      dashboard={Dashboard}
      layout={MyLayout}
    >
      <Resource
        options={{
          label: translate("pos.menu.sessions"),
        }}
        name="platforms"
        list={PostList}
        edit={PostEdit}
        create={PostCreate}
      />
      <Resource
        name="weight_class"
        list={PostList}
        edit={WCEdit}
        create={WCCreate}
      />
      <Resource
        options={{
          label: translate("pos.menu.competitions"),
        }}
        name="competitions"
        list={CompetitionList}
        create={CompetitionCreate}
        edit={CompetitionEdit}
        icon={EventIcon}
      />
      <Resource
        options={{
          label: translate("pos.menu.athletes"),
        }}
        name="lifters"
        list={LifterList}
        create={LifterCreate}
        edit={LifterEdit}
        icon={GroupsIcon}
      />
      <Resource
        options={{
          label: translate("pos.menu.teams"),
        }}
        name="teams"
        list={PostList}
        edit={PostEdit}
        create={PostCreate}
      />
      <Resource
        options={{
          label: translate("pos.menu.paperwork"),
        }}
        name="paper_work"
        list={PaperWork}
        icon={PrintIcon}
      />
      <Resource
        options={{
          label: translate("pos.menu.award_divisions"),
        }}
        name="award_divisions"
        {...AwardDivisions}
      />
      <Resource
        options={{
          label: translate("pos.menu.results"),
        }}
        name="results"
        list={AwardList2}
      />
      <CustomRoutes noLayout>
        <Route path="/run/:platform" element={<Run />} noLayout />
        <Route path="/board/:platform" element={<Board />} noLayout />
        <Route path="/board/:platform/:movement" element={<Board />} noLayout />
        <Route path="/display/:platform" element={<Display />} noLayout />
        <Route
          path="/rreferee/:platform"
          element={<Referee position="r" />}
          noLayout
        />
        <Route
          path="/lreferee/:platform"
          element={<Referee position="l" />}
          noLayout
        />
        <Route
          path="/creferee/:platform"
          element={<Referee position="c" />}
          noLayout
        />
      </CustomRoutes>
    </Admin>
  );
};

export default App;
