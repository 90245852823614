import React from "react";
import { useRef } from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  useRecordContext,
  useGetList,
  Button,
  useDataProvider,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PrintIcon from "@mui/icons-material/Print";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const postFilters = [<TextInput label="name" source="name" alwaysOn />];

export const PaperWork = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [record, setRecord] = React.useState(false);
  const [competition, setCompetition] = React.useState(false);
  const [lifters, setLifters] = React.useState([]);
  const [dlgTitle, setDlgTitle] = React.useState(false);
  const exportRef = useRef();
  const dataProvider = useDataProvider();

  const { data: divisions } = useGetList("divisions", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const { data: MensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "m",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const { data: WomensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "f",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const { data: teams } = useGetList("teams", {
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "name",
      order: "ASC",
    },
  });

  const handleClickOpen = async (title, record) => {
    setDlgTitle(title);
    setOpen(true);
    setRecord(record);
    /*Traigo a los atletas de la sesion*/
    const { data: lifters } = await dataProvider.getList("lifters", {
      //       filter: {
      //         competition: localStorage.getItem( "currentCompetition" ) ? localStorage.getItem( "currentCompetition" ) : null,
      //         // weight_class: record.idString,
      //
      //       },
      filter: {
        competition: localStorage.getItem("currentCompetition")
          ? localStorage.getItem("currentCompetition")
          : null,
        platform: record.id,
      },
      pagination: {
        page: 1,
        perPage: 10000,
      },
      sort: {
        field: "weight_class",
        order: "DESC",
      },
    });

    console.log([
      ...lifters.reverse().filter((i) => i.sex === "f"),
      ...lifters.reverse().filter((i) => i.sex !== "f"),
    ]);
    setLifters([
      ...lifters
        .sort((a, b) => parseInt(b.weight_class) - parseInt(a.weight_class))
        .filter((i) => i.sex === "f"),
      ...lifters.reverse().filter((i) => i.sex !== "f"),
    ]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function getCompetition() {
    const { data: recordCompetition } = await dataProvider.getOne(
      "competitions",
      {
        id: localStorage.getItem("currentCompetition"),
      },
    );
    setCompetition(recordCompetition);
  }

  React.useEffect(() => {
    if (!localStorage.getItem("currentCompetition")) {
      alert("You must click on the competition you want to enter");
      navigate("/");
    } else {
      getCompetition();
    }
  }, []);

  const imprimir = () => {
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(`<style type="text/css" media="print" />
        body {font-size: 8pt; font-family:'Arial','Times New Roman',Times,serif;}
        table { page-break-inside: avoid;page-break-after:auto; font-size: 8pt !important;  }
        tr    { page-break-inside:avoid; page-break-after:auto; font-size: 8pt !important;  }
        td    { page-break-inside:avoid; page-break-after:auto; font-size: 8pt !important; font-family:'Arial','Times New Roman',Times,serif; }
        thead { display:table-header-group }
        tfoot { display:table-footer-group }</style>`);
    pri.document.write(document.getElementById("toprint").innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  const ACButton = () => {
    const record = useRecordContext();
    return (
      <Button
        onClick={() => handleClickOpen("Attempt Cards", record)}
        label="Attempt Cards"
      />
    );
  };
  const STButton = () => {
    const record = useRecordContext();
    return (
      <Button
        onClick={() => handleClickOpen("Start List", record)}
        label="Start List"
      />
    );
  };

  const KCHButton = () => {
    const record = useRecordContext();
    return (
      <Button
        onClick={() => handleClickOpen("Kit Check", record)}
        label="Kit Check"
      />
    );
  };

  const WCButton = () => {
    const record = useRecordContext();
    return (
      <Button
        onClick={() => handleClickOpen("Weigh in", record)}
        label="Weigh in"
      />
    );
  };

  if (
    !competition ||
    !lifters ||
    !MensWc ||
    !WomensWc ||
    !teams ||
    !divisions
  ) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <List
        perPage={1000}
        filter={{
          competition: localStorage.getItem("currentCompetition")
            ? localStorage.getItem("currentCompetition")
            : null,
        }}
        filters={postFilters}
        resource={"platforms"}
      >
        <Datagrid rowClick="false">
          <TextField source="name" />
          <WCButton />
          <ACButton />
          <STButton />
          <KCHButton />
        </Datagrid>
      </List>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar sx={{ display: "flex" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
              Close
            </IconButton>
            <h3 style={{ flex: 1, textAlign: "center" }}>{dlgTitle}</h3>
            <IconButton
              autoFocus
              color="inherit"
              onClick={() => {
                imprimir();
              }}
            >
              <PrintIcon />
              Print
            </IconButton>
          </Toolbar>
        </AppBar>
        <iframe
          title="pdf"
          id="ifmcontentstoprint"
          className="pdfIframe"
        ></iframe>
        {competition && lifters.length && dlgTitle === "Attempt Cards" ? (
          <Box id="toprint" ref={exportRef}>
            {lifters.map((lifter) => (
              <Table
                className="myFormat"
                size="small"
                style={{
                  width: "666px",
                  border: "solid",
                  borderWidth: 1,
                  borderCollapse: "collapse",
                  marginBottom: 2,
                }}
              >
                <thead>
                  <TableRow>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      colspan={2}
                    ></TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                      colspan={12}
                    >
                      Attempt Card
                      <br />
                      {competition ? competition.meet_name : null}
                    </TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 0 }}
                      colspan={2}
                    >
                      {competition &&
                      competition.pictures &&
                      competition.pictures[0] ? (
                        <img
                          alt={competition.pictures[0].name}
                          src={competition.pictures[0].src}
                          height="80"
                        />
                      ) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      colspan={2}
                    >
                      Lot. No.
                    </TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      colspan={1}
                    >
                      {lifter.lot_number}
                    </TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      colspan={2}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 8,
                        whiteSpace: "nowrap",
                      }}
                      colspan={4}
                    >
                      {lifter.surename} {lifter.firstname}
                    </TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    >
                      NPC
                    </TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      colspan={2}
                    >
                      {lifter.country}{" "}
                      {teams &&
                      teams.length &&
                      teams.find((item) => item.id === lifter.team)
                        ? teams.find((item) => item.id === lifter.team).name
                        : null}
                    </TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      colspan={1}
                    >
                      DoB
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        whiteSpace: "nowrap",
                      }}
                      colspan={3}
                    >
                      {lifter.date_of_birth}
                    </TableCell>
                  </TableRow>
                </thead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      colspan={2}
                    >
                      Cat/Grp.
                    </TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      colspan={1}
                    >
                      {[...WomensWc, ...MensWc].find(
                        (item) => item.id === lifter.weight_class,
                      )
                        ? [...WomensWc, ...MensWc].find(
                            (item) => item.id === lifter.weight_class,
                          ).name
                        : lifter.weight_class}
                    </TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      colspan={2}
                    >
                      Bodyweight
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        minWidth: 20,
                      }}
                      colspan={2}
                    >
                      {lifter.bodyweight}
                    </TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      colspan={2}
                    >
                      Rack Height
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        minWidth: 50,
                      }}
                      colspan={2}
                    >
                      {lifter.rack_height}
                    </TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      colspan={2}
                    >
                      Age Group
                    </TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                      colspan={3}
                    >
                      {divisions &&
                      divisions.length &&
                      divisions.find((item) => item.id === lifter.age_divisions)
                        ? divisions.find(
                            (item) => item.id === lifter.age_divisions,
                          ).name
                        : null}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: "#DDDDDD" }}>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        textAlign: "center",
                      }}
                      colspan={4}
                    >
                      1st Attempt
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        textAlign: "center",
                      }}
                      colspan={4}
                    >
                      2st Attempt
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        textAlign: "center",
                      }}
                      colspan={4}
                    >
                      3st Attempt
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        textAlign: "center",
                      }}
                      colspan={4}
                    >
                      Power Lift
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                      }}
                      colspan={1}
                    >
                      Squat
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                      }}
                      colspan={3}
                    >
                      <small>
                        {lifter.squat_first_attempt
                          ? lifter.squat_first_attempt
                          : ""}
                      </small>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        textAlign: "center",
                      }}
                      colspan={4}
                    >
                      <div
                        style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                      >
                        Automatic / Declared
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        textAlign: "center",
                      }}
                      colspan={4}
                    >
                      <div
                        style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                      >
                        Automatic / Declared
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        textAlign: "center",
                      }}
                      colspan={4}
                    >
                      <div
                        style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                      ></div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                      }}
                      colspan={1}
                    >
                      Bench Press
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                      }}
                      colspan={3}
                    >
                      <small>
                        {lifter.bench_first_attempt
                          ? lifter.bench_first_attempt
                          : ""}
                      </small>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        textAlign: "center",
                      }}
                      colspan={4}
                    >
                      <div
                        style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                      >
                        Automatic / Declared
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        textAlign: "center",
                      }}
                      colspan={4}
                    >
                      <div
                        style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                      >
                        Automatic / Declared
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        textAlign: "center",
                      }}
                      colspan={4}
                    >
                      <div
                        style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                      ></div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                      }}
                      colspan={1}
                    >
                      Deadlift
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                      }}
                      colspan={3}
                    >
                      <small>
                        {lifter.deadlift_first_attempt
                          ? lifter.deadlift_first_attempt
                          : ""}
                      </small>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        textAlign: "center",
                      }}
                      colspan={4}
                    >
                      <div
                        style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                      >
                        Automatic / Declared
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        textAlign: "center",
                      }}
                      colspan={4}
                    >
                      <div
                        style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                      >
                        Automatic / Declared
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        textAlign: "center",
                      }}
                      colspan={4}
                    >
                      <div
                        style={{ fontSize: 8, height: 20, marginTop: "-5px" }}
                      ></div>
                    </TableCell>
                  </TableRow>
                  {/* <TableRow> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={4} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       Signature */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={4} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       Signature */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={4} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       Signature */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={4} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       Signature */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/* </TableRow> */}
                  {/* <TableRow> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={4} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       1st Change */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={4} */}
                  {/*     rowspan={4} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     ></div> */}
                  {/*   </TableCell> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={4} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       1st Change */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={4} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       Result */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/* </TableRow> */}
                  {/* <TableRow> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={4} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       Signature */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={4} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       Signature */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={2} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       Best */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={2} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       Total */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/* </TableRow> */}
                  {/* <TableRow> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={4} */}
                  {/*     rowspan={2} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     ></div> */}
                  {/*   </TableCell> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={4} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       2nd Change */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={4} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       Ranking */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/* </TableRow> */}
                  {/* <TableRow> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={4} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       Signature */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={2} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       Best */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/*   <TableCell */}
                  {/*     style={{ */}
                  {/*       border: "solid", */}
                  {/*       borderWidth: 1, */}
                  {/*       padding: 4, */}
                  {/*       textAlign: "center", */}
                  {/*     }} */}
                  {/*     colspan={2} */}
                  {/*   > */}
                  {/*     <div */}
                  {/*       style={{ fontSize: 8, height: 20, marginTop: "-5px" }} */}
                  {/*     > */}
                  {/*       Total */}
                  {/*     </div> */}
                  {/*   </TableCell> */}
                  {/* </TableRow> */}
                </TableBody>
              </Table>
            ))}
          </Box>
        ) : null}

        {lifters.length && dlgTitle === "Start List" ? (
          <Box id="toprint" ref={exportRef}>
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "10px",
                    maxWidth: "900px",
                  }}
                >
                  {competition.pictures.map((item) => (
                    <img
                      alt={item.name}
                      align="center"
                      width="100"
                      src={item.src}
                    />
                  ))}
                </div>
              </div>
              <h1 style={{ fontSize: 14 }}>Start List</h1>
            </div>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 32,
                marginTop: 32,
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                      width: "15%",
                    }}
                  >
                    Competition
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition ? competition.meet_name : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition && competition.start_date
                      ? competition.start_date
                      : null}{" "}
                    {competition && competition.end_date
                      ? competition.end_date
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Bodyweight Category
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {record.name}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 2,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                    rowspan={2}
                  >
                    Lot. No.
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                    rowspan={2}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                    rowspan={2}
                  >
                    NPC Code / Team
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                    rowspan={2}
                  >
                    DoB
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                    rowspan={2}
                  >
                    Age Group
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                    rowspan={2}
                  >
                    Body weight
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                    rowspan={2}
                  >
                    Weight Class
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                    rowspan={2}
                  >
                    Rack height
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                    colspan={3}
                  >
                    Attempts
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                    rowspan={2}
                  >
                    Best Lift
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                    rowspan={2}
                  >
                    Rank
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                  >
                    1 Attempt
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                  >
                    2 Attempt
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                  >
                    3 Attempt
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lifters.map((lifter) => (
                  <TableRow>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.lot_number}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.surename} {lifter.firstname}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.country}{" "}
                      {teams &&
                      teams.length &&
                      teams.find((item) => item.id === lifter.team)
                        ? teams.find((item) => item.id === lifter.team).name
                        : null}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.date_of_birth}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.divisions && lifter.divisions.length
                        ? lifter.divisions.join(", ")
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.bodyweight}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {[...WomensWc, ...MensWc].find(
                        (item) => item.id === lifter.weight_class,
                      )
                        ? [...WomensWc, ...MensWc].find(
                            (item) => item.id === lifter.weight_class,
                          ).name
                        : lifter.weight_class}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.rack_height}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.first_attempt}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 8,
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 8,
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 8,
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 8,
                      }}
                    ></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        ) : null}
        {lifters.length && dlgTitle === "Weigh in" ? (
          <Box id="toprint" ref={exportRef}>
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "10px",
                    maxWidth: "900px",
                  }}
                >
                  {competition.pictures &&
                    competition.pictures.map((item) => (
                      <img
                        alt={item.name}
                        align="center"
                        width="100"
                        src={item.src}
                      />
                    ))}
                </div>
              </div>
              <h1 style={{ fontSize: 14 }}>Weigh-in List</h1>
            </div>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 32,
                marginTop: 32,
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                      width: "15%",
                    }}
                  >
                    Competition
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition ? competition.meet_name : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition && competition.start_date
                      ? competition.start_date
                      : null}{" "}
                    {competition && competition.end_date
                      ? competition.end_date
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Bodyweight Category
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {record.name}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 2,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Lot. No.
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    NPC Code / Team
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    DoB
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Body weight
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Weight Class
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Squat First Attempt
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Bench First Attempt
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Deadlift First Attempt
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lifters.map((lifter) => (
                  <TableRow>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.lot_number}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.surename} {lifter.firstname}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.country}{" "}
                      {teams &&
                      teams.length &&
                      teams.find((item) => item.id === lifter.team)
                        ? teams.find((item) => item.id === lifter.team).name
                        : null}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.date_of_birth}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.bodyweight}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {[...WomensWc, ...MensWc].find(
                        (item) => item.id === lifter.weight_class,
                      )
                        ? [...WomensWc, ...MensWc].find(
                            (item) => item.id === lifter.weight_class,
                          ).name
                        : lifter.weight_class}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.squat_first_attempt}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.bench_first_attempt}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 12,
                      }}
                    >
                      {lifter.deadlift_first_attempt}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "none",
                borderWidth: 0,
                marginBottom: 32,
                marginTop: 32,
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      borderWidth: 0,
                      padding: 4,
                      fontWeight: "bold",
                      fontSize: 10,
                    }}
                  >
                    Weigh-in Official Name:
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        ) : null}
        {lifters.length && dlgTitle === "Kit Check" ? (
          <Box id="toprint" ref={exportRef}>
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "10px",
                    maxWidth: "900px",
                  }}
                >
                  {competition.pictures &&
                    competition.pictures.map((item) => (
                      <img
                        alt={item.name}
                        align="center"
                        width="100"
                        src={item.src}
                      />
                    ))}
                </div>
              </div>
              <h1 style={{ fontSize: 14 }}>Kit Check Inspection Sheet</h1>
            </div>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 32,
                marginTop: 32,
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                      width: "15%",
                    }}
                  >
                    Competition
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition ? competition.meet_name : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {competition && competition.start_date
                      ? competition.start_date
                      : null}{" "}
                    {competition && competition.end_date
                      ? competition.end_date
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Session
                  </TableCell>
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    colspan={2}
                  >
                    {record.name}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table
              className="myFormat"
              size="small"
              style={{
                width: "100%",
                border: "solid",
                borderWidth: 1,
                borderCollapse: "collapse",
                marginBottom: 2,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Lot. No.
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    NPC Code / Team
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Body weight
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Lifting suit
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Shirt
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Knee sleeves
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Briefs
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Socks
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Shoes
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Wrist wraps
                  </TableCell>
                  <TableCell
                    style={{
                      border: "solid",
                      borderWidth: 1,
                      padding: 4,
                      fontSize: 10,
                    }}
                  >
                    Belt
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lifters.map((lifter) => (
                  <TableRow>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    >
                      {lifter.lot_number}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 10,
                      }}
                    >
                      {lifter.surename} {lifter.firstname}
                    </TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    >
                      {lifter.country}{" "}
                      {teams &&
                      teams.length &&
                      teams.find((item) => item.id === lifter.team)
                        ? teams.find((item) => item.id === lifter.team).name
                        : null}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "solid",
                        borderWidth: 1,
                        padding: 4,
                        fontSize: 10,
                      }}
                    >
                      {[...WomensWc, ...MensWc].find(
                        (item) => item.id === lifter.weight_class,
                      )
                        ? [...WomensWc, ...MensWc].find(
                            (item) => item.id === lifter.weight_class,
                          ).name
                        : lifter.weight_class}
                    </TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    ></TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    ></TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    ></TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    ></TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    ></TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    ></TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    ></TableCell>
                    <TableCell
                      style={{ border: "solid", borderWidth: 1, padding: 4 }}
                    ></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <p>
              Kit Check Official Name:
              <br />
              Signature:
            </p>
          </Box>
        ) : null}
      </Dialog>
    </>
  );
};
