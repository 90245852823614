import * as React from "react";
import { useEffect, useRef } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Button as MUIButton,
  Box,
} from "@mui/material";
import {
  Button,
  useGetList,
  Loading,
  useDataProvider,
  useGetOne,
} from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import RestoreIcon from "@mui/icons-material/Restore";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Draggable, { DraggableCore } from "react-draggable";
import { Countries as countries } from "./Countries";
import PomSubsView from "./PomSubsView";
import Parse from "parse";

const BarPlates = ({ wheight, refreshedPlatform, competition }) => {
  const ref = useRef();
  const dataProvider = useDataProvider();
  useEffect(() => {
    if (ref.current) {
      var totalWeight = parseFloat(wheight); /* fixed to kg for now */
      var unit = "Kg"; /* fixed to kg for now */
      var collar = 2.5; /* always in kg */
      let fifthy_pairs = competition.fifthy_pairs;
      let twentyfive_pairs = competition.twentyfive_pairs;
      let twenty_pairs = competition.twenty_pairs;
      let fifteen_pairs = competition.fifteen_pairs;
      let ten_pairs = competition.ten_pairs;
      let five_pairs = competition.five_pairs;
      let twofive_pairs = competition.twofive_pairs;
      let one_twofive_pairs = competition.one_twofive_pairs;
      let one_dotfive_pairs = competition.one_dotfive_pairs;
      let one_pairs = competition.one_pairs;
      let dotfive_pairs = competition.dotfive_pairs;
      // console.log(competition);
      // load canvas
      // var ctx_kg = document.getElementById("w2" + '-cv1').getContext('2d');
      // var ctx_lbs = document.getElementById("w2" + '-cv2').getContext('2d');
      const ctx_kg = ref.current.getContext("2d");
      // clean areas
      barLoadingWidget.clearCanvas(ctx_kg);
      if (totalWeight < 20 || totalWeight > 325) {
        // barLoadingWidget.clearCanvas(ctx_lbs);
        // console.log("invalid weight");
        return;
      }
      // create bar
      barLoadingWidget.drawBar(
        ctx_kg,
        Math.round(totalWeight) + " kg",
        totalWeight < 25 ? 15 : 20,
      );
      // create collars
      if (collar > 0) barLoadingWidget.drawCollar(ctx_kg, collar);
      // calculate weights
      // #1: 50kg plates at 235+ and 304+ kg

      var sideWeight =
        totalWeight < 25
          ? (totalWeight - 15) / 2 - collar
          : (totalWeight - 20) / 2 - collar;
      var remainingWeight = sideWeight;

      console.log(remainingWeight);

      var plates = new Array();

      if (totalWeight > 235) {
        if (fifthy_pairs > 0) {
          plates.push(50);
          remainingWeight -= 50;
          fifthy_pairs -= 1;
        }
      }
      if (totalWeight > 304) {
        if (fifthy_pairs > 0) {
          plates.push(50);
          remainingWeight -= 50;
          fifthy_pairs -= 1;
        }
      }

      for (var i = twentyfive_pairs; i > 0; i--) {
        if (remainingWeight >= 25) {
          if (twentyfive_pairs > 0) {
            plates.push(25);
            remainingWeight -= 25;
            twentyfive_pairs -= 1;
          }
        }
      }

      for (var i = twenty_pairs; i > 0; i--) {
        if (remainingWeight >= 20) {
          if (twenty_pairs > 0) {
            plates.push(20);
            remainingWeight -= 20;
            twentyfive_pairs -= 1;
          }
        }
      }

      for (var i = fifteen_pairs; i > 0; i--) {
        if (remainingWeight >= 15) {
          if (fifteen_pairs > 0) {
            plates.push(15);
            remainingWeight -= 15;
            fifteen_pairs -= 1;
          }
        }
      }

      for (var i = ten_pairs; i > 0; i--) {
        if (remainingWeight >= 10) {
          if (ten_pairs > 0) {
            plates.push(10);
            remainingWeight -= 10;
            ten_pairs -= 1;
          }
        }
      }

      for (var i = five_pairs; i > 0; i--) {
        if (remainingWeight >= 5) {
          if (five_pairs > 0) {
            plates.push(5);
            remainingWeight -= 5;
            five_pairs -= 1;
          }
        }
      }

      for (var i = twofive_pairs; i > 0; i--) {
        if (remainingWeight >= 2.5) {
          if (twofive_pairs > 0) {
            plates.push(2.5);
            remainingWeight -= 2.5;
            twofive_pairs -= 1;
          }
        }
      }

      for (var i = one_dotfive_pairs; i > 0; i--) {
        if (remainingWeight >= 1.5) {
          if (one_dotfive_pairs > 0) {
            plates.push(1.5);
            remainingWeight -= 1.5;
            one_dotfive_pairs -= 1;
          }
        }
      }

      for (var i = one_twofive_pairs; i > 0; i--) {
        console.log("entro");
        if (remainingWeight >= 1.25) {
          if (one_twofive_pairs > 0) {
            plates.push(1.25);
            remainingWeight -= 1.25;
            one_twofive_pairs -= 1;
          }
        }
      }

      for (var i = one_pairs; i > 0; i--) {
        if (remainingWeight >= 1) {
          if (one_pairs > 0) {
            plates.push(1);
            remainingWeight -= 1;
            one_pairs -= 1;
          }
        }
      }

      for (var i = dotfive_pairs; i > 0; i--) {
        if (remainingWeight >= 0.5) {
          if (dotfive_pairs > 0) {
            plates.push(0.5);
            remainingWeight -= 0.5;
            dotfive_pairs -= 1;
          }
        }
      }

      // print plates
      for (var i = plates.length - 1; i >= 0; i--) {
        barLoadingWidget.drawPlate(ctx_kg, plates[i], "kg");
      }
      // show remaining
      if (remainingWeight)
        barLoadingWidget.drawNotes(
          ctx_kg,
          "Missing weight = " + remainingWeight.toFixed(3) + " kg",
        );
    }
  }, [wheight]);

  const barLoadingWidget = {
    x: 0,
    platesKg: {
      0.5: {
        colour: competition.dotfive_color,
        text: "black",
        height: 150,
        width: 25,
      },
      1: {
        colour: competition.one_color,
        text: "black",
        height: 170,
        width: 25,
      },
      1.25: {
        colour: competition.one_twofive_color,
        text: "black",
        height: 180,
        width: 25,
      },
      1.5: {
        colour: competition.one_dotfive_color,
        text: "black",
        height: 180,
        width: 25,
      },
      2: {
        colour: competition.two_color,
        text: "black",
        height: 190,
        width: 25,
      },
      2.5: {
        colour: competition.twofive_color,
        text: "white",
        height: 200,
        width: 25,
      },
      5: {
        colour: competition.five_color,
        text: "black",
        height: 360,
        width: 27,
      },
      10: {
        colour: competition.ten_color,
        text: "black",
        height: 360,
        width: 27,
      },
      15: {
        colour: competition.fifteen_color,
        text: "black",
        height: 360,
        width: 27,
      },
      20: {
        colour: competition.twenty_color,
        text: "black",
        height: 360,
        width: 37,
      },
      25: {
        colour: competition.twentyfive_color,
        text: "black",
        height: 360,
        width: 42,
      },
      50: {
        colour: competition.fifthy_color,
        text: "white",
        height: 360,
        width: 42,
      },
    },
    platesLbs: {
      2.5: {
        colour: "gray",
        text: "white",
        height: 75,
        width: 20,
      },
      5: {
        colour: "gray",
        text: "white",
        height: 85,
        width: 22,
      },
      10: {
        colour: "gray",
        text: "white",
        height: 90,
        width: 24,
      },
      25: {
        colour: "gray",
        text: "white",
        height: 95,
        width: 26,
      },
      35: {
        colour: "gray",
        text: "white",
        height: 100,
        width: 26,
      },
      45: {
        colour: "gray",
        text: "white",
        height: 180,
        width: 28,
      },
      100: {
        colour: "gray",
        text: "white",
        height: 180,
        width: 30,
      },
    },
    clearCanvas: function (ctx, totalWeight) {
      ctx.clearRect(0, 0, 680, 500);
      ctx.font = "12px Arial";
      this.x = 314;
    },
    drawBar: function (ctx, totalWeight, barWeight) {
      var barHeight = 24;
      ctx.fillStyle = "silver";
      ctx.fillRect(5, 200 - barHeight / 2, 370, barHeight);
      ctx.fillStyle = "black";
      ctx.strokeRect(5, 200 - barHeight / 2, 370, barHeight);
      ctx.fillText(barWeight, 30, 204);
      var font = ctx.font;
      ctx.font = "bold 18px Arial";
      ctx.fillStyle = "#000";
      ctx.fillText(totalWeight, 300, 184);
      ctx.font = font;
    },
    drawCollar: function (ctx, collarWeight) {
      var collarHeight = 48,
        collarWidth = 24;
      ctx.beginPath();
      ctx.lineWidth = 4;
      ctx.moveTo(this.x + collarWidth / 2, 200 - collarHeight / 2);
      ctx.lineTo(this.x + (3 * collarWidth) / 4, 200 - collarHeight / 2 - 10);
      ctx.stroke();
      ctx.lineWidth = 1;
      ctx.fillStyle = "silver";
      ctx.fillRect(this.x, 200 - collarHeight / 2, collarWidth, collarHeight);
      ctx.fillStyle = "black";
      ctx.strokeRect(this.x, 200 - collarHeight / 2, collarWidth, collarHeight);
      var textWidth = ctx.measureText("2.5").width;
      ctx.fillText(collarWeight, this.x + (collarWidth - textWidth) / 2, 204);
    },
    drawPlate: function (ctx, plate, unit) {
      var plateDefinition =
        unit === "lbs"
          ? this.platesLbs[plate.toString()]
          : this.platesKg[plate.toString()];
      this.x -= plateDefinition.width;
      ctx.fillStyle = plateDefinition.colour;
      ctx.fillRect(
        this.x,
        200 - plateDefinition.height / 2,
        plateDefinition.width,
        plateDefinition.height,
      );
      ctx.fillStyle = "gray";
      ctx.strokeRect(
        this.x,
        200 - plateDefinition.height / 2,
        plateDefinition.width,
        plateDefinition.height,
      );
      var textWidth = ctx.measureText(plate).width;
      ctx.fillStyle = plateDefinition.text;
      ctx.fillText(
        plate,
        this.x + (plateDefinition.width - textWidth) / 2,
        200,
      );
    },
    drawNotes: function (ctx, text) {
      ctx.font = "18px Arial";
      ctx.fillStyle = "#ff0000";
      ctx.fillText(text, 10, 380);
    },
  };

  return <canvas ref={ref} id="w2-cv1" width="680" height="400"></canvas>;
};

const Decision = ({ referee, index }) => (
  <>
    {index !== 5 ? (
      <Box
        backgroundColor={
          referee && referee[index] === "bp"
            ? "turquoise"
            : referee && referee[index] === "ds"
              ? "blue"
              : referee && referee[index] === "ss"
                ? "orange"
                : referee && referee[index] === "ps"
                  ? "purple"
                  : referee && referee[index] === "good"
                    ? "none"
                    : "none"
        }
        component="div"
        sx={{
          borderRadius: 2,
          width: index === 5 ? "93%" : "80%",
          margin: "auto",
        }}
      />
    ) : (
      <Box
        backgroundColor={
          referee &&
          (referee[0] === "bp" ||
            referee[0] === "ds" ||
            referee[0] === "ss" ||
            referee[0] === "ps")
            ? "red"
            : referee && referee[0] === "good"
              ? "#ffffff"
              : "none"
        }
        component="div"
        sx={{
          borderRadius: 2,
          width: index === 5 ? "93%" : "80%",
          height: "90%",
          margin: "auto",
        }}
      />
    )}
  </>
);

export const Display = () => {
  const navigate = useNavigate();
  const user = Parse.User.current();
  const { platform } = useParams();
  const dataProvider = useDataProvider();
  const [round, setRound] = React.useState("1");
  const { data: competition } = useGetOne("competitions", {
    id: localStorage.getItem("currentCompetition"),
  });

  const { data: MensWc } = useGetList("weight_class", {
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const { data: WomensWc } = useGetList("weight_class", {
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const {
    data: lifters,
    total,
    isLoading,
    error,
  } = useGetList("lifters", {
    filter: {
      platform,
    },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "surename", order: "ASC" },
  });

  const [refreshedLifters, setRefreshedLifters] = React.useState(
    lifters || null,
  );

  const {
    data: teams,
    total2,
    isLoading2,
    error2,
  } = useGetList("teams", {
    filter: {
      competition: localStorage.getItem("currentCompetition"),
    },
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "name", order: "ASC" },
  });

  const {
    data: record,
    isLoading3,
    error3,
  } = useGetOne("platforms", { id: platform });
  const [refreshedPlatform, setRefreshedPlatform] = React.useState(
    record || null,
  );

  const livequery = async () => {
    // const self = this;
    if (!refreshedPlatform && platform) {
      const { data: recordT } = await dataProvider.getOne("platforms", {
        id: platform,
      });
      setRefreshedPlatform(recordT);
    }

    const query = new Parse.Query("platforms");
    query.equalTo("objectId", platform);
    const subscription = await query.subscribe();
    subscription.on("update", async (object) => {
      // console.log("Entro a update de la primera subscripción");
      setRound(object.get("round"));
      setRefreshedPlatform(object.toJSON());
    });

    const queryLifters = new Parse.Query("lifters");
    queryLifters.equalTo("platform", platform);
    const subscriptionLifers = await queryLifters.subscribe();
    subscriptionLifers.on("update", async (object) => {
      //Obtengo al current
      const { data: liftersT } = await dataProvider.getList("lifters", {
        filter: {
          platform,
        },
        pagination: { page: 1, perPage: 10000 },
        sort: { field: "surename", order: "ASC" },
      });
      setRefreshedLifters(liftersT);
    });
  };

  React.useEffect(() => {
    if (platform) {
      livequery();
    }
  }, [platform]);

  React.useEffect(() => {
    if (!isLoading && lifters.length && !refreshedLifters)
      setRefreshedLifters(lifters);
  }, [isLoading]);

  React.useEffect(() => {
    if (refreshedPlatform) setRound(refreshedPlatform.round);
  }, [refreshedPlatform]);

  if (
    isLoading ||
    isLoading2 ||
    isLoading3 ||
    !refreshedPlatform ||
    !competition
  ) {
    return <Loading />;
  }
  if (error || error2 || error3) {
    return <p>ERROR</p>;
  }

  return (
    <Card className="darkside">
      {/*<CardHeader title={`${record.name}`} />*/}
      <CardContent>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              height: "20vh",
              padding: "5px",
              borderRadius: "10px",
              // maxWidth: "900px",
            }}
          >
            {competition.pictures &&
              competition.pictures.map((item) => (
                <img align="center" height="80%" src={item.src} />
              ))}
          </div>
        </div>
        {refreshedPlatform && refreshedPlatform.current ? (
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Draggable>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                >
                  {refreshedPlatform.current.country &&
                  refreshedPlatform.current.country
                    .toLowerCase()
                    .includes("hungary") ? (
                    <img
                      style={{ borderRadius: "5px" }}
                      src={`/hung.jpg`}
                      loading="lazy"
                      width="160"
                      alt=""
                    />
                  ) : refreshedPlatform.current.country &&
                    refreshedPlatform.current.country
                      .toLowerCase()
                      .includes("ukraine") ? (
                    <img
                      style={{ borderRadius: "5px" }}
                      src={`/ucr.jpg`}
                      loading="lazy"
                      width="160"
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ borderRadius: "5px" }}
                      src={`/hellas.png`}
                      loading="lazy"
                      width="160"
                      alt=""
                    />
                  )}
                  <h3
                    style={{
                      color: "#008000",
                      fontSize: "6vw",
                      margin: 0,
                      lineHeight: "6vw",
                      marginLeft: "5px",
                    }}
                  >
                    <span
                      style={{
                        color: "#008000",
                        fontSize: "6vw",
                      }}
                    >
                      {refreshedPlatform &&
                        refreshedPlatform.current &&
                        refreshedPlatform.current.lot_number}
                      .{" "}
                    </span>
                    {refreshedPlatform &&
                      refreshedPlatform.current &&
                      refreshedPlatform.current.surename}{" "}
                    {refreshedPlatform &&
                      refreshedPlatform.current &&
                      refreshedPlatform.current.firstname}
                  </h3>
                </div>
              </Draggable>
              <Draggable>
                <h2 style={{ margin: 0 }}>
                  {refreshedPlatform &&
                  refreshedPlatform.current &&
                  refreshedPlatform.current.team &&
                  teams &&
                  teams.length &&
                  teams.find(
                    (item) => item.id === refreshedPlatform.current.team,
                  )
                    ? teams.find(
                        (item) => item.id === refreshedPlatform.current.team,
                      ).name
                    : null}
                </h2>
              </Draggable>
              <Draggable>
                <h3 style={{ margin: 0, fontSize: "3vw" }}>
                  Att
                  {" : "}
                  {(refreshedPlatform.round && refreshedPlatform.round == 4) ||
                  refreshedPlatform.round == 7
                    ? "1"
                    : (refreshedPlatform.round &&
                          refreshedPlatform.round == 5) ||
                        refreshedPlatform.round == 8
                      ? "2"
                      : (refreshedPlatform.round &&
                            refreshedPlatform.round == 6) ||
                          refreshedPlatform.round == 9
                        ? "3"
                        : refreshedPlatform.round}
                  {" , "}
                  {MensWc &&
                  WomensWc &&
                  [...MensWc, ...WomensWc].find(
                    (item) =>
                      item.id === refreshedPlatform.current.weight_class,
                  )
                    ? [...MensWc, ...WomensWc].find(
                        (item) =>
                          item.id === refreshedPlatform.current.weight_class,
                      ).name
                    : null}
                </h3>
              </Draggable>
              <Draggable>
                <div>
                  {refreshedPlatform &&
                    refreshedPlatform.current &&
                    refreshedPlatform.current.country && (
                      <>
                        {countries.find(
                          (ctry) =>
                            ctry.code === refreshedPlatform.current.country,
                        ) &&
                          countries.find(
                            (ctry) =>
                              ctry.code === refreshedPlatform.current.country,
                          ).flag && (
                            <img
                              src={`/flags/${countries.find((ctry) => ctry.code === refreshedPlatform.current.country).flag}`}
                              loading="lazy"
                              width="80"
                              // src={`https://flagcdn.com/w20/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png`}
                              // srcSet={`https://flagcdn.com/w40/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png 2x`}
                              alt=""
                            />
                          )}
                        <h3 style={{ margin: 0, fontSize: "3vw" }}>
                          {refreshedPlatform.current.country}
                        </h3>
                      </>
                    )}
                </div>
              </Draggable>
              <Draggable>
                <div>
                  {refreshedLifters &&
                    refreshedPlatform &&
                    refreshedPlatform.current &&
                    refreshedPlatform.current.id && (
                      <BarPlates
                        competition={competition}
                        refreshedPlatform={refreshedPlatform}
                        wheight={
                          round === "1"
                            ? refreshedLifters.find(
                                (lifter) =>
                                  lifter.id === refreshedPlatform.current.id,
                              )["squat_first_attempt"]
                            : round === "2"
                              ? refreshedLifters.find(
                                  (lifter) =>
                                    lifter.id === refreshedPlatform.current.id,
                                )["squat_second_attempt"]
                              : round === "3"
                                ? refreshedLifters.find(
                                    (lifter) =>
                                      lifter.id ===
                                      refreshedPlatform.current.id,
                                  )["squat_third_attempt"]
                                : round === "4"
                                  ? refreshedLifters.find(
                                      (lifter) =>
                                        lifter.id ===
                                        refreshedPlatform.current.id,
                                    )["bench_first_attempt"]
                                  : round === "5"
                                    ? refreshedLifters.find(
                                        (lifter) =>
                                          lifter.id ===
                                          refreshedPlatform.current.id,
                                      )["bench_second_attempt"]
                                    : round === "6"
                                      ? refreshedLifters.find(
                                          (lifter) =>
                                            lifter.id ===
                                            refreshedPlatform.current.id,
                                        )["bench_third_attempt"]
                                      : round === "7"
                                        ? refreshedLifters.find(
                                            (lifter) =>
                                              lifter.id ===
                                              refreshedPlatform.current.id,
                                          )["deadlift_first_attempt"]
                                        : round === "8"
                                          ? refreshedLifters.find(
                                              (lifter) =>
                                                lifter.id ===
                                                refreshedPlatform.current.id,
                                            )["deadlift_second_attempt"]
                                          : round === "9"
                                            ? refreshedLifters.find(
                                                (lifter) =>
                                                  lifter.id ===
                                                  refreshedPlatform.current.id,
                                              )["deadlift_third_attempt"]
                                            : null
                        }
                      />
                    )}
                </div>
              </Draggable>
            </Grid>
            <Grid item xs={5}>
              <Draggable>
                <h3 style={{ textAlign: "right", fontSize: "5vw", margin: 0 }}>
                  {refreshedLifters &&
                    refreshedPlatform &&
                    refreshedPlatform.current &&
                    refreshedPlatform.current.id &&
                    (round === "1"
                      ? refreshedLifters.find(
                          (lifter) =>
                            lifter.id === refreshedPlatform.current.id,
                        )["squat_first_attempt"]
                      : round === "2"
                        ? refreshedLifters.find(
                            (lifter) =>
                              lifter.id === refreshedPlatform.current.id,
                          )["squat_second_attempt"]
                        : round === "3"
                          ? refreshedLifters.find(
                              (lifter) =>
                                lifter.id === refreshedPlatform.current.id,
                            )["squat_third_attempt"]
                          : round === "4"
                            ? refreshedLifters.find(
                                (lifter) =>
                                  lifter.id === refreshedPlatform.current.id,
                              )["bench_first_attempt"]
                            : round === "5"
                              ? refreshedLifters.find(
                                  (lifter) =>
                                    lifter.id === refreshedPlatform.current.id,
                                )["bench_second_attempt"]
                              : round === "6"
                                ? refreshedLifters.find(
                                    (lifter) =>
                                      lifter.id ===
                                      refreshedPlatform.current.id,
                                  )["bench_third_attempt"]
                                : round === "7"
                                  ? refreshedLifters.find(
                                      (lifter) =>
                                        lifter.id ===
                                        refreshedPlatform.current.id,
                                    )["deadlift_first_attempt"]
                                  : round === "8"
                                    ? refreshedLifters.find(
                                        (lifter) =>
                                          lifter.id ===
                                          refreshedPlatform.current.id,
                                      )["deadlift_second_attempt"]
                                    : round === "9"
                                      ? refreshedLifters.find(
                                          (lifter) =>
                                            lifter.id ===
                                            refreshedPlatform.current.id,
                                        )["deadlift_third_attempt"]
                                      : null)}{" "}
                  Kg{". "}
                  Rack{" "}
                  <span style={{ color: "#008000", fontSize: "6vw" }}>
                    {(round === "1" || round === "2" || round === "3") &&
                      refreshedPlatform &&
                      refreshedPlatform.current &&
                      refreshedPlatform.current.id &&
                      refreshedLifters &&
                      refreshedLifters.find(
                        (lifter) => lifter.id === refreshedPlatform.current.id,
                      )["squat_rack_height"]}
                    {(round === "4" || round === "5" || round === "6") &&
                      refreshedPlatform &&
                      refreshedPlatform.current &&
                      refreshedPlatform.current.id &&
                      refreshedLifters &&
                      refreshedLifters.find(
                        (lifter) => lifter.id === refreshedPlatform.current.id,
                      )["bench_rack_height"]}
                  </span>
                </h3>
              </Draggable>
              <Draggable>
                <div>
                  <PomSubsView refreshedPlatform={refreshedPlatform} />
                </div>
              </Draggable>
              <Draggable>
                <div>
                  {refreshedPlatform && (
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid grey",
                        height: "15vh",
                        padding: 5,
                        borderRadius: 8,
                      }}
                    >
                      <Grid container spacing={0}>
                        <Grid item xs={12} style={{ marginBottom: "5px" }}>
                          <Decision referee={refreshedPlatform.lr} index={5} />
                        </Grid>
                        {/*<Grid item xs={3}>
                                          <Decision referee={refreshedPlatform.lr} index={0} />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Decision referee={refreshedPlatform.lr} index={2} />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Decision referee={refreshedPlatform.lr} index={3} />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Decision referee={refreshedPlatform.lr} index={3} />
                                        </Grid>*/}
                      </Grid>
                      <Grid container spacing={0}>
                        <Grid item xs={12} style={{ marginBottom: "5px" }}>
                          <Decision referee={refreshedPlatform.hr} index={5} />
                        </Grid>
                        {/*<Grid item xs={3}>
                                          <Decision referee={refreshedPlatform.hr} index={0} />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Decision referee={refreshedPlatform.hr} index={1} />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Decision referee={refreshedPlatform.hr} index={2} />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Decision referee={refreshedPlatform.hr} index={3} />
                                        </Grid>*/}
                      </Grid>
                      <Grid container spacing={0}>
                        <Grid item xs={12} style={{ marginBottom: "5px" }}>
                          <Decision referee={refreshedPlatform.rr} index={5} />
                        </Grid>
                        {/*<Grid item xs={3}>
                                           <Decision referee={refreshedPlatform.rr} index={0} />
                                        </Grid>
                                        <Grid item xs={3}>
                                           <Decision referee={refreshedPlatform.rr} index={1} />
                                        </Grid>
                                        <Grid item xs={3}>
                                           <Decision referee={refreshedPlatform.rr} index={2} />
                                        </Grid>
                                        <Grid item xs={3}>
                                           <Decision referee={refreshedPlatform.rr} index={3} />
                                        </Grid>*/}
                      </Grid>
                    </div>
                  )}
                </div>
              </Draggable>
              {/* <Draggable> */}
              {/*   <div> */}
              {/*     {refreshedLifters && */}
              {/*       refreshedPlatform && */}
              {/*       refreshedPlatform.current && */}
              {/*       refreshedPlatform.current.id && ( */}
              {/*         <h1 style={{ fontSize: "6vw", margin: 0 }}> */}
              {/*           {round === "1" */}
              {/*             ? refreshedLifters.find( */}
              {/*                 (lifter) => */}
              {/*                   lifter.id === refreshedPlatform.current.id, */}
              {/*               )["squat_first_attempt"] */}
              {/*             : round === "2" */}
              {/*               ? refreshedLifters.find( */}
              {/*                   (lifter) => */}
              {/*                     lifter.id === refreshedPlatform.current.id, */}
              {/*                 )["squat_second_attempt"] */}
              {/*               : round === "3" */}
              {/*                 ? refreshedLifters.find( */}
              {/*                     (lifter) => */}
              {/*                       lifter.id === refreshedPlatform.current.id, */}
              {/*                   )["squat_third_attempt"] */}
              {/*                 : round === "4" */}
              {/*                   ? refreshedLifters.find( */}
              {/*                       (lifter) => */}
              {/*                         lifter.id === */}
              {/*                         refreshedPlatform.current.id, */}
              {/*                     )["bench_first_attempt"] */}
              {/*                   : round === "5" */}
              {/*                     ? refreshedLifters.find( */}
              {/*                         (lifter) => */}
              {/*                           lifter.id === */}
              {/*                           refreshedPlatform.current.id, */}
              {/*                       )["bench_second_attempt"] */}
              {/*                     : round === "6" */}
              {/*                       ? refreshedLifters.find( */}
              {/*                           (lifter) => */}
              {/*                             lifter.id === */}
              {/*                             refreshedPlatform.current.id, */}
              {/*                         )["bench_third_attempt"] */}
              {/*                       : round === "7" */}
              {/*                         ? refreshedLifters.find( */}
              {/*                             (lifter) => */}
              {/*                               lifter.id === */}
              {/*                               refreshedPlatform.current.id, */}
              {/*                           )["deadlift_first_attempt"] */}
              {/*                         : round === "8" */}
              {/*                           ? refreshedLifters.find( */}
              {/*                               (lifter) => */}
              {/*                                 lifter.id === */}
              {/*                                 refreshedPlatform.current.id, */}
              {/*                             )["deadlift_second_attempt"] */}
              {/*                           : round === "9" */}
              {/*                             ? refreshedLifters.find( */}
              {/*                                 (lifter) => */}
              {/*                                   lifter.id === */}
              {/*                                   refreshedPlatform.current.id, */}
              {/*                               )["deadlift_third_attempt"] */}
              {/*                             : null}{" "} */}
              {/*           Kg */}
              {/*         </h1> */}
              {/*       )} */}
              {/*   </div> */}
              {/* </Draggable> */}
            </Grid>
          </Grid>
        ) : null}
      </CardContent>
    </Card>
  );
};
