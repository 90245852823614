import * as React from "react";
import { useEffect, useRef } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Button as MUIButton,
  Box,
} from "@mui/material";
import {
  Button,
  useGetList,
  Loading,
  useDataProvider,
  useGetOne,
} from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import RestoreIcon from "@mui/icons-material/Restore";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Countries as countries } from "./Countries";
import Draggable, { DraggableCore } from "react-draggable";
import PomSubsView from "./PomSubsView";
import Parse from "parse";
import { wilks2020 } from "powerlifting-formulas";
const BarPlates = ({ wheight, refreshedPlatform, competition }) => {
  const ref = useRef();
  const dataProvider = useDataProvider();
  useEffect(() => {
    if (ref.current) {
      console.log(wheight);
      var totalWeight = parseFloat(wheight); /* fixed to kg for now */
      var unit = "Kg"; /* fixed to kg for now */
      var collar = 2.5; /* always in kg */
      let fifthy_pairs = competition.fifthy_pairs;
      let twentyfive_pairs = competition.twentyfive_pairs;
      let twenty_pairs = competition.twenty_pairs;
      let fifteen_pairs = competition.fifteen_pairs;
      let ten_pairs = competition.ten_pairs;
      let five_pairs = competition.five_pairs;
      let twofive_pairs = competition.twofive_pairs;
      let one_dotfive_pairs = competition.one_dotfive_pairs;
      let one_twofive_pairs = competition.one_twofive_pairs;
      let one_pairs = competition.one_pairs;
      let dotfive_pairs = competition.dotfive_pairs;
      console.log(totalWeight);
      // load canvas
      // var ctx_kg = document.getElementById("w2" + '-cv1').getContext('2d');
      // var ctx_lbs = document.getElementById("w2" + '-cv2').getContext('2d');
      const ctx_kg = ref.current.getContext("2d");
      // clean areas
      barLoadingWidget.clearCanvas(ctx_kg);
      if (totalWeight < 20 || totalWeight > 325) {
        // barLoadingWidget.clearCanvas(ctx_lbs);
        return;
      }

      // create bar
      barLoadingWidget.drawBar(
        ctx_kg,
        Math.round(totalWeight) + " kg",
        totalWeight < 25 ? 15 : 20,
      );
      // create collars
      if (collar > 0) barLoadingWidget.drawCollar(ctx_kg, collar);
      // calculate weights
      // #1: 50kg plates at 235+ and 304+ kg

      var sideWeight =
        totalWeight < 25
          ? (totalWeight - 15) / 2 - collar
          : (totalWeight - 20) / 2 - collar;
      var remainingWeight = sideWeight;
      console.log(remainingWeight);
      var plates = new Array();

      if (totalWeight > 235) {
        if (fifthy_pairs > 0) {
          plates.push(50);
          remainingWeight -= 50;
          fifthy_pairs -= 1;
        }
      }
      if (totalWeight > 304) {
        if (fifthy_pairs > 0) {
          plates.push(50);
          remainingWeight -= 50;
          fifthy_pairs -= 1;
        }
      }

      for (var i = twentyfive_pairs; i > 0; i--) {
        if (remainingWeight >= 25) {
          if (twentyfive_pairs > 0) {
            plates.push(25);
            remainingWeight -= 25;
            twentyfive_pairs -= 1;
          }
        }
      }

      for (var i = twenty_pairs; i > 0; i--) {
        if (remainingWeight >= 20) {
          if (twenty_pairs > 0) {
            plates.push(20);
            remainingWeight -= 20;
            twentyfive_pairs -= 1;
          }
        }
      }

      for (var i = fifteen_pairs; i > 0; i--) {
        if (remainingWeight >= 15) {
          if (fifteen_pairs > 0) {
            plates.push(15);
            remainingWeight -= 15;
            fifteen_pairs -= 1;
          }
        }
      }

      for (var i = ten_pairs; i > 0; i--) {
        if (remainingWeight >= 10) {
          if (ten_pairs > 0) {
            plates.push(10);
            remainingWeight -= 10;
            ten_pairs -= 1;
          }
        }
      }

      for (var i = five_pairs; i > 0; i--) {
        if (remainingWeight >= 5) {
          if (five_pairs > 0) {
            plates.push(5);
            remainingWeight -= 5;
            five_pairs -= 1;
          }
        }
      }

      for (var i = twofive_pairs; i > 0; i--) {
        if (remainingWeight >= 2.5) {
          if (twofive_pairs > 0) {
            plates.push(2.5);
            remainingWeight -= 2.5;
            twofive_pairs -= 1;
          }
        }
      }

      for (var i = one_dotfive_pairs; i > 0; i--) {
        if (remainingWeight >= 1.5) {
          if (one_pairs > 0) {
            plates.push(1.5);
            remainingWeight -= 1.5;
            one_dotfive_pairs -= 1.5;
          }
        }
      }

      for (var i = one_twofive_pairs; i > 0; i--) {
        console.log("entro");
        if (remainingWeight >= 1.25) {
          if (one_twofive_pairs > 0) {
            plates.push(1.25);
            remainingWeight -= 1.25;
            one_twofive_pairs -= 1;
          }
        }
      }

      for (var i = one_pairs; i > 0; i--) {
        if (remainingWeight >= 1) {
          if (one_pairs > 0) {
            plates.push(1);
            remainingWeight -= 1;
            one_pairs -= 1;
          }
        }
      }

      for (var i = dotfive_pairs; i > 0; i--) {
        if (remainingWeight >= 0.5) {
          if (dotfive_pairs > 0) {
            plates.push(0.5);
            remainingWeight -= 0.5;
            dotfive_pairs -= 1;
          }
        }
      }

      // print plates
      for (var i = plates.length - 1; i >= 0; i--) {
        barLoadingWidget.drawPlate(ctx_kg, plates[i], "kg");
      }
      // show remaining
      if (remainingWeight)
        barLoadingWidget.drawNotes(
          ctx_kg,
          "Missing weight = " + remainingWeight.toFixed(3) + " kg",
        );
    }
  }, [wheight]);

  const barLoadingWidget = {
    x: 0,
    platesKg: {
      0.5: {
        colour: competition.dotfive_color,
        text: "black",
        height: 75,
        width: 20,
      },
      1: {
        colour: competition.one_color,
        text: "black",
        height: 85,
        width: 20,
      },
      1.25: {
        colour: competition.one_twofive_color,
        text: "black",
        height: 90,
        width: 20,
      },
      1.5: {
        colour: competition.one_dotfive_color,
        text: "black",
        height: 90,
        width: 20,
      },
      2: {
        colour: competition.two_color,
        text: "black",
        height: 95,
        width: 20,
      },
      2.5: {
        colour: competition.twofive_color,
        text: "white",
        height: 100,
        width: 22,
      },
      5: {
        colour: competition.five_color,
        text: "black",
        height: 180,
        width: 24,
      },
      10: {
        colour: competition.ten_color,
        text: "black",
        height: 180,
        width: 24,
      },
      15: {
        colour: competition.fifteen_color,
        text: "black",
        height: 180,
        width: 24,
      },
      20: {
        colour: competition.twenty_color,
        text: "black",
        height: 180,
        width: 24,
      },
      25: {
        colour: competition.twentyfive_color,
        text: "black",
        height: 180,
        width: 30,
      },
      50: {
        colour: competition.fifthy_color,
        text: "white",
        height: 180,
        width: 30,
      },
    },
    platesLbs: {
      2.5: {
        colour: "gray",
        text: "white",
        height: 75,
        width: 20,
      },
      5: {
        colour: "gray",
        text: "white",
        height: 85,
        width: 22,
      },
      10: {
        colour: "gray",
        text: "white",
        height: 90,
        width: 24,
      },
      25: {
        colour: "gray",
        text: "white",
        height: 95,
        width: 26,
      },
      35: {
        colour: "gray",
        text: "white",
        height: 100,
        width: 26,
      },
      45: {
        colour: "gray",
        text: "white",
        height: 180,
        width: 28,
      },
      100: {
        colour: "gray",
        text: "white",
        height: 180,
        width: 30,
      },
    },
    clearCanvas: function (ctx, totalWeight) {
      ctx.clearRect(0, 0, 300, 300);
      ctx.font = "12px Arial";
      this.x = 234;
    },
    drawBar: function (ctx, totalWeight, barWeight) {
      var barHeight = 24;
      ctx.fillStyle = "silver";
      ctx.fillRect(5, 150 - barHeight / 2, 270, barHeight);
      ctx.fillStyle = "gray";
      ctx.strokeRect(5, 150 - barHeight / 2, 270, barHeight);
      ctx.fillText(barWeight, 30, 154);
      var font = ctx.font;
      ctx.font = "10px Arial";
      ctx.fillStyle = "black";
      ctx.fillText(totalWeight, 0, 0);
      ctx.font = font;
    },
    drawCollar: function (ctx, collarWeight) {
      var collarHeight = 48,
        collarWidth = 24;
      ctx.beginPath();
      ctx.lineWidth = 4;
      ctx.moveTo(this.x + collarWidth / 2, 150 - collarHeight / 2);
      ctx.lineTo(this.x + (3 * collarWidth) / 4, 150 - collarHeight / 2 - 10);
      ctx.stroke();
      ctx.lineWidth = 1;
      ctx.fillStyle = "silver";
      ctx.fillRect(this.x, 150 - collarHeight / 2, collarWidth, collarHeight);
      ctx.fillStyle = "gray";
      ctx.strokeRect(this.x, 150 - collarHeight / 2, collarWidth, collarHeight);
      var textWidth = ctx.measureText("2.5").width;
      ctx.fillText(collarWeight, this.x + (collarWidth - textWidth) / 2, 154);
    },
    drawPlate: function (ctx, plate, unit) {
      var plateDefinition =
        unit === "lbs"
          ? this.platesLbs[plate.toString()]
          : this.platesKg[plate.toString()];
      this.x -= plateDefinition.width;
      ctx.fillStyle = plateDefinition.colour;
      ctx.fillRect(
        this.x,
        150 - plateDefinition.height / 2,
        plateDefinition.width,
        plateDefinition.height,
      );
      ctx.fillStyle = "gray";
      ctx.strokeRect(
        this.x,
        150 - plateDefinition.height / 2,
        plateDefinition.width,
        plateDefinition.height,
      );
      var textWidth = ctx.measureText(plate).width;
      ctx.fillStyle = plateDefinition.text;
      ctx.fillText(
        plate,
        this.x + (plateDefinition.width - textWidth) / 2,
        154,
      );
    },
    drawNotes: function (ctx, text) {
      ctx.font = "18px Arial";
      ctx.fillStyle = "gray";
      ctx.fillText(text, 10, 280);
    },
  };

  return <canvas ref={ref} id="w2-cv1" width="280" height="250"></canvas>;
};

const Decision = ({ referee, index }) => (
  <>
    {index !== 5 ? (
      <Box
        backgroundColor={
          referee && referee[index] === "bp"
            ? "turquoise"
            : referee && referee[index] === "ds"
              ? "blue"
              : referee && referee[index] === "ss"
                ? "orange"
                : referee && referee[index] === "ps"
                  ? "purple"
                  : referee && referee[index] === "good"
                    ? "none"
                    : "none"
        }
        component="div"
        sx={{
          borderRadius: 2,
          width: index === 5 ? "93%" : "80%",
          height: 40,
          margin: "auto",
        }}
      />
    ) : (
      <Box
        backgroundColor={
          referee &&
          (referee[0] === "bp" ||
            referee[0] === "ds" ||
            referee[0] === "ss" ||
            referee[0] === "ps")
            ? "red"
            : referee && referee[0] === "good"
              ? "#ffffff"
              : "none"
        }
        component="div"
        sx={{
          borderRadius: 2,
          width: index === 5 ? "93%" : "80%",
          height: 40,
          margin: "auto",
        }}
      />
    )}
  </>
);

export const Board = () => {
  const navigate = useNavigate();
  const user = Parse.User.current();
  const { platform, movement } = useParams();
  const dataProvider = useDataProvider();
  const [round, setRound] = React.useState("1");
  const [ldecisions, setLDecisions] = React.useState({});
  const [rdecisions, setrDecisions] = React.useState({});
  const [cdecisions, setCDecisions] = React.useState({});
  const { data: competition } = useGetOne("competitions", {
    id: localStorage.getItem("currentCompetition"),
  });
  const {
    data: lifters,
    total,
    isLoading,
    error,
  } = useGetList("lifters", {
    filter: {
      platform,
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "surename",
      order: "ASC",
    },
  });

  const [refreshedLifters, setRefreshedLifters] = React.useState(
    lifters || null,
  );

  const {
    data: teams,
    total2,
    isLoading2,
    error2,
  } = useGetList("teams", {
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "name",
      order: "ASC",
    },
  });

  const {
    data: record,
    isLoading3,
    error3,
  } = useGetOne("platforms", {
    id: platform,
  });
  const [refreshedPlatform, setRefreshedPlatform] = React.useState(
    record || null,
  );

  const { data: MensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "m",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const { data: WomensWc } = useGetList("weight_class", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      sex: "f",
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "idString",
      order: "ASC",
    },
  });

  const livequery = async () => {
    // const self = this;
    // alert("entro a las subscripción")
    if (!refreshedPlatform && platform) {
      const { data: recordT } = await dataProvider.getOne("platforms", {
        id: platform,
      });
      setRefreshedPlatform(recordT);
    }

    const query = new Parse.Query("platforms");
    query.equalTo("objectId", platform);
    const subscription = await query.subscribe();
    subscription.on("update", async (object) => {
      console.log("Entro a update de la primera subscripción");
      setRound(object.get("round"));
      setRefreshedPlatform(object.toJSON());
    });

    const queryLifters = new Parse.Query("lifters");
    queryLifters.equalTo("platform", platform);
    const subscriptionLifers = await queryLifters.subscribe();
    subscriptionLifers.on("update", async (object) => {
      //Obtengo al current
      const { data: liftersT } = await dataProvider.getList("lifters", {
        filter: {
          platform,
        },
        pagination: {
          page: 1,
          perPage: 10000,
        },
        sort: {
          field: "surename",
          order: "ASC",
        },
      });
      setRefreshedLifters(liftersT);
    });
  };

  React.useEffect(() => {
    if (platform) {
      livequery();
    }
  }, [platform]);

  React.useEffect(() => {
    if (!isLoading && lifters.length && !refreshedLifters)
      setRefreshedLifters(lifters);
  }, [isLoading]);

  React.useEffect(() => {
    if (refreshedPlatform) setRound(refreshedPlatform.round);
  }, [refreshedPlatform]);

  if (
    isLoading ||
    isLoading2 ||
    isLoading3 ||
    !refreshedPlatform ||
    !competition ||
    !MensWc ||
    !WomensWc
  ) {
    return <Loading />;
  }
  if (error || error2 || error3) {
    return <p>ERROR</p>;
  }

  return (
    <Card className="darkside">
      {/*<CardHeader title={`${record.name}`} />*/}
      <CardContent>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              height: "20vh",
              padding: "5px",
              borderRadius: "10px",
              // maxWidth: "900px",
            }}
          >
            {competition.pictures &&
              competition.pictures.map((item) => (
                <img align="center" height="80%" src={item.src} />
              ))}
          </div>
        </div>
        {refreshedPlatform && refreshedPlatform.current ? (
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "5px",
                }}
              >
                {refreshedPlatform.current.country &&
                refreshedPlatform.current.country
                  .toLowerCase()
                  .includes("hungary") ? (
                  <img
                    style={{ borderRadius: "5px" }}
                    src={`/hung.jpg`}
                    loading="lazy"
                    width="80"
                    alt=""
                  />
                ) : refreshedPlatform.current.country &&
                  refreshedPlatform.current.country
                    .toLowerCase()
                    .includes("ukraine") ? (
                  <img
                    style={{ borderRadius: "5px" }}
                    src={`/ucr.jpg`}
                    loading="lazy"
                    width="80"
                    alt=""
                  />
                ) : (
                  <img
                    style={{ borderRadius: "5px" }}
                    src={`/hellas.png`}
                    loading="lazy"
                    width="80"
                    alt=""
                  />
                )}{" "}
                <h3
                  style={{
                    margin: 0,
                    fontSize: "2vw",
                    marginLeft: "5px",
                  }}
                >
                  {refreshedPlatform &&
                    refreshedPlatform.current &&
                    refreshedPlatform.current.surename}{" "}
                  {refreshedPlatform &&
                    refreshedPlatform.current &&
                    refreshedPlatform.current.firstname}
                </h3>
              </div>
              <h3 style={{ margin: 0, fontSize: "2vw" }}>
                Att {" : "}
                {(refreshedPlatform.round && refreshedPlatform.round == 4) ||
                refreshedPlatform.round == 7
                  ? "1"
                  : (refreshedPlatform.round && refreshedPlatform.round == 5) ||
                      refreshedPlatform.round == 8
                    ? "2"
                    : (refreshedPlatform.round &&
                          refreshedPlatform.round == 6) ||
                        refreshedPlatform.round == 9
                      ? "3"
                      : refreshedPlatform.round}
                {" , "}
                {[...MensWc, ...WomensWc].find(
                  (item) => item.id === refreshedPlatform.current.weight_class,
                )
                  ? [...MensWc, ...WomensWc].find(
                      (item) =>
                        item.id === refreshedPlatform.current.weight_class,
                    ).name
                  : null}
                {" , "}
                {[...MensWc, ...WomensWc].find(
                  (item) => item.id === refreshedPlatform.current.weight_class,
                )
                  ? [...MensWc, ...WomensWc].find(
                      (item) =>
                        item.id === refreshedPlatform.current.weight_class,
                    ).name
                  : null}
              </h3>
              <h3>
                {refreshedPlatform &&
                refreshedPlatform.current &&
                refreshedPlatform.current.team &&
                teams &&
                teams.length &&
                teams.find((item) => item.id === refreshedPlatform.current.team)
                  ? teams.find(
                      (item) => item.id === refreshedPlatform.current.team,
                    ).name
                  : null}
              </h3>
              {refreshedPlatform &&
                refreshedPlatform.current &&
                refreshedPlatform.current.country &&
                countries.find(
                  (ctry) => ctry.code === refreshedPlatform.current.country,
                ) &&
                countries.find(
                  (ctry) => ctry.code === refreshedPlatform.current.country,
                ).flag && (
                  <img
                    src={`/flags/${countries.find((ctry) => ctry.code === refreshedPlatform.current.country).flag}`}
                    loading="lazy"
                    width="40"
                    // src={`https://flagcdn.com/w20/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png`}
                    // srcSet={`https://flagcdn.com/w40/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png 2x`}
                    alt=""
                  />
                )}
              <h3 style={{ margin: 0, fontSize: 20 }}>
                {refreshedPlatform.current.country}
              </h3>
              {refreshedPlatform && (
                <div
                  style={{
                    display: "flex",
                    border: "1px solid grey",
                    padding: 5,
                    borderRadius: 8,
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12} style={{ marginBottom: "5px" }}>
                      <Decision referee={refreshedPlatform.lr} index={5} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={12} style={{ marginBottom: "5px" }}>
                      <Decision referee={refreshedPlatform.hr} index={5} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={12} style={{ marginBottom: "5px" }}>
                      <Decision referee={refreshedPlatform.rr} index={5} />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={7}
              style={{
                display: "flex",
                textAlign: "right",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ display: "block", marginRight: 5 }}>
                <span style={{ margin: 0, fontSize: "4vw", color: "white" }}>
                  {refreshedLifters && round === "1"
                    ? refreshedLifters.find(
                        (lifter) => lifter.id === refreshedPlatform.current.id,
                      )["squat_first_attempt"]
                    : refreshedLifters && round === "2"
                      ? refreshedLifters.find(
                          (lifter) =>
                            lifter.id === refreshedPlatform.current.id,
                        )["squat_second_attempt"]
                      : refreshedLifters && round === "3"
                        ? refreshedLifters.find(
                            (lifter) =>
                              lifter.id === refreshedPlatform.current.id,
                          )["squat_third_attempt"]
                        : refreshedLifters && round === "4"
                          ? refreshedLifters.find(
                              (lifter) =>
                                lifter.id === refreshedPlatform.current.id,
                            )["bench_first_attempt"]
                          : refreshedLifters && round === "5"
                            ? refreshedLifters.find(
                                (lifter) =>
                                  lifter.id === refreshedPlatform.current.id,
                              )["bench_second_attempt"]
                            : refreshedLifters && round === "6"
                              ? refreshedLifters.find(
                                  (lifter) =>
                                    lifter.id === refreshedPlatform.current.id,
                                )["bench_third_attempt"]
                              : refreshedLifters && round === "7"
                                ? refreshedLifters.find(
                                    (lifter) =>
                                      lifter.id ===
                                      refreshedPlatform.current.id,
                                  )["deadlift_first_attempt"]
                                : refreshedLifters && round === "8"
                                  ? refreshedLifters.find(
                                      (lifter) =>
                                        lifter.id ===
                                        refreshedPlatform.current.id,
                                    )["deadlift_second_attempt"]
                                  : refreshedLifters && round === "9"
                                    ? refreshedLifters.find(
                                        (lifter) =>
                                          lifter.id ===
                                          refreshedPlatform.current.id,
                                      )["deadlift_third_attempt"]
                                    : null}{" "}
                  Kg
                </span>
              </div>
              <div style={{ display: "block", marginRight: 20 }}>
                {refreshedLifters &&
                  refreshedPlatform &&
                  refreshedPlatform.current.id && (
                    <>
                      <BarPlates
                        competition={competition}
                        refreshedPlatform={refreshedPlatform}
                        wheight={
                          round === "1"
                            ? refreshedLifters.find(
                                (lifter) =>
                                  lifter.id === refreshedPlatform.current.id,
                              )["squat_first_attempt"]
                            : round === "2"
                              ? refreshedLifters.find(
                                  (lifter) =>
                                    lifter.id === refreshedPlatform.current.id,
                                )["squat_second_attempt"]
                              : round === "3"
                                ? refreshedLifters.find(
                                    (lifter) =>
                                      lifter.id ===
                                      refreshedPlatform.current.id,
                                  )["squat_third_attempt"]
                                : round === "4"
                                  ? refreshedLifters.find(
                                      (lifter) =>
                                        lifter.id ===
                                        refreshedPlatform.current.id,
                                    )["bench_first_attempt"]
                                  : round === "5"
                                    ? refreshedLifters.find(
                                        (lifter) =>
                                          lifter.id ===
                                          refreshedPlatform.current.id,
                                      )["bench_second_attempt"]
                                    : round === "6"
                                      ? refreshedLifters.find(
                                          (lifter) =>
                                            lifter.id ===
                                            refreshedPlatform.current.id,
                                        )["bench_third_attempt"]
                                      : round === "7"
                                        ? refreshedLifters.find(
                                            (lifter) =>
                                              lifter.id ===
                                              refreshedPlatform.current.id,
                                          )["deadlift_first_attempt"]
                                        : round === "8"
                                          ? refreshedLifters.find(
                                              (lifter) =>
                                                lifter.id ===
                                                refreshedPlatform.current.id,
                                            )["deadlift_second_attempt"]
                                          : round === "9"
                                            ? refreshedLifters.find(
                                                (lifter) =>
                                                  lifter.id ===
                                                  refreshedPlatform.current.id,
                                              )["deadlift_third_attempt"]
                                            : null
                        }
                      />
                    </>
                  )}
              </div>
              <div style={{ display: "block" }}>
                {/*<h3 style={{fontSize: 60, margin: 0}}>Rack {refreshedPlatform && refreshedLifters && refreshedLifters.find(lifter => lifter.id === refreshedPlatform.current.id)["rack_height"]}</h3>*/}
                <PomSubsView refreshedPlatform={refreshedPlatform} />
              </div>
            </Grid>
          </Grid>
        ) : null}

        {/*<TableContainer component={Paper}>*/}
        <Table
          width="100%"
          sx={{ minWidth: 650, color: "#ffffff" }}
          size="small"
          aria-label="simple table"
          className="boardTable"
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  border: "solid",
                  borderWidth: 1,
                  color: "white",
                  width: 50,
                }}
                align="right"
              >
                Lot
              </TableCell>
              <TableCell
                style={{
                  border: "solid",
                  borderWidth: 1,
                  color: "white",
                  width: 200,
                }}
              >
                Name
              </TableCell>
              <TableCell
                style={{
                  border: "solid",
                  borderWidth: 1,
                  color: "white",
                  width: 60,
                  fontSize: 12,
                }}
                align="right"
              >
                Country
              </TableCell>
              {/* <TableCell */}
              {/*   style={{ border: "solid", borderWidth: 1, color: "white" }} */}
              {/*   align="right" */}
              {/* > */}
              {/*   Team */}
              {/* </TableCell> */}
              <TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
              >
                BWC
              </TableCell>
              <TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
              >
                BW
              </TableCell>
              {/* <TableCell */}
              {/*   style={{ */}
              {/*     border: "solid", */}
              {/*     borderWidth: 1, */}
              {/*     color: "white", */}
              {/*     width: 60, */}
              {/*     fontSize: 10, */}
              {/*   }} */}
              {/*   align="right" */}
              {/* > */}
              {/*   Rack Height */}
              {/* </TableCell> */}
              {!movement ||
                (movement === "squat" && (
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, color: "white" }}
                    align="center"
                    colSpan={3}
                  >
                    Squat
                  </TableCell>
                ))}
              {!movement ||
                (movement === "squat" && (
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, color: "white" }}
                    align="right"
                  >
                    Best Squat
                  </TableCell>
                ))}
              {!movement ||
                (movement === "bench" && (
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, color: "white" }}
                    align="right"
                    colSpan={3}
                  >
                    Bench Press
                  </TableCell>
                ))}
              {!movement ||
                (movement === "bench" && (
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, color: "white" }}
                    align="right"
                  >
                    Best Bench
                  </TableCell>
                ))}
              {!movement ||
                (movement === "deadlift" && (
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, color: "white" }}
                    align="right"
                    colSpan={3}
                  >
                    Deadlift
                  </TableCell>
                ))}
              {!movement ||
                (movement === "deadlift" && (
                  <TableCell
                    style={{ border: "solid", borderWidth: 1, color: "white" }}
                    align="right"
                  >
                    Best Deadlift
                  </TableCell>
                ))}
              {/*<TableCell style={{border: 'solid',borderWidth: 1, color: 'white'}} align="right">Total</TableCell>*/}
              <TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
                align="right"
              >
                Wilks
              </TableCell>
              <TableCell
                style={{ border: "solid", borderWidth: 1, color: "white" }}
                align="right"
              >
                Rank
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {refreshedLifters &&
              refreshedLifters
                .sort((a, b) => {
                  const attempt =
                    round === "1"
                      ? "squat_first_attempt"
                      : round === "2"
                        ? "squat_second_attempt"
                        : round === "3"
                          ? "squat_third_attempt"
                          : round === "4"
                            ? "bench_first_attempt"
                            : round === "5"
                              ? "bench_second_attempt"
                              : round === "6"
                                ? "bench_third_attempt"
                                : round === "7"
                                  ? "dead_first_attempt"
                                  : round === "8"
                                    ? "dead_second_attempt"
                                    : round === "9"
                                      ? "dead_third_attempt"
                                      : "";

                  if (a[attempt] === b[attempt]) {
                    return parseInt(b.lot_number) < parseInt(a.lot_number)
                      ? -1
                      : 1;
                  } else {
                    return a[attempt] < b[attempt] ? -1 : 1;
                  }
                })
                .map((row, index) => {
                  let rank = "-";
                  return {
                    ...row,
                    bestSquat:
                      refreshedPlatform &&
                      refreshedPlatform.squat_third_attempt_results &&
                      refreshedPlatform.squat_third_attempt_results[row.id]
                        ? row.squat_third_attempt
                        : refreshedPlatform.squat_second_attempt_results &&
                            refreshedPlatform.squat_second_attempt_results[
                              row.id
                            ]
                          ? row.squat_second_attempt
                          : refreshedPlatform.squat_first_attempt_results &&
                              refreshedPlatform.squat_first_attempt_results[
                                row.id
                              ]
                            ? row.squat_first_attempt
                            : null,
                    bestBench:
                      refreshedPlatform &&
                      refreshedPlatform.bench_third_attempt_results &&
                      refreshedPlatform.bench_third_attempt_results[row.id]
                        ? row.bench_third_attempt
                        : refreshedPlatform.bench_second_attempt_results &&
                            refreshedPlatform.bench_second_attempt_results[
                              row.id
                            ]
                          ? row.bench_second_attempt
                          : refreshedPlatform.bench_first_attempt_results &&
                              refreshedPlatform.bench_first_attempt_results[
                                row.id
                              ]
                            ? row.bench_first_attempt
                            : null,
                    bestDeadlift:
                      refreshedPlatform &&
                      refreshedPlatform.deadlift_third_attempt_results &&
                      refreshedPlatform.deadlift_third_attempt_results[row.id]
                        ? row.deadlift_third_attempt
                        : refreshedPlatform.deadlift_second_attempt_results &&
                            refreshedPlatform.deadlift_second_attempt_results[
                              row.id
                            ]
                          ? row.deadlift_second_attempt
                          : refreshedPlatform.deadlift_first_attempt_results &&
                              refreshedPlatform.deadlift_first_attempt_results[
                                row.id
                              ]
                            ? row.deadlift_first_attempt
                            : null,
                    BWC: [...WomensWc, ...MensWc].find(
                      (item) => item.id === row.weight_class,
                    )
                      ? [...WomensWc, ...MensWc].find(
                          (item) => item.id === row.weight_class,
                        ).name
                      : row.weight_class,
                  };
                })
                .map((row, index, mainarr) => {
                  const nextIndex =
                    refreshedPlatform && refreshedPlatform.current
                      ? mainarr.findIndex(
                          (item) => item.id === refreshedPlatform.current.id,
                        )
                      : -1;
                  // console.log(nextIndex)
                  // console.log(mainarr)
                  const nextLifter =
                    nextIndex !== -1 && mainarr[nextIndex + 1]
                      ? mainarr[nextIndex + 1]
                      : null;

                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          color: "yellow",
                        }}
                        align="right"
                      >
                        {row.lot_number}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "solid",
                          borderWidth: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          color:
                            refreshedPlatform &&
                            refreshedPlatform.current &&
                            refreshedPlatform.current.id === row.id
                              ? "black"
                              : refreshedPlatform &&
                                  refreshedPlatform.current &&
                                  nextLifter &&
                                  nextLifter.id === row.id
                                ? "black"
                                : "yellow",
                        }}
                        component="th"
                        scope="row"
                        className={
                          refreshedPlatform &&
                          refreshedPlatform.current &&
                          refreshedPlatform.current.id === row.id
                            ? "black"
                            : refreshedPlatform &&
                                refreshedPlatform.current &&
                                nextLifter &&
                                nextLifter.id === row.id
                              ? "black"
                              : "yellow"
                        }
                        sx={{
                          fontSize: { xs: 10, md: 12 },
                          backgroundColor:
                            refreshedPlatform &&
                            refreshedPlatform.current &&
                            refreshedPlatform.current.id === row.id
                              ? "yellow"
                              : refreshedPlatform &&
                                  refreshedPlatform.current &&
                                  nextLifter &&
                                  nextLifter.id === row.id
                                ? "#00e3ff"
                                : "black",
                          color:
                            refreshedPlatform &&
                            refreshedPlatform.current &&
                            refreshedPlatform.current.id === row.id
                              ? "black"
                              : refreshedPlatform &&
                                  refreshedPlatform.current &&
                                  nextLifter &&
                                  nextLifter.id === row.id
                                ? "black"
                                : "yellow",
                        }}
                      >
                        {row.surename} {row.firstname}
                      </TableCell>
                      <TableCell
                        style={{ border: "solid", borderWidth: 1 }}
                        align="center"
                      >
                        {row.country &&
                        row.country.toLowerCase().includes("hungary") ? (
                          <img
                            src={`/hung.jpg`}
                            loading="lazy"
                            width="60"
                            alt=""
                          />
                        ) : row.country &&
                          row.country.toLowerCase().includes("ukraine") ? (
                          <img
                            src={`/ucr.jpg`}
                            loading="lazy"
                            width="60"
                            alt=""
                          />
                        ) : (
                          <img
                            src={`/hellas.png`}
                            loading="lazy"
                            width="60"
                            alt=""
                          />
                        )}
                        {/*countries.find((ctry) => ctry.code === row.country) &&
                        countries.find((ctry) => ctry.code === row.country)
                          .flag ? (
                          <img
                            src={`/flags/${countries.find((ctry) => ctry.code === row.country).flag}`}
                            loading="lazy"
                            width="30"
                            // src={`https://flagcdn.com/w20/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png`}
                            // srcSet={`https://flagcdn.com/w40/${countries.find(ctry => ctry.code === refreshedPlatform.current.country).flag.toLowerCase()}.png 2x`}
                            alt=""
                          />
                        ) : (
                          row.country
                        )*/}
                      </TableCell>
                      {/* <TableCell */}
                      {/*   sx={{ */}
                      {/*     border: "solid", */}
                      {/*     borderWidth: 1, */}
                      {/*     color: "yellow", */}
                      {/*     fontSize: { xs: 10, md: 14 }, */}
                      {/*   }} */}
                      {/* > */}
                      {/*   {teams && */}
                      {/*   teams.length && */}
                      {/*   teams.find((item) => item.id === row.team) */}
                      {/*     ? teams.find((item) => item.id === row.team).name */}
                      {/*     : null} */}
                      {/* </TableCell> */}
                      <TableCell
                        sx={{
                          border: "solid",
                          borderWidth: 1,
                          color: "yellow",
                          fontSize: { xs: 10, md: 14 },
                        }}
                        component="th"
                        scope="row"
                      >
                        {[...WomensWc, ...MensWc].find(
                          (item) => item.id === row.weight_class,
                        )
                          ? [...WomensWc, ...MensWc].find(
                              (item) => item.id === row.weight_class,
                            ).name
                          : row.weight_class}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "solid",
                          borderWidth: 1,
                          color: "yellow",
                        }}
                        component="th"
                        scope="row"
                      >
                        {row.bodyweight}
                      </TableCell>
                      {/* <TableCell */}
                      {/*   sx={{ */}
                      {/*     border: "solid", */}
                      {/*     borderWidth: 1, */}
                      {/*     color: "yellow", */}
                      {/*   }} */}
                      {/*   align="right" */}
                      {/* > */}
                      {/*   {row.rack_height} */}
                      {/* </TableCell> */}
                      {!movement ||
                        (movement === "squat" && (
                          <TableCell
                            sx={{
                              border: "solid",
                              borderWidth: 1,
                              fontSize: { xs: 12, md: 20 },
                              fontWeight: "bold",
                            }}
                            align="right"
                            className={
                              refreshedPlatform &&
                              refreshedPlatform.current &&
                              refreshedPlatform &&
                              refreshedPlatform.current.id === row.id &&
                              round === "1"
                                ? "black"
                                : refreshedPlatform &&
                                    (!refreshedPlatform.squat_third_attempt_results ||
                                      !refreshedPlatform
                                        .squat_third_attempt_results[row.id]) &&
                                    (!refreshedPlatform.squat_second_attempt_results ||
                                      !refreshedPlatform
                                        .squat_second_attempt_results[
                                        row.id
                                      ]) &&
                                    refreshedPlatform.squat_first_attempt_results &&
                                    refreshedPlatform
                                      .squat_first_attempt_results[row.id]
                                  ? "black"
                                  : "white"
                            }
                            sx={{
                              backgroundColor:
                                refreshedPlatform &&
                                refreshedPlatform.current &&
                                refreshedPlatform.current.id === row.id &&
                                round === "1"
                                  ? "yellow"
                                  : refreshedPlatform &&
                                      refreshedPlatform.current &&
                                      nextLifter &&
                                      nextLifter.id === row.id &&
                                      round === "1"
                                    ? "#00e3ff"
                                    : refreshedPlatform &&
                                        (!refreshedPlatform.squat_third_attempt_results ||
                                          !refreshedPlatform
                                            .squat_third_attempt_results[
                                            row.id
                                          ]) &&
                                        (!refreshedPlatform.squat_second_attempt_results ||
                                          !refreshedPlatform
                                            .squat_second_attempt_results[
                                            row.id
                                          ]) &&
                                        refreshedPlatform.squat_first_attempt_results &&
                                        refreshedPlatform
                                          .squat_first_attempt_results[row.id]
                                      ? "#008000"
                                      : "black",
                              color:
                                refreshedPlatform &&
                                refreshedPlatform.current &&
                                refreshedPlatform.squat_first_attempt_results &&
                                refreshedPlatform.squat_first_attempt_results[
                                  row.id
                                ] &&
                                refreshedPlatform.squat_first_attempt_results[
                                  row.id
                                ] === true
                                  ? "#008000"
                                  : refreshedPlatform.squat_first_attempt_results &&
                                      refreshedPlatform
                                        .squat_first_attempt_results[row.id] ===
                                        false
                                    ? "#CC0000"
                                    : "yellow",
                            }}
                          >
                            {row.squat_first_attempt}
                          </TableCell>
                        ))}
                      {!movement ||
                        (movement === "squat" && (
                          <TableCell
                            sx={{
                              border: "solid",
                              borderWidth: 1,
                              fontSize: { xs: 12, md: 20 },
                              fontWeight: "bold",
                            }}
                            align="right"
                            className={
                              refreshedPlatform &&
                              refreshedPlatform.current &&
                              refreshedPlatform &&
                              refreshedPlatform.current.id === row.id &&
                              round === "2"
                                ? "black"
                                : refreshedPlatform &&
                                    (!refreshedPlatform.squat_third_attempt_results ||
                                      !refreshedPlatform
                                        .squat_third_attempt_results[row.id]) &&
                                    refreshedPlatform.squat_second_attempt_results &&
                                    refreshedPlatform
                                      .squat_second_attempt_results[row.id]
                                  ? "black"
                                  : "white"
                            }
                            sx={{
                              backgroundColor:
                                refreshedPlatform &&
                                refreshedPlatform.current &&
                                refreshedPlatform.current.id === row.id &&
                                round === "2"
                                  ? "yellow"
                                  : refreshedPlatform &&
                                      refreshedPlatform.current &&
                                      nextLifter &&
                                      nextLifter.id === row.id &&
                                      round === "2"
                                    ? "#00e3ff"
                                    : refreshedPlatform &&
                                        (!refreshedPlatform.squat_third_attempt_results ||
                                          !refreshedPlatform
                                            .squat_third_attempt_results[
                                            row.id
                                          ]) &&
                                        refreshedPlatform.squat_second_attempt_results &&
                                        refreshedPlatform
                                          .squat_second_attempt_results[row.id]
                                      ? "#008000"
                                      : "black",
                              color:
                                refreshedPlatform &&
                                refreshedPlatform.squat_second_attempt_results &&
                                refreshedPlatform.squat_second_attempt_results[
                                  row.id
                                ] &&
                                refreshedPlatform.squat_second_attempt_results[
                                  row.id
                                ] === true
                                  ? "#008000"
                                  : refreshedPlatform.squat_second_attempt_results &&
                                      refreshedPlatform
                                        .squat_second_attempt_results[
                                        row.id
                                      ] === false
                                    ? "#CC0000"
                                    : "yellow",
                            }}
                          >
                            {row.squat_second_attempt}
                          </TableCell>
                        ))}
                      {!movement ||
                        (movement === "squat" && (
                          <TableCell
                            sx={{
                              border: "solid",
                              borderWidth: 1,
                              fontSize: { xs: 12, md: 20 },
                              fontWeight: "bold",
                            }}
                            align="right"
                            className={
                              refreshedPlatform &&
                              refreshedPlatform.current &&
                              refreshedPlatform &&
                              refreshedPlatform.current.id === row.id &&
                              round === "3"
                                ? "black"
                                : refreshedPlatform.squat_third_attempt_results &&
                                    refreshedPlatform
                                      .squat_third_attempt_results[row.id]
                                  ? "black"
                                  : "white"
                            }
                            sx={{
                              backgroundColor:
                                refreshedPlatform &&
                                refreshedPlatform.current &&
                                refreshedPlatform.current.id === row.id &&
                                round === "3"
                                  ? "yellow"
                                  : refreshedPlatform &&
                                      refreshedPlatform.current &&
                                      nextLifter &&
                                      nextLifter.id === row.id &&
                                      round === "3"
                                    ? "#00e3ff"
                                    : refreshedPlatform.squat_third_attempt_results &&
                                        refreshedPlatform
                                          .squat_third_attempt_results[row.id]
                                      ? "#008000"
                                      : "black",
                              color:
                                refreshedPlatform &&
                                refreshedPlatform.current &&
                                refreshedPlatform.squat_third_attempt_results &&
                                refreshedPlatform.squat_third_attempt_results[
                                  row.id
                                ] &&
                                refreshedPlatform.squat_third_attempt_results[
                                  row.id
                                ] === true
                                  ? "#008000"
                                  : refreshedPlatform.squat_third_attempt_results &&
                                      refreshedPlatform
                                        .squat_third_attempt_results[row.id] ===
                                        false
                                    ? "#CC0000"
                                    : "yellow",
                            }}
                          >
                            {row.squat_third_attempt}
                          </TableCell>
                        ))}
                      {!movement ||
                        (movement === "squat" && (
                          <TableCell
                            sx={{
                              border: "solid",
                              borderWidth: 1,
                              fontSize: { xs: 12, md: 20 },
                              fontWeight: "bold",
                              color: "yellow",
                            }}
                            align="right"
                          >
                            {row.bestSquat}
                          </TableCell>
                        ))}
                      {!movement ||
                        (movement === "bench" && (
                          <TableCell
                            sx={{
                              border: "solid",
                              borderWidth: 1,
                              fontSize: { xs: 12, md: 20 },
                              fontWeight: "bold",
                            }}
                            align="right"
                            className={
                              refreshedPlatform &&
                              refreshedPlatform.current &&
                              refreshedPlatform &&
                              refreshedPlatform.current.id === row.id &&
                              round === "4"
                                ? "black"
                                : refreshedPlatform &&
                                    (!refreshedPlatform.bench_third_attempt_results ||
                                      !refreshedPlatform
                                        .bench_third_attempt_results[row.id]) &&
                                    (!refreshedPlatform.bench_second_attempt_results ||
                                      !refreshedPlatform
                                        .bench_second_attempt_results[
                                        row.id
                                      ]) &&
                                    refreshedPlatform.bench_first_attempt_results &&
                                    refreshedPlatform
                                      .bench_first_attempt_results[row.id]
                                  ? "black"
                                  : "white"
                            }
                            sx={{
                              backgroundColor:
                                refreshedPlatform &&
                                refreshedPlatform.current &&
                                refreshedPlatform.current.id === row.id &&
                                round === "4"
                                  ? "yellow"
                                  : refreshedPlatform &&
                                      refreshedPlatform.current &&
                                      nextLifter &&
                                      nextLifter.id === row.id &&
                                      round === "4"
                                    ? "#00e3ff"
                                    : refreshedPlatform &&
                                        (!refreshedPlatform.bench_third_attempt_results ||
                                          !refreshedPlatform
                                            .bench_third_attempt_results[
                                            row.id
                                          ]) &&
                                        (!refreshedPlatform.bench_second_attempt_results ||
                                          !refreshedPlatform
                                            .bench_second_attempt_results[
                                            row.id
                                          ]) &&
                                        refreshedPlatform.bench_first_attempt_results &&
                                        refreshedPlatform
                                          .bench_first_attempt_results[row.id]
                                      ? "#008000"
                                      : "black",
                              color:
                                refreshedPlatform &&
                                refreshedPlatform.current &&
                                refreshedPlatform.bench_first_attempt_results &&
                                refreshedPlatform.bench_first_attempt_results[
                                  row.id
                                ] &&
                                refreshedPlatform.bench_first_attempt_results[
                                  row.id
                                ] === true
                                  ? "#008000"
                                  : refreshedPlatform.bench_first_attempt_results &&
                                      refreshedPlatform
                                        .bench_first_attempt_results[row.id] ===
                                        false
                                    ? "#CC0000"
                                    : "yellow",
                            }}
                          >
                            {row.bench_first_attempt}
                          </TableCell>
                        ))}
                      {!movement ||
                        (movement === "bench" && (
                          <TableCell
                            sx={{
                              border: "solid",
                              borderWidth: 1,
                              fontSize: { xs: 12, md: 20 },
                              fontWeight: "bold",
                            }}
                            align="right"
                            className={
                              refreshedPlatform &&
                              refreshedPlatform.current &&
                              refreshedPlatform &&
                              refreshedPlatform.current.id === row.id &&
                              round === "5"
                                ? "black"
                                : refreshedPlatform &&
                                    (!refreshedPlatform.bench_third_attempt_results ||
                                      !refreshedPlatform
                                        .bench_third_attempt_results[row.id]) &&
                                    refreshedPlatform.bench_second_attempt_results &&
                                    refreshedPlatform
                                      .bench_second_attempt_results[row.id]
                                  ? "black"
                                  : "white"
                            }
                            sx={{
                              backgroundColor:
                                refreshedPlatform &&
                                refreshedPlatform.current &&
                                refreshedPlatform.current.id === row.id &&
                                round === "5"
                                  ? "yellow"
                                  : refreshedPlatform &&
                                      refreshedPlatform.current &&
                                      nextLifter &&
                                      nextLifter.id === row.id &&
                                      round === "5"
                                    ? "#00e3ff"
                                    : refreshedPlatform &&
                                        (!refreshedPlatform.bench_third_attempt_results ||
                                          !refreshedPlatform
                                            .bench_third_attempt_results[
                                            row.id
                                          ]) &&
                                        refreshedPlatform.bench_second_attempt_results &&
                                        refreshedPlatform
                                          .bench_second_attempt_results[row.id]
                                      ? "#008000"
                                      : "black",
                              color:
                                refreshedPlatform &&
                                refreshedPlatform.bench_second_attempt_results &&
                                refreshedPlatform.bench_second_attempt_results[
                                  row.id
                                ] &&
                                refreshedPlatform.bench_second_attempt_results[
                                  row.id
                                ] === true
                                  ? "#008000"
                                  : refreshedPlatform.bench_second_attempt_results &&
                                      refreshedPlatform
                                        .bench_second_attempt_results[
                                        row.id
                                      ] === false
                                    ? "#CC0000"
                                    : "yellow",
                            }}
                          >
                            {row.bench_second_attempt}
                          </TableCell>
                        ))}
                      {!movement ||
                        (movement === "bench" && (
                          <TableCell
                            sx={{
                              border: "solid",
                              borderWidth: 1,
                              fontSize: { xs: 12, md: 20 },
                              fontWeight: "bold",
                            }}
                            align="right"
                            className={
                              refreshedPlatform &&
                              refreshedPlatform.current &&
                              refreshedPlatform &&
                              refreshedPlatform.current.id === row.id &&
                              round === "6"
                                ? "black"
                                : refreshedPlatform.bench_third_attempt_results &&
                                    refreshedPlatform
                                      .bench_third_attempt_results[row.id]
                                  ? "black"
                                  : "white"
                            }
                            sx={{
                              backgroundColor:
                                refreshedPlatform &&
                                refreshedPlatform.current &&
                                refreshedPlatform.current.id === row.id &&
                                round === "6"
                                  ? "yellow"
                                  : refreshedPlatform &&
                                      refreshedPlatform.current &&
                                      nextLifter &&
                                      nextLifter.id === row.id &&
                                      round === "6"
                                    ? "#00e3ff"
                                    : refreshedPlatform.bench_third_attempt_results &&
                                        refreshedPlatform
                                          .bench_third_attempt_results[row.id]
                                      ? "#008000"
                                      : "black",
                              color:
                                refreshedPlatform &&
                                refreshedPlatform.current &&
                                refreshedPlatform.bench_third_attempt_results &&
                                refreshedPlatform.bench_third_attempt_results[
                                  row.id
                                ] &&
                                refreshedPlatform.bench_third_attempt_results[
                                  row.id
                                ] === true
                                  ? "#008000"
                                  : refreshedPlatform.bench_third_attempt_results &&
                                      refreshedPlatform
                                        .bench_third_attempt_results[row.id] ===
                                        false
                                    ? "#CC0000"
                                    : "yellow",
                            }}
                          >
                            {row.bench_third_attempt}
                          </TableCell>
                        ))}
                      {!movement ||
                        (movement === "bench" && (
                          <TableCell
                            sx={{
                              border: "solid",
                              borderWidth: 1,
                              fontSize: { xs: 12, md: 20 },
                              fontWeight: "bold",
                              color: "yellow",
                            }}
                            align="right"
                          >
                            {row.bestBench}
                          </TableCell>
                        ))}
                      {!movement ||
                        (movement === "deadlift" && (
                          <TableCell
                            sx={{
                              border: "solid",
                              borderWidth: 1,
                              fontSize: { xs: 12, md: 20 },
                              fontWeight: "bold",
                            }}
                            align="right"
                            className={
                              refreshedPlatform &&
                              refreshedPlatform.current &&
                              refreshedPlatform &&
                              refreshedPlatform.current.id === row.id &&
                              round === "7"
                                ? "black"
                                : refreshedPlatform &&
                                    (!refreshedPlatform.deadlift_third_attempt_results ||
                                      !refreshedPlatform
                                        .deadlift_third_attempt_results[
                                        row.id
                                      ]) &&
                                    (!refreshedPlatform.deadlift_second_attempt_results ||
                                      !refreshedPlatform
                                        .deadlift_second_attempt_results[
                                        row.id
                                      ]) &&
                                    refreshedPlatform.deadlift_first_attempt_results &&
                                    refreshedPlatform
                                      .deadlift_first_attempt_results[row.id]
                                  ? "black"
                                  : "white"
                            }
                            sx={{
                              backgroundColor:
                                refreshedPlatform &&
                                refreshedPlatform.current &&
                                refreshedPlatform.current.id === row.id &&
                                round === "7"
                                  ? "yellow"
                                  : refreshedPlatform &&
                                      refreshedPlatform.current &&
                                      nextLifter &&
                                      nextLifter.id === row.id &&
                                      round === "7"
                                    ? "#00e3ff"
                                    : refreshedPlatform &&
                                        (!refreshedPlatform.deadlift_third_attempt_results ||
                                          !refreshedPlatform
                                            .deadlift_third_attempt_results[
                                            row.id
                                          ]) &&
                                        (!refreshedPlatform.deadlift_second_attempt_results ||
                                          !refreshedPlatform
                                            .deadlift_second_attempt_results[
                                            row.id
                                          ]) &&
                                        refreshedPlatform.deadlift_first_attempt_results &&
                                        refreshedPlatform
                                          .deadlift_first_attempt_results[
                                          row.id
                                        ]
                                      ? "#008000"
                                      : "black",
                              color:
                                refreshedPlatform &&
                                refreshedPlatform.current &&
                                refreshedPlatform.deadlift_first_attempt_results &&
                                refreshedPlatform
                                  .deadlift_first_attempt_results[row.id] &&
                                refreshedPlatform
                                  .deadlift_first_attempt_results[row.id] ===
                                  true
                                  ? "#008000"
                                  : refreshedPlatform.deadlift_first_attempt_results &&
                                      refreshedPlatform
                                        .deadlift_first_attempt_results[
                                        row.id
                                      ] === false
                                    ? "#CC0000"
                                    : "yellow",
                            }}
                          >
                            {row.deadlift_first_attempt}
                          </TableCell>
                        ))}
                      {!movement ||
                        (movement === "deadlift" && (
                          <TableCell
                            sx={{
                              border: "solid",
                              borderWidth: 1,
                              fontSize: { xs: 12, md: 20 },
                              fontWeight: "bold",
                            }}
                            align="right"
                            className={
                              refreshedPlatform &&
                              refreshedPlatform.current &&
                              refreshedPlatform &&
                              refreshedPlatform.current.id === row.id &&
                              round === "8"
                                ? "black"
                                : refreshedPlatform &&
                                    (!refreshedPlatform.deadlift_third_attempt_results ||
                                      !refreshedPlatform
                                        .deadlift_third_attempt_results[
                                        row.id
                                      ]) &&
                                    refreshedPlatform.deadlift_second_attempt_results &&
                                    refreshedPlatform
                                      .deadlift_second_attempt_results[row.id]
                                  ? "black"
                                  : "white"
                            }
                            sx={{
                              backgroundColor:
                                refreshedPlatform &&
                                refreshedPlatform.current &&
                                refreshedPlatform.current.id === row.id &&
                                round === "8"
                                  ? "yellow"
                                  : refreshedPlatform &&
                                      refreshedPlatform.current &&
                                      nextLifter &&
                                      nextLifter.id === row.id &&
                                      round === "8"
                                    ? "#00e3ff"
                                    : refreshedPlatform &&
                                        (!refreshedPlatform.deadlift_third_attempt_results ||
                                          !refreshedPlatform
                                            .deadlift_third_attempt_results[
                                            row.id
                                          ]) &&
                                        refreshedPlatform.deadlift_second_attempt_results &&
                                        refreshedPlatform
                                          .deadlift_second_attempt_results[
                                          row.id
                                        ]
                                      ? "#008000"
                                      : "black",
                              color:
                                refreshedPlatform &&
                                refreshedPlatform.deadlift_second_attempt_results &&
                                refreshedPlatform
                                  .deadlift_second_attempt_results[row.id] &&
                                refreshedPlatform
                                  .deadlift_second_attempt_results[row.id] ===
                                  true
                                  ? "#008000"
                                  : refreshedPlatform.deadlift_second_attempt_results &&
                                      refreshedPlatform
                                        .deadlift_second_attempt_results[
                                        row.id
                                      ] === false
                                    ? "#CC0000"
                                    : "yellow",
                            }}
                          >
                            {row.deadlift_second_attempt}
                          </TableCell>
                        ))}
                      {!movement ||
                        (movement === "deadlift" && (
                          <TableCell
                            sx={{
                              border: "solid",
                              borderWidth: 1,
                              fontSize: { xs: 12, md: 20 },
                              fontWeight: "bold",
                            }}
                            align="right"
                            className={
                              refreshedPlatform &&
                              refreshedPlatform.current &&
                              refreshedPlatform &&
                              refreshedPlatform.current.id === row.id &&
                              round === "9"
                                ? "black"
                                : refreshedPlatform.deadlift_third_attempt_results &&
                                    refreshedPlatform
                                      .deadlift_third_attempt_results[row.id]
                                  ? "black"
                                  : "white"
                            }
                            sx={{
                              backgroundColor:
                                refreshedPlatform &&
                                refreshedPlatform.current &&
                                refreshedPlatform.current.id === row.id &&
                                round === "9"
                                  ? "yellow"
                                  : refreshedPlatform &&
                                      refreshedPlatform.current &&
                                      nextLifter &&
                                      nextLifter.id === row.id &&
                                      round === "9"
                                    ? "#00e3ff"
                                    : refreshedPlatform.deadlift_third_attempt_results &&
                                        refreshedPlatform
                                          .deadlift_third_attempt_results[
                                          row.id
                                        ]
                                      ? "#008000"
                                      : "black",
                              color:
                                refreshedPlatform &&
                                refreshedPlatform.current &&
                                refreshedPlatform.deadlift_third_attempt_results &&
                                refreshedPlatform
                                  .deadlift_third_attempt_results[row.id] &&
                                refreshedPlatform
                                  .deadlift_third_attempt_results[row.id] ===
                                  true
                                  ? "#008000"
                                  : refreshedPlatform.deadlift_third_attempt_results &&
                                      refreshedPlatform
                                        .deadlift_third_attempt_results[
                                        row.id
                                      ] === false
                                    ? "#CC0000"
                                    : "yellow",
                            }}
                          >
                            {row.deadlift_third_attempt}
                          </TableCell>
                        ))}
                      {!movement ||
                        (movement === "deadlift" && (
                          <TableCell
                            sx={{
                              border: "solid",
                              borderWidth: 1,
                              fontSize: { xs: 12, md: 20 },
                              fontWeight: "bold",
                              color: "yellow",
                            }}
                            align="right"
                          >
                            {row.bestDeadlift}
                          </TableCell>
                        ))}
                      <TableCell
                        sx={{
                          border: "solid",
                          borderWidth: 1,
                          fontSize: { xs: 12, md: 14 },
                          color: "yellow",
                        }}
                        align="right"
                      >
                        {
                          // pDots(row.best, row.sex, row.bodyweight) calculateWilksScore(
                          //   row.sex,
                          //   row.bodyweight,
                          //   row.best,
                          // )
                          wilks2020(
                            row.bodyweight,
                            round < 4
                              ? row.bestSquat
                              : round > 3 && row < 7
                                ? row.bestBench
                                : row.bestDeadlift,
                            row.sex === "m" ? "male" : "female",
                          )
                        }
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "solid",
                          borderWidth: 1,
                          fontSize: { xs: 16, md: 20 },
                          fontWeight: "bold",
                          color: "yellow",
                        }}
                        align="right"
                      >
                        {getRank(
                          row,
                          mainarr.filter((lifter) => lifter.BWC === row.BWC),
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            <TableRow></TableRow>
          </TableBody>
        </Table>
        {/*</TableContainer>*/}
      </CardContent>
    </Card>
  );
};

function getRank(meIam, lifters) {
  lifters.sort((a, b) => {
    return a.best > b.best ? -1 : 1;
  });

  return lifters.map((lifter) => lifter.id).indexOf(meIam.id) + 1;
}

const maleValues = [
  -216.0475144, 16.2606339, -0.002388645, -0.00113732, 7.01863e-6, -1.291e-8,
];

const femaleValues = [
  594.31747775582, -27.23842536447, 0.82112226871, -0.00930733913, 4.731582e-5,
  -9.054e-8,
];

const imperial = 2.20462262185;

function calculateWilksScore(
  gender,
  bodyWeight,
  liftedWeight,
  unitType = "metric",
) {
  // if (!gender || !bodyWeight || !liftedWeight) {
  //   console.log('Missing parameters, please fill in gender, body weight and weight.');
  // }

  if (unitType === "imperial") {
    liftedWeight /= imperial;
    bodyWeight /= imperial;
  }

  validateInput({
    gender: gender,
    bodyWeight: parseFloat(bodyWeight),
    liftedWeight: liftedWeight,
    unitType: unitType,
  });

  let coeff = 500 / calculateCoefficient(gender, bodyWeight);

  return (liftedWeight * coeff).toFixed(3);
}

function calculateCoefficient(gender, bodyWeight) {
  let coeff = 0;
  let values = gender === "m" ? maleValues : femaleValues;

  for (let i = 0; i <= 5; i++) {
    coeff += i === 0 ? values[i] : values[i] * bodyWeight ** i;
  }

  return coeff;
}

function validateInput({
  gender,
  bodyWeight = 0,
  liftedWeight = 0,
  wilksScore = 0,
  unitType,
}) {
  if (typeof gender !== "string" || (gender !== "m" && gender !== "f")) {
    console.log(
      "Gender is not valid. Please select m for Male or f for Female.",
    );
  }

  if (typeof bodyWeight !== "number" || bodyWeight < 0) {
    console.log("Body weight is not valid.");
  }

  if (typeof liftedWeight !== "number" || liftedWeight < 0) {
    console.log("Weight is not valid.");
  }

  if (typeof wilksScore !== "number" || wilksScore < 0) {
    console.log("Wilks score is not valid.");
  }

  if (
    typeof unitType !== "string" ||
    (unitType !== "metric" && unitType !== "imperial")
  ) {
    console.log("Unit type is not valid. Please select metric or imperial.");
  }
}

function pDots(best, sex, bw) {
  var inputLabel = "Best Lift",
    inputUnit = "kg",
    outputLabel = "Score",
    outputUnit = "pts",
    steps = 1;
  var a,
    b,
    c,
    d,
    e,
    bwsum,
    result,
    total = 0.0;
  var params = {
    1: {
      A: -7.93954283e-7,
      B: 0.000493457474,
      C: -0.1231642956,
      D: 16.0233664,
      E: 45.59224,
    },
    2: {
      A: -2.55986906e-6,
      B: 0.00116119212,
      C: -0.205352889,
      D: 17.3690866,
      E: 55.4261,
    },
  };

  var gender = sex === "m" || sex === "M" || sex === "male" ? 1 : 2;
  a = params[gender].A;
  b = params[gender].B;
  c = params[gender].C;
  d = params[gender].D;
  e = params[gender].E;

  const value = best;
  bwsum =
    a * Math.pow(bw, 4) +
    b * Math.pow(bw, 3) +
    c * Math.pow(bw, 2) +
    d * bw +
    e;
  var exactResult = (value * 500) / bwsum;
  result = Math.round(exactResult * 1000) / 1000;

  if (!isNaN(result) && result !== Infinity) {
    total += result.toFixed(3);
  }

  var totalResult = Math.round(total * 1000) / 1000;

  return `${totalResult.toFixed(3)}`;
}
