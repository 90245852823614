import * as React from "react";
import {
    useCreate,
    useCreateSuggestionContext,
    TextInput,
    PasswordInput,
    Button,
    Form,
} from "react-admin";
import {
    // Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box,
} from "@mui/material";

const CreateButton = (props) => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [create] = useCreate();
    const [value, setValue] = React.useState(filter || "");

    const handleSubmit = (event) => {
        // event.preventDefault();
        // alert(JSON.stringify(event))
        create(
            props.collection,
            {
                data: {
                    name: event.name,
                    competition: localStorage.getItem("currentCompetition"),
                },
            },
            {
                onSuccess: (data) => {
                    setValue("");
                    onCreate(data);
                },
            },
        );
    };

    const handleSaveClick = async () => {
        const oFormObject = document.getElementById("QTituloQuickCreateForm");
        console.log(oFormObject);
        const data = new FormData(oFormObject);
        const form = Array.from(data.entries());
        console.log(form);
        const values = {};
        for (const [name, value] of form) {
            console.log({ name, value });
            /*
            {name: "title", value: "a"}
            {name: "text", value: "b"}
            {name: "email", value: "c"}
            */
            values[name] = value;
        }

        values.competition = localStorage.getItem("currentCompetition");

        create(
            props.collection,
            { data: values },
            {
                onSuccess: (data) => {
                    setValue("");
                    onCreate(data);
                },
            },
        );

        // return;

        // dataProvider.create('User', { data: values })
        //     .then(({ data }) => {
        //         // setShowDialog(false)
        //         // window.location.reload();
        //     })
        //     .catch(error => {
        //         // showNotification(error.message, 'error');
        //         // console.log(error)
        //     })
    };

    return (
        <Dialog open onClose={onCancel} maxWidth="xs" fullWidth>
            {/*<form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        fullWidth
                        label="Nombre de la nueva Categoría"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Guardar</Button>
                    <Button onClick={onCancel}>Cancelar</Button>
                </DialogActions>
            </form>*/}
            <DialogTitle>
                {props.collection && props.collection.includes("user")
                    ? `This user will have admin permissions on this competition`
                    : `Create new`}
            </DialogTitle>
            <DialogContent>
                <Form
                    name="QTituloQuickCreateForm"
                    id="QTituloQuickCreateForm"
                    onSubmit={handleSubmit}
                >
                    <Box
                        style={{ width: "100%" }}
                        mt={{ xs: "0.5em", sm: "0.5em" }}
                        fullWidth={true}
                        display={{ xs: "block", sm: "flex" }}
                    >
                        <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                            <TextInput
                                fullWidth
                                source="name"
                                defaultValue={value}
                            />
                        </Box>
                    </Box>
                    {/*<Box style={{width: "100%"}} mt={{ xs: '0.5em', sm: '0.5em' }} fullWidth={true} display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1}  mr={{ xs: 0, sm: '0.5em' }} >
                            <TextInput fullWidth source="username" label="email" />
                        </Box>
                        <Box flex={1}  ml={{ xs: 0, sm: '0.5em' }} >
                            <PasswordInput fullWidth source="password" />
                        </Box>
                    </Box>*/}
                    {/*<SubmitButton>Guardar</SubmitButton>*/}
                </Form>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleSaveClick}
                    label="Save"
                />
                <Button label="ra.action.cancel" onClick={onCancel} />
            </DialogActions>
        </Dialog>
    );
};

export default CreateButton;
