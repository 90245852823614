import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  Edit,
  SimpleForm,
  ReferenceInput,
  TextInput,
  Create,
  useRecordContext,
  Show,
  SimpleShowLayout,
  useUpdate,
  useDataProvider,
  useUpdateMany,
  useGetList,
  SelectInput,
  DeleteButton,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const RunField = () => {
  const record = useRecordContext();
  return (
    <a href={`#/run/${record.id}`} target="_blank">
      Run
    </a>
  );
};

const BoardField = () => {
  const record = useRecordContext();
  return (
    <a href={`#/board/${record.id}`} target="_blank">
      Open Scoreboard
    </a>
  );
};

const DisplayField = () => {
  const record = useRecordContext();
  return (
    <a href={`#/display/${record.id}`} target="_blank">
      Open attempt board
    </a>
  );
};

const RefereeField = () => {
  const record = useRecordContext();
  return (
    <div>
      <a href={`#/creferee/${record.id}`} target="_blank">
        Chief Referee
      </a>
      <br />
      <a href={`#/lreferee/${record.id}`} target="_blank">
        Left Referee
      </a>
      <br />
      <a href={`#/rreferee/${record.id}`} target="_blank">
        Right Referee
      </a>
    </div>
  );
};

const postFilters = [<TextInput label="Session Name" source="name" />];

export const PostList = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!localStorage.getItem("currentCompetition")) {
      alert("You must click on the competition you want to enter");
      navigate("/");
    }
  }, []);
  return (
    <List
      sort={{ field: "name", order: "ASC" }}
      perPage={1000}
      exporter={false}
      filters={postFilters}
      filter={{
        competition: localStorage.getItem("currentCompetition")
          ? localStorage.getItem("currentCompetition")
          : null,
      }}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="expand"
        expand={<PostShow />}
      >
        <TextField source="name" />
        {/*<RunField source="run" label="Run" />
        <BoardField source="board" label="Scoreboard"/>
        <DisplayField source="Attempt Board" />
        <RefereeField source="display" label="Referee Devices"/>*/}
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const PostShow = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const {
    data: lifters,
    total,
    isLoading,
    error,
  } = useGetList("lifters", {
    filter: {
      competition: localStorage.getItem("currentCompetition")
        ? localStorage.getItem("currentCompetition")
        : null,
      platform: record.id,
    },
    pagination: {
      page: 1,
      perPage: 10000,
    },
    sort: {
      field: "surename",
      order: "ASC",
    },
  });
  const selectedIds = lifters ? lifters.map((l) => l.id) : [];
  const [updateMany, { isLoading2, error2 }] = useUpdateMany("lifters", {
    ids: selectedIds,
    data: {
      first_attempt: null,
      second_attempt: null,
      third_attempt: null,
    },
  });
  const productionUrl = "https://wpp.freiesland.com/";
  const [update, { isLoading3, error3 }] = useUpdate("platforms", {
    id: record.id,
    data: {
      first_attempt_results: {},
      second_attempt_results: {},
      third_attempt_results: {},
      current: {},
      first_attempt_records: {},
      second_attempt_records: {},
      third_attempt_records: {},
    },
    previousData: record,
  });

  if (isLoading || isLoading2 || isLoading3) {
    return <div>Loading...</div>;
  }

  if (error || error2 || error3) {
    return <div>{error.message}</div>;
  }

  return (
    <div>
      <h3>Run the session on another window:</h3>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        href={`#/run/${record.id}`}
        target="_blank"
        startIcon={<OpenInNewIcon />}
      >
        Run
      </Button>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          navigator.clipboard
            .writeText(`${productionUrl}#/run/${record.id}`)
            .then(() => {
              alert(`Coppied`);
            })
            .catch(() => {
              alert("something went wrong");
            });
          // }
        }}
        startIcon={<ContentCopyIcon />}
      >
        Copy the run link
      </Button>
      <br />
      <h3>Scoreboard of this session:</h3>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        href={`#/board/${record.id}/squat`}
        target="_blank"
        startIcon={<OpenInNewIcon />}
      >
        Open Squat Scoreboard
      </Button>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        href={`#/board/${record.id}/bench`}
        target="_blank"
        startIcon={<OpenInNewIcon />}
      >
        Open Bench Scoreboard
      </Button>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        href={`#/board/${record.id}/deadlift`}
        target="_blank"
        startIcon={<OpenInNewIcon />}
      >
        Open Deadlift Scoreboard
      </Button>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        href={`#/board/${record.id}`}
        target="_blank"
        startIcon={<OpenInNewIcon />}
      >
        Open Scoreboard
      </Button>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          navigator.clipboard
            .writeText(`${productionUrl}#/board/${record.id}`)
            .then(() => {
              alert(`Coppied`);
            })
            .catch(() => {
              alert("something went wrong");
            });
          // }
        }}
        startIcon={<ContentCopyIcon />}
      >
        Copy the Scoreboard link
      </Button>
      <br />
      <h3>Attempt board of this session:</h3>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        href={`#/display/${record.id}`}
        target="_blank"
        startIcon={<OpenInNewIcon />}
      >
        Open attempt board
      </Button>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          navigator.clipboard
            .writeText(`${productionUrl}#/display/${record.id}`)
            .then(() => {
              alert(`Coppied`);
            })
            .catch(() => {
              alert("something went wrong");
            });
          // }
        }}
        startIcon={<ContentCopyIcon />}
      >
        Copy the attempt board link
      </Button>
      <br />
      <h3>Referees: </h3>
      <Button
        size="small"
        variant="outlined"
        href={`#/creferee/${record.id}`}
        target="_blank"
      >
        Chief Referee:{" "}
      </Button>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          navigator.clipboard
            .writeText(`${productionUrl}#/creferee/${record.id}`)
            .then(() => {
              alert(`Coppied`);
            })
            .catch(() => {
              alert("something went wrong");
            });
          // }
        }}
        startIcon={<ContentCopyIcon />}
      >
        Copy link
      </Button>
      <br />
      <Button
        size="small"
        variant="outlined"
        href={`#/lreferee/${record.id}`}
        target="_blank"
      >
        Left Referee:{" "}
      </Button>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          navigator.clipboard
            .writeText(`${productionUrl}#/lreferee/${record.id}`)
            .then(() => {
              alert(`Coppied`);
            })
            .catch(() => {
              alert("something went wrong");
            });
          // }
        }}
        startIcon={<ContentCopyIcon />}
      >
        Copy link
      </Button>
      <br />
      <Button
        size="small"
        variant="outlined"
        href={`#/rreferee/${record.id}`}
        target="_blank"
      >
        Right Referee:{" "}
      </Button>
      &nbsp;
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          navigator.clipboard
            .writeText(`${productionUrl}#/rreferee/${record.id}`)
            .then(() => {
              alert(`Coppied`);
            })
            .catch(() => {
              alert("something went wrong");
            });
          // }
        }}
        startIcon={<ContentCopyIcon />}
      >
        Copy link
      </Button>
      <h3>Resset Session Information: </h3>
      <p>By clicking the attempts and ressults will be deleted</p>
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          //record
          update();
          //Update lifters
          updateMany();
        }}
        target="_blank"
      >
        Resset Session{" "}
      </Button>
    </div>
  );
};

export const PostEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput
        disabled
        label="Event"
        defaultValue={localStorage.getItem("currentCompetition")}
        source="competition"
      />
      <TextInput fullWidth source="name" />
    </SimpleForm>
  </Edit>
);

export const PostCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput
        disabled
        label="Event"
        defaultValue={localStorage.getItem("currentCompetition")}
        source="competition"
      />
      <TextInput fullWidth source="name" />
    </SimpleForm>
  </Create>
);
export const WCEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput
        disabled
        label="Event"
        defaultValue={localStorage.getItem("currentCompetition")}
        source="competition"
      />
      <Box
        style={{ width: "100%" }}
        mt={{ xs: "0.5em", sm: "0.5em" }}
        fullWidth={true}
        display={{ xs: "block", sm: "flex" }}
      >
        <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput fullWidth source="name" />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            fullWidth
            label="Max body weight to reach"
            source="idString"
          />
        </Box>
      </Box>
      <Box
        style={{ width: "100%" }}
        mt={{ xs: "0.5em", sm: "0.5em" }}
        fullWidth={true}
        display={{ xs: "block", sm: "flex" }}
      >
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="sex"
            choices={[
              { id: "f", name: "F" },
              { id: "m", name: "M" },
            ]}
          />
        </Box>
      </Box>
    </SimpleForm>
  </Edit>
);

export const WCCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput
        disabled
        label="Event"
        defaultValue={localStorage.getItem("currentCompetition")}
        source="competition"
      />
      <Box
        style={{ width: "100%" }}
        mt={{ xs: "0.5em", sm: "0.5em" }}
        fullWidth={true}
        display={{ xs: "block", sm: "flex" }}
      >
        <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput fullWidth source="name" />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            fullWidth
            label="Max body weight to reach"
            source="idString"
          />
        </Box>
      </Box>
      <Box
        style={{ width: "100%" }}
        mt={{ xs: "0.5em", sm: "0.5em" }}
        fullWidth={true}
        display={{ xs: "block", sm: "flex" }}
      >
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="sex"
            choices={[
              { id: "f", name: "F" },
              { id: "m", name: "M" },
            ]}
          />
        </Box>
      </Box>
    </SimpleForm>
  </Create>
);
